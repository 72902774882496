import { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { useTranslation } from 'react-i18next';
import { Modal } from "react-bootstrap";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const mySwal = withReactContent(Swal);

const Transfer = ({ history, user, token, theme }) => {
    const [transferToPhone, setTransferToPhone] = useState("");
    const [transferToUser, setTransferToUser] = useState(null);
    const [loading, setLoading] = useState(false);
    const [amount, setAmount] = useState(0);
    const [password, setPassword] = useState("");
    const [step, setStep] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [transferHistory, setTransferHistory] = useState(null);

    const { t } = useTranslation();

    useEffect(() => {
        if (!user) history.push("/");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = e => {
        e.preventDefault();

        if (step === 0) {
            const numberRegex = /^\d+$/;
            if (!transferToPhone) {
                mySwal.fire(t("Error"), t("Please fill in the phone number"), "error");
            } else if (!numberRegex.test(transferToPhone) || transferToPhone.length <= 9 || transferToPhone.length >= 12 || transferToPhone.substr(0, 2) !== "01") {
                mySwal.fire({
                    title: t("Error"),
                    icon: "error",
                    html: t(`Please enter the correct phone number format. <br /><br /> <small>Example: 0129465628</small>`)
                });
            } else if (transferToPhone === user.number) {
                mySwal.fire(t("Error"), t("Please enter the phone number that you want to transfer to"), "error");
            } else {
                setLoading(true);
                axios
                    .post("/api/token/searchTransferUser", { transfer_to: transferToPhone, operatorId: user.store }, { headers: { "Content-Type": "application/json", "auth-token": token } })
                    .then(res => {
                        setLoading(false);
                        setTransferToUser(res.data.data)
                        setStep(1)
                    })
                    .catch(err => {
                        setLoading(false);
                        mySwal.fire(t("Error"), t(err.response.data.error), "error");
                    });
            }
        } else if (step === 1) {
            if (!amount) {
                mySwal.fire(t("Error"), t("Please fill in the transfer amount"), "error");
            } else if (amount <= 0 || amount > user.token) {
                mySwal.fire(t("Error"), t("Transfer amount should be more than 0 and less than your current e-Token"), "error");
            } else {
                setStep(2)
            }
        } else if (step === 2) {
            setShowModal(true);
        } else if (step === 3) {
            history.push("/");
        }
    }

    const transfer = () => {
        if (!password) {
            mySwal.fire(t("Error"), t("Please enter your password"), "error");
        } else if (transferToUser && amount && password && user.id && user.store) {
            const sendThis = {
                transfer_from: user.id,
                transfer_to: transferToUser.id,
                amount,
                operatorId: user.store,
                password
            }

            setLoading(true);
            axios
                .post("/api/token/transfer", sendThis, { headers: { "Content-Type": "application/json", "auth-token": token } })
                .then(res => {
                    setTransferHistory(res.data.data);
                    setLoading(false);
                    setShowModal(false);
                    setStep(3)
                })
                .catch(err => {
                    setLoading(false);
                    mySwal.fire(t("Error"), t(err.response.data.error), "error");
                });
        }
    }

    return (
        <div id="transfer-page">
            <div id="user-header">
                <h5>
                    <i onClick={e => history.push("/")} className="fas fa-chevron-left me-3"></i> <strong>{t("Transfer Tokens")}</strong>
                </h5>

                {
                    step !== 3 ? (
                        <div id="token-info-container">
                            <div id="token-image-container">
                                <i className="fas fa-coins"></i>
                            </div>

                            <div id="token-info">
                                <h3>{user?.name || ""}</h3>
                                <h6>
                                    {t("Current e-Token")}: <span id="user-token">{user?.token || 0}</span>
                                </h6>
                            </div>
                        </div>
                    ) : null
                }
            </div>

            <div id="user-body">
                <form onSubmit={handleSubmit}>
                    {
                        step === 0 ? (
                            <div className="pt-3">
                                <p style={{ margin: 0, paddingTop: "5px" }}><b>{t("Phone Number")}:</b></p>
                                <input type="number" name="transferToPhone" id="transferToPhone" className="form-control" placeholder={t("Example: 012xxxxxxx")} onChange={e => setTransferToPhone(e.target.value)} value={transferToPhone} required />
                            </div>
                        ) : step === 1 ? (
                            <div className="pt-3">
                                <p style={{ margin: 0, paddingTop: "5px" }}><b>{t("Phone Number")}:</b> {transferToUser ? transferToUser.number : ""}</p>
                                <p style={{ margin: 0, paddingTop: "5px" }}><b>{t("Name")}:</b> {transferToUser ? transferToUser.name : ""}</p>
                                <p style={{ margin: 0, paddingTop: "5px" }}><b>{t("Member ID")}:</b> {transferToUser ? transferToUser.member_id : ""}</p>
                                <div className="pt-3">
                                    <p style={{ margin: 0, paddingTop: "5px" }}><b>{t("E-Token Transfer Amount")}:</b></p>
                                    <input type="number" name="amount" id="amount" className="form-control" onChange={e => setAmount(e.target.value)} value={amount} required min="1" max={user.token} any="1" />
                                </div>
                            </div>
                        ) : step === 2 ? (
                            <div className="pt-3">
                                <h5 className="text-center"><b>{t("Please confirm the details before proceeding")}</b></h5>
                                <h5 className="pt-3">{t("Transfer From")}</h5>
                                <p style={{ margin: 0, paddingTop: "5px" }}><b>{t("Phone Number")}:</b> {user ? user.number : ""}</p>
                                <p style={{ margin: 0, paddingTop: "5px" }}><b>{t("Name")}:</b> {user ? user.name : ""}</p>
                                <p style={{ margin: 0, paddingTop: "5px" }}><b>{t("Member ID")}:</b> {user ? user.memberId : ""}</p>
                                <h5 className="pt-4">{t("Transfer To")}</h5>
                                <p style={{ margin: 0, paddingTop: "5px" }}><b>{t("Phone Number")}:</b> {transferToUser ? transferToUser.number : ""}</p>
                                <p style={{ margin: 0, paddingTop: "5px" }}><b>{t("Name")}:</b> {transferToUser ? transferToUser.name : ""}</p>
                                <p style={{ margin: 0, paddingTop: "5px" }}><b>{t("Member ID")}:</b> {transferToUser ? transferToUser.member_id : ""}</p>
                                <h5 className="pt-4">{t("Transfer Amount")}</h5>
                                <p style={{ margin: 0, paddingTop: "5px" }}><b>{t("Transfer E-Token Amount")}:</b> {amount}</p>
                            </div>
                        ) : step === 3 ? (
                            <div className="text-center">
                                <div style={{ color: "green", fontSize: "35px" }}>
                                    <i className="fas fa-check-circle"></i>
                                </div>
                                <h5><b>{t("Transfer Successfully!")}</b></h5>
                                <h5 className="pt-3">{t("Transfer From")}</h5>
                                <p style={{ margin: 0, paddingTop: "5px" }}><b>{t("Phone Number")}:</b> {transferHistory.transferFromPhone}</p>
                                <p style={{ margin: 0, paddingTop: "5px" }}><b>{t("Name")}:</b> {transferHistory.transferFromName}</p>
                                <h5 className="pt-4">{t("Transfer To")}</h5>
                                <p style={{ margin: 0, paddingTop: "5px" }}><b>{t("Phone Number")}:</b> {transferHistory.transferToPhone}</p>
                                <p style={{ margin: 0, paddingTop: "5px" }}><b>{t("Name")}:</b> {transferHistory.transferToName}</p>
                                <h5 className="pt-4">{t("Transfer Amount")}</h5>
                                <p style={{ margin: 0, paddingTop: "5px" }}>{transferHistory.transferAmount} {t("Token")}</p>
                                <h5 className="pt-4">{t("Transfer Time")}</h5>
                                <p style={{ margin: 0, paddingTop: "5px" }}>{transferHistory.transferTime}</p>
                            </div>
                        ) : null
                    }

                    <div className="pt-3">
                        {
                            loading ? (
                                <div className="text-center">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <button id="transfer-next" type="submit" className="btn big-button form-control mt-3">{step === 3 ? t("Done") : t("Next")}</button>
                                </div>
                            )
                        }
                    </div>
                </form>
            </div >

            <Modal
                show={showModal}
                onHide={() => setShowModal(false)}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header>
                    <Modal.Title>{t("Enter Password")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={theme}>
                        <p style={{ margin: 0, paddingTop: "5px" }}><b>{t("Password")}:</b></p>
                        <input type="password" name="password" id="password" className="form-control" onChange={e => setPassword(e.target.value)} value={password} required />
                        {
                            loading ? (
                                <div className="text-center">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            ) : (
                                <div className="text-center pt-3">
                                    <button id="transfer-confirm" type="button" className="btn big-button form-control mt-3" onClick={() => transfer()}>{t("Submit")}</button>
                                </div>
                            )
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </div >
    );

}

const mapStateToProps = state => {
    return {
        user: state.user.user,
        token: state.user.token,
        theme: state.theme.theme
    };
};

export default connect(mapStateToProps, null)(Transfer);