import React, { Component } from 'react';
import axios from "axios";
import { connect } from "react-redux";
import Sidebar from './components/Sidebar';
import Topbar from './components/Topbar';
import Loading from "../../admin/components/Loading";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Card } from 'react-bootstrap';

const mySwal = withReactContent(Swal);

class EditNews extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggled: false,
            title: "",
            titleBm: "",
            titleCh: "",
            description: "",
            descriptionBm: "",
            descriptionCh: "",
            banner: null,
            image: null,
            startDate: "",
            endDate: "",
            bannerUrl: "",
            imageUrl: "",
            loading: true
        };
    };

    componentDidMount() {
        if (!this.props.distributor || !this.props.location.state || !this.props.distributor.news_setting) {
            this.props.history.push('/distributor');
        } else {
            axios
                .post("/api/admin/setting/getEditNews", { newsId: this.props.location.state.newsId }, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
                .then(res => {
                    this.setState({
                        ...this.state,
                        loading: false,
                        title: res.data.title,
                        titleBm: res.data.titleBm,
                        titleCh: res.data.titleCh,
                        description: res.data.description,
                        descriptionBm: res.data.descriptionBm,
                        descriptionCh: res.data.descriptionCh,
                        startDate: res.data.startDate ? new Date(res.data.startDate) : "",
                        endDate: res.data.endDate ? new Date(res.data.endDate) : "",
                        bannerUrl: res.data.banner,
                        imageUrl: res.data.image
                    });
                })
                .catch(err => mySwal.fire("Error", err.response.data.error, "error"));
        }
    }

    handleToggle = () => this.setState({ ...this.state, toggled: !this.state.toggled });

    handleChange = e => {

        this.setState({
            ...this.state,
            [e.target.id]: e.target.value
        });
    };

    handleDate = dates => {
        const [start, end] = dates;
        this.setState({
            ...this.state,
            startDate: start,
            endDate: end
        });
    };

    handleImage = e => {

        const file = e.target.files[0];

        const imageRegex = /image\//g;
        const jpgRegex = /.jpg/g;
        const pngRegex = /.png/g;
        const jpegRegex = /.jpeg/g;

        if (file) {
            if (imageRegex.test(file.type)) {
                if (jpgRegex.test(file.name) || pngRegex.test(file.name) || jpegRegex.test(file.name)) {

                    if (file.size > 2097152) {
                        mySwal.fire("Info", "The image size is over 2MB", "info");
                        e.target.value = "";
                    } else {
                        if (e.target.id === "banner") {
                            const bannerObjectUrl = URL.createObjectURL(e.target.files[0]);
                            this.setState({
                                ...this.state,
                                bannerUrl: bannerObjectUrl,
                                banner: e.target.files[0]
                            });
                        } else if (e.target.id === "image") {
                            const imageObjectUrl = URL.createObjectURL(e.target.files[0]);
                            this.setState({
                                ...this.state,
                                imageUrl: imageObjectUrl,
                                image: e.target.files[0]
                            });
                        }
                    }

                } else {
                    mySwal.fire("Info", "Please only use png, jpg or jpeg file extension type", "info");
                    e.target.value = "";
                }
            } else {
                mySwal.fire("Info", "Please only use image type file", "info");
                e.target.value = "";
            }
        }
    };

    handleSubmit = e => {
        e.preventDefault();

        const { title, titleBm, titleCh, description, descriptionBm, descriptionCh, banner, image, startDate, endDate } = this.state;

        if (startDate && !endDate) {
            mySwal.fire("Info", "Please select the end date for your news.", "info");
        } else if (title && description) {
            mySwal.fire({
                title: "Confirmation",
                text: "Are you sure you want to edit news?",
                icon: "question",
                showCancelButton: true,
                cancelButtonText: "No",
                confirmButtonText: "Yes",
                cancelButtonColor: "#d33"
            })
                .then(click => {

                    if (click.isConfirmed && click.value) {
                        this.setState({
                            ...this.state,
                            loading: true
                        });

                        const sendThis = {
                            title,
                            titleBm,
                            titleCh,
                            description,
                            descriptionBm,
                            descriptionCh,
                            startDate,
                            endDate,
                            newsId: this.props.location.state.newsId
                        };

                        axios.post('/api/admin/setting/editNews', sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
                            .then(res => {

                                if (banner || image) {
                                    const formData = new FormData();
                                    formData.append("id", this.props.location.state.newsId);
                                    if (banner) {
                                        formData.append("banner", banner);
                                    }
                                    if (image) {
                                        formData.append("image", image);
                                    }

                                    axios.post('/api/admin/setting/editNewsImage', formData, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
                                        .then(res => {
                                            this.setState({
                                                ...this.state,
                                                loading: false,
                                            });

                                            mySwal.fire("Success", "News edit successfully", "success");
                                            this.props.history.push("/distributor/news")
                                        })
                                        .catch(err => {
                                            this.setState({
                                                ...this.state,
                                                loading: false
                                            });
                                            mySwal.fire("Error", err.response.data.error, "error");
                                        });
                                } else {
                                    this.setState({
                                        ...this.state,
                                        loading: false,
                                    });

                                    mySwal.fire("Success", "News edit successfully", "success");
                                    this.props.history.push("/distributor/news")
                                }

                            })
                            .catch(err => {
                                this.setState({
                                    ...this.state,
                                    loading: false
                                });
                                mySwal.fire("Error", err.response.data.error, "error");
                            });
                    }
                })
        } else {
            mySwal.fire("Info", "Please fill in all the required fields before submit.", "info");
        }
    };

    render() {

        return (
            <div className="admin-page-container">
                <Sidebar toggled={this.state.toggled} setToggled={this.handleToggle} />

                <div className="admin-page">
                    <Topbar handleToggle={this.handleToggle} />

                    <div className="admin-content">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="card-title">Edit News</h5>
                            </div>

                            <div className="card-body">
                                <div className="news-preview-container">
                                    <label htmlFor="banner">Banner Image: </label>
                                    <Card className="news-banner-card">
                                        <Card.Img variant="top" src={this.state.bannerUrl ? this.state.bannerUrl : null} alt="bannerImage" />
                                        <Card.Body style={{ padding: "0.5rem 1rem" }}>
                                            <Card.Text>Learn More</Card.Text>
                                        </Card.Body>
                                    </Card>
                                    <label htmlFor="image">News Image: </label>
                                    <div>
                                        <img src={this.state.imageUrl ? this.state.imageUrl : null} alt="newsImage" style={{ maxHeight: "300px", padding: "10px" }} className="img-fluid" />
                                    </div>
                                </div>

                                <form onSubmit={this.handleSubmit}>
                                    <div className="form-group">
                                        <label htmlFor="title">Title (English): * </label>
                                        <input type="text" id="title" name="title" value={this.state.title} onChange={this.handleChange} required maxLength="25" />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="titleBm">Title (Malay): </label>
                                        <input type="text" id="titleBm" name="titleBm" value={this.state.titleBm} onChange={this.handleChange} maxLength="25" />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="titleCh">Title (Chinese): </label>
                                        <input type="text" id="titleCh" name="titleCh" value={this.state.titleCh} onChange={this.handleChange} maxLength="25" />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="description">Description (English): * </label>
                                        <textarea id="description" name="description" className="form-control" style={{ height: "100px" }} onChange={this.handleChange} value={this.state.description} required></textarea>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="descriptionBm">Description (Malay): </label>
                                        <textarea id="descriptionBm" name="descriptionBm" className="form-control" style={{ height: "100px" }} onChange={this.handleChange} value={this.state.descriptionBm}></textarea>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="descriptionCh">Description (Chinese): </label>
                                        <textarea id="descriptionCh" name="descriptionCh" className="form-control" style={{ height: "100px" }} onChange={this.handleChange} value={this.state.descriptionCh}></textarea>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="banner">Banner (max: 2MB) (aspect ratio: 2:1): </label><br />
                                        <input type="file" id="banner" name="banner" accept="image/*" onChange={this.handleImage} />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="image">Image (max: 2MB) (aspect ratio: 6:5): </label><br />
                                        <input type="file" id="image" name="image" accept="image/*" onChange={this.handleImage} />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="date">Select Period: (optional) </label>
                                        <DatePicker value={`${this.state.startDate ? `${new Date(this.state.startDate).getDate()}/${new Date(this.state.startDate).getMonth() + 1}/${new Date(this.state.startDate).getFullYear()}` : ""} - ${this.state.endDate ? `${new Date(this.state.endDate).getDate()}/${new Date(this.state.endDate).getMonth() + 1}/${new Date(this.state.endDate).getFullYear()}` : ""}`} onChange={this.handleDate} startDate={this.state.startDate} endDate={this.state.endDate} selectsRange shouldCloseOnSelect={false} minDate={new Date()} />
                                    </div>

                                    <div>
                                        {
                                            this.state.loading
                                                ? <Loading />
                                                : (
                                                    <div>
                                                        <input type="submit" value="SUBMIT" className="btn btn-primary form-control mb-1" />
                                                        <button onClick={() => this.props.history.push("/distributor/news")} className="btn btn-danger mt-1 form-control font-weight-bold">Cancel</button>
                                                    </div>
                                                )
                                        }
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    };
};

const mapStateToProps = state => {
    return {
        distributor: state.distributor.distributor,
        token: state.distributor.token
    };
};

export default connect(mapStateToProps, null)(EditNews);