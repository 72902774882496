import { useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from 'react-i18next';

import Sidebar from "../utilities/Sidebar";
import NewsSlider from "./dashboard/NewsSlider";

const Dashboard = ({ user, history }) => {
	const [toggled, setToggled] = useState(false);
	const { t } = useTranslation();

	return (
		<div id="user-dashboard">
			<Sidebar toggled={toggled} setToggled={setToggled} />
			<div id="user-header">
				<h5>
					<i onClick={e => setToggled(true)} className="fas fa-bars me-3"></i> <strong>{user.stamp ? t("Home") : t("Home")}</strong>
				</h5>
				<div id="user-info-container">
					<div id="user-info-flex">
						<div style={{ height: "70px", display: "flex", flexDirection: "column", justifyContent: "space-around", borderRight: "1px white solid", paddingRight: "12%" }}>
							<h6 className="m-1 p-0">POINTS</h6>
							<h6 className="mt-1 p-0 mx-auto">
								<strong>{user.point}</strong>
							</h6>
						</div>

						<div id="user-info">
							<h5 className="m-0 p-0">{user ? user.name.toUpperCase() : null}</h5>
							<h5 className="mt-3 p-0">
								<strong>{user ? user.memberId : null}</strong>
							</h5>
						</div>
					</div>
				</div>
			</div>
			<div id="user-body">
				{
					user.creditAmount && user.creditAmount.length ?
						<div>
							<br />
							<br />
							<br />
							<h5>
								{/* <strong>{t("News")}</strong> */}
								<strong>Credits Info</strong>
							</h5>
							<div id="user-info-container" style={{ height: "auto" }}>

								<div>
									{user.creditAmount.map(credit => {
										return (
											<div>
												<br />
												<div key={credit.id} style={{ display: "flex", justifyContent: "flex-start", marginLeft: "10%" }}>
													<h5 className="font-weight-bold m-1 p-0">{credit.type}</h5>
													<h5 className="font-weight-bold m-1 p-0">-</h5>
													<h5 className="font-weight-bold m-1 p-0">{credit.amount} CREDITS</h5>
												</div>
											</div>
										)
									})}
									<br />
								</div>

							</div>
							<br />
							<br />
							<br />
							<br />
						</div>
						:
						""
				}
				<div className="mt-5" />
				<br />
				<br />
				<div>
					<h5>
						<strong>{t("News")}</strong>
					</h5>
					<div>
						<NewsSlider page="dashboard" history={history} />
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		user: state.user.user
	};
};

export default connect(mapStateToProps, null)(Dashboard);
