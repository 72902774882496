import React, { Component } from "react";
import { connect } from "react-redux";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import Loading from "../components/Loading";
import { MDBDataTable } from "mdbreact";
// import { Modal, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { updatePaymentSetting } from "../../../redux/actions/adminActions";

const mySwal = withReactContent(Swal);

class TokenSetting extends Component {
	constructor(props) {
		super(props);
		this.state = {
			toggled: false,
			token: false,
			epayment: false,
			nonMemberPayment: false,
			loading: true,
			data: []
		};
	}
	
	_isMounted = false;
	componentDidMount() {
		this._isMounted = true;
		if (this._isMounted) {
			if (!this.props.admin.isAuthenticated) {
				return this.props.history.push("/admin/dashboard");
			}
			else {
				axios
					.post("/api/admin/setting/getPointSetting", { id: this.props.admin.admin.operatorId }, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
					.then(res => {
						// console.log("get Point", res.data)
						this.setState({
							...this.state,
							data: res.data,
							loading: false
						})
					})
					.catch(err => {
						this.setState({
							...this.state,
							loading: false
						});
						mySwal.fire("Error", err.response.data.error, "error");
					});
			}
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	handleToggle = () => this.setState({ ...this.state, toggled: !this.state.toggled });

	handleNonMemberPayment = e => this.setState({
		...this.state,
		nonMemberPayment: !this.state.nonMemberPayment
	});

	handleCheckbox = e => {
		this.setState({
			...this.state,
			[e.target.id]: !this.state[e.target.id]
		});
	};

	handlePayment = e => {
		e.preventDefault();
		if (this.props.admin.admin.token !== this.state.token || this.props.admin.admin.epayment !== this.state.epayment || this.props.admin.admin.nonMemberPayment !== this.state.nonMemberPayment) {
			if (!this.state.token && !this.state.epayment) {
				mySwal.fire("Error", "Please choose at least one payment method", "error");
			} else {
				mySwal.fire({
					title: "Confirmation",
					text: "Are you sure you want to change the payment setting?",
					icon: "question",
					showCancelButton: true,
					cancelButtonText: "No",
					confirmButtonText: "Yes",
					cancelButtonColor: "#d33"
				})
					.then(click => {
						if (click.isConfirmed && click.value) {
							this.setState({
								...this.state,
								loading: true
							});

							const { token, epayment, nonMemberPayment } = this.state;
							const sendThis = {
								id: this.props.admin.admin.operatorId,
								role: "operator",
								token,
								epayment,
								nonMemberPayment
							};

							axios.post('/api/admin/setting/paymentSetting', sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
								.then(res => {
									this.setState({
										...this.state,
										loading: false
									});
									let data = {
										token,
										epayment,
										nonMemberPayment
									}
									this.props.updatePaymentSetting(data);
									mySwal.fire("Success", "Your payment setting has updated successfully!", "success");
								})
								.catch(err => {
									this.setState({
										...this.state,
										loading: false
									});
									mySwal.fire("Error", err.response.data.error, "error");
								});
						}
					})
			}
		}
	}

	deletePoint = id => {
		mySwal.fire({
			title: "Confirmation",
			text: "Are you sure you want to delete Point Setting?",
			icon: "question",
			showCancelButton: true,
			cancelButtonText: "No",
			confirmButtonText: "Yes",
			cancelButtonColor: "#d33"
		}).then(click => {
			if (click.isConfirmed && click.value) {
				this.setState({
					...this.state,
					loading: true
				});
				axios.post('/api/admin/setting/deletePointSetting', { id }, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
					.then(res => {
						axios
							.post("/api/admin/setting/getPointSetting", { id: this.props.admin.admin.operatorId, role: "operator" }, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
							.then(res => {
								this.setState({
									...this.state,
									data: res.data,
									loading: false
								});
								mySwal.fire("Success", "Point deleted successfully", "success");
							})
							.catch(err => {
								this.setState({
									...this.state,
									loading: false
								});
								mySwal.fire("Error", err.response.data.error, "error");
							});
					})
					.catch(err => {
						this.setState({
							...this.state,
							loading: false
						});
						mySwal.fire("Error", err.response.data.error, "error");
					});
			}
		});
	}

	render() {

		let tableData = {
			columns: [
				{
					label: "ID",
					field: "id",
					sort: "asc"
				},
				// {
				// 	label: "Ringgit Malaysia",
				// 	field: "rm_amount",
				// },
				{
					label: "Point Divide Ratio",
					field: "point_ratio",
				},
				// {
				// 	label: "Point",
				// 	field: "point_amount",
				// },
				// {
				// 	label: "Col Span",
				// 	field: "colSpan",
				// },
				{
					label: "Category",
					field: "category",
				},
				{
					label: "Item Price Multiplier",
					field: "multiply",
				},
				// {
				// 	label: "Created At",
				// 	field: "createdAt",
				// },
				{
					label: "Updated At",
					field: "updatedAt",
				},
				{
					label: "Action",
					field: "action"
				}
			],
			rows: []
		};

		if (this.state.data.length) {
			for (let i = 0; i < this.state.data.length; i++) {
				const dataObject = {
					id: this.state.data[i].id,
					rm_amount: this.state.data[i].rm_amount,
					point_ratio: this.state.data[i].point_ratio,
					point_amount: this.state.data[i].point_amount,
					colSpan:this.state.data[i].category_colSpan,
					multiply:this.state.data[i].multiply,
					category: this.state.data[i].category,
					createdAt: this.state.data[i].createdAt,
					updatedAt: this.state.data[i].updatedAt,
					action: (
						<div>
							<i className="fas fa-edit fa-lg" onClick={() => this.props.history.push("/admin/point/edit", { pointId: this.state.data[i].id })}></i>
							{/* <i className="fas fa-trash-alt fa-lg ms-3" onClick={() => this.deletePoint(this.state.data[i].id)}></i> */}
						</div>
					)
				};
				tableData.rows.push(dataObject);
			}
		}

		const renderList = () => {

			if (this.state.loading) {
				return (
					<Loading />
				);
			} 
			else {
				if (!this.state.data.length) {
					return (
						<div className="text-center">
							<h1> - NO POINT - </h1>
						</div>
					);
				} 
				else {
					return (
						<MDBDataTable data={tableData} noBottomColumns striped hover responsive bordered />
					);
				}
			}
		}

		return (
			<div className="admin-page-container">
				<Sidebar toggled={this.state.toggled} setToggled={this.handleToggle} />
				<div className="admin-page">
					<Topbar handleToggle={this.handleToggle} />
					<div className="admin-content">
						{/* <div className="card">
							<div className="card-header">
								<h5 className="card-title">Payment Setting</h5>
							</div>
							<div className="card-body">
								<form onSubmit={this.handlePayment}>
									<p>Select Payment Method</p>
									<div className="d-flex">
										<div className="form-group">
											<label>
												<input type="checkbox" id="epayment" name="epayment" checked={this.state.epayment} onChange={this.handleCheckbox} />
												<span>Enable E-Payment?</span>
											</label>
										</div>

										<div className="form-group ms-5">
											<label>
												<input type="checkbox" id="token" name="token" checked={this.state.token} onChange={this.handleCheckbox} />
												<span>Enable Point Currency?</span>
											</label>
										</div>
									</div>
									{
										this.state.epayment ? (
											<div className="form-group mt-3">
												<p>E-payment for?</p>

												<p>
													<label htmlFor="radio2">
														<input type="radio" name="nonMemberPayment" id="radio2" className="with-gap" checked={this.state.nonMemberPayment === true} onChange={this.handleNonMemberPayment} />
														<span>Member & Non-Member Payment</span>
													</label>
												</p>

												<p>
													<label htmlFor="radio1">
														<input type="radio" name="nonMemberPayment" id="radio1" className="with-gap" checked={this.state.nonMemberPayment === false} onChange={this.handleNonMemberPayment} />
														<span>Only Member Payment</span>
													</label>
												</p>

												<p><small>* {this.state.nonMemberPayment ? "User can skip to payment without login to make the payment" : "User have to login only can make the payment"}</small></p>
											</div>
										) : null
									}
									<div>
										{
											this.state.loading
												? (
													<div className="text-center">
														<div className={`spinner-border text-${process.env.REACT_APP_STORETYPE === "cuci" ? "warning" : "primary"}`} role="status">
															<span className="sr-only">Loading...</span>
														</div>
													</div>
												)
												: <input type="submit" value="SUBMIT" className="btn btn-block btn-warning big-button" />
										}
									</div>
								</form>
							</div>
						</div> */}
						<div className="card">
							<div className="card-header d-flex justify-content-between">
								<h5 className="card-title">Point Setting</h5>
								{/* <button className="btn btn-primary" type="button"
									onClick={() => this.props.history.push("/admin/point/create")}>
									ADD POINT
								</button> */}
							</div>
							<div className="card-body">
								{
									renderList()
								}
							</div>

						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		admin: state.admin
	};
};

const mapDispatchToProps = dispatch => {
	return {
		updatePaymentSetting: data => dispatch(updatePaymentSetting(data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(TokenSetting);
