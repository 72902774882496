import React, { Component } from 'react';
import { MDBDataTable } from "mdbreact";
import { connect } from "react-redux";

class TopUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: "Spending"
        };
    }; 

    handleChange = e => {
        this.setState({
            ...this.state,
            [e.target.id]: e.target.value
        });
    };

    render() {
        let data = {
            columns: [],
            rows: []
        };

        if (this.state.filter === "Spending") {
            data.columns = [
                {
                    label: "Top Users",
                    field: "topUsers",
                },
                {
                    label: "User name",
                    field: "username",                  
                },
                {
                    label: "Number",
                    field: "number",                  
                },
                {
                    label: "Point Spending",
                    field: "pointSpending",
                    sort: "asc"
                },
            ]
            data.rows = this.props.topUserData.sortByPointSpending;
        }
        else if (this.state.filter === "rmSpending") {
            data.columns = [
                {
                    label: "Top Users",
                    field: "topUsers",
                },
                {
                    label: "User name",
                    field: "username",
                },
                {
                    label: "Number",
                    field: "number",
                },
                {
                    label: "RM Spending",
                    field: "rmSpending",
                    sort: "asc"
                },
            ]
            data.rows = this.props.topUserData.sortByRMSpending;
        }
        else if (this.state.filter === "creditSpending") {
            data.columns = [
                {
                    label: "Top Users",
                    field: "topUsers",
                },
                {
                    label: "User name",
                    field: "username",
                },
                {
                    label: "Number",
                    field: "number",
                },
                {
                    label: "Credit Spending",
                    field: "creditSpending",
                    sort: "asc"
                },
            ]
            data.rows = this.props.topUserData.sortByCreditSpending;
        }

        return (
            <div>
                <div>
                    <h6 className="text-center">Top Users</h6>
                    <div>
                        <label htmlFor="filter">Sort by: </label>
                        <select id="filter" name="filter" className="browser-default form-select" value={this.state.filter} onChange={this.handleChange}>
                            <option value="Spending">Point Spending</option>
                            <option value="rmSpending">E-Wallet Spending</option>
                            <option value="creditSpending">Credit Spending</option>
                        </select>
                    </div>
                </div>
                <div className="text-center mt-3"><MDBDataTable data={data} paging={false} searching={false} sortable={false} noBottomColumns striped hover responsive bordered /></div>
            </div>
        );
    };
};

const mapStateToProps = state => {
    return {
        admin: state.admin
    };
};

export default connect(mapStateToProps, null)(TopUser);