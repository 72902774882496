import { useTranslation } from 'react-i18next';

const ReloadList = ({ data, loading, handleSubmit }) => {

	const { t } = useTranslation();
	
	if (loading) {
		return (
			<div className="text-center" style={{ width: "100%", height: "100%", margin: 0, padding: "5px 0" }}>
				<div style={{ width: "80px", height: "80px", fontWeight: "bold", fontSize: "36px", margin: "10px", padding: 0 }} className={process.env.REACT_APP_STORETYPE === "cuci" ? "mt-5 spinner-border text-warning" : "mt-5 spinner-border text-primary"}>
					<span className="sr-only">Loading...</span>
				</div>
			</div>
		);
	} else {
		if (!data || !data.length) {
			return (
				<div className="text-center mt-5">
					<h3> - {t("No Promotions")} - </h3>
				</div>
			);
		} else {
			const renderList = () =>
				data.map(x => {
					return (
						<li className="list-group-item" key={x.id}>
							<div className="d-flex justify-content-between align-items-center">
								<h5 className="m-0 p-0">{x.token} {t("e-Tokens")}</h5>
								<button onClick={e => handleSubmit(x)} className="m-0 font-weight-bold btn btn-primary">
									RM {parseFloat(x.price).toFixed(2)}
								</button>
							</div>
						</li>
					);
				});

			return (
				<div className="list-group" style={{ maxHeight: "52vh", overflowX: "hidden", overflowY: "auto", boxSizing: "border-box" }}>
					{renderList()}
				</div>
			);
		}
	}
};

export default ReloadList;
