import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from 'react-i18next';
import queryString from "query-string";
import axios from "axios";
import './Payment.css'


import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { /*changePage,*/checkToken } from "../../../../redux/actions/userActions";
import { /*scannedCode, saveCreds,*/savePaymentProduct } from "../../../../redux/actions/paymentActions";

import { Modal } from "react-bootstrap";

const mySwal = withReactContent(Swal);
const Payment = ({ payment, user, history, /*changePage,*/ location, savePaymentProduct, checkToken, token /*scanned, saveCredentials*/ }) => {

	const { t } = useTranslation();
	const { Token, TID } = queryString.parse(location.search);
	const [isOpen, setOpen] = useState(false);
	const [voucher, setVoucher] = useState(null);
	const [type, setType] = useState(null);
	const [voucherAmount, setvoucherAmount] = useState(null);
	const [voucherId, setVoucherId] = useState(null);
	const [discountCode, setDiscountCode] = useState("");
	const [discountCodeInput, setDiscountCodeInput] = useState("");
	const [discountCodeModal, setDiscountCodeModal] = useState(false);
	const [loading, setLoading] = useState(false);

	const [productData, setProduct] = useState([]);
	const [itemPoint, setItemPoint] = useState(null);
	const [itemCredit, setItemCredit] = useState([]);
	const [paymentAmount, setPaymentAmount] = useState(null);
	const [userCategory, setUserCategory] = useState([]);

	useEffect(() => {
		if (!user || !payment.product) {

			if (!user) {
				if (token) {
					checkToken();
				}
				else {
					history.push('/');
				}
			}

			if (!payment.product) {
				if (TID && Token) {
					axios.post("/api/payment/frame", { TID, Token })
						.then(res => {
							setProduct(res.data.data)
						})
						.catch(err => {
							history.push('/');
							// mySwal.fire("Error", err.response.data.error + ". Back to homepage", "error");
						});
				}
				else {
					history.push('/');
				}
			}

			if (user?.userType) {
				axios.post("/api/user/auth/checkUserType", { id: user.userType })
					.then(res => {
						// console.log(res)
						setUserCategory(res.data);
					})
					.catch(err => {
						history.push('/');
						//mySwal.fire("Error", err.response.data.error + ". Back to homepage", "error");
					});
			}
		}
		// console.log(TID, Token)
	}, [TID, Token, checkToken, token, payment]);

	useEffect(() => {
		if (!productData.length) {
			const productRM = productData.price;
			// const productRM = 2500;
			const itemCategory = "GENERAL"
			axios.post("/api/payment/getProductPoint", { productRM, itemCategory })
				.then(res => {
					setItemPoint(res.data.productPoint)
				})
				.catch(err => {
					mySwal.fire("Error", err.response.data.error, "error");
				});

			axios.post("/api/payment/getProductCredit", { productRM, itemCategory, userCategory, TID })
				.then(res => {
					// console.log(res.data)
					setItemCredit(res.data)
				})
				.catch(err => {
					mySwal.fire("Error", err.response.data.error, "error");
				});
		}
	}, [userCategory, productData])

	const checkDiscountCode = (e) => {
		e.preventDefault();
		setLoading(true);
		axios
			.post("/api/user/checkDiscountCode", { discountCode: discountCodeInput, userId: user.id })
			.then(res => {
				let typ = res.data.discountType
				let amt = res.data.discountAmount
				setDiscountCode(discountCodeInput);
				const voucher = `${typ === "Flat" ? "RM" : ""} ${amt}${typ === "Rate" ? "%" : ""}`;
				if (typ === "Flat") {
					const afterDiscount = (parseFloat(productData.price) - amt).toFixed(2);
					setType(typ);
					setvoucherAmount(amt);
					setVoucherId(null);
					setVoucher(voucher);
					setPaymentAmount(afterDiscount < 0 ? 0 : afterDiscount);
				} else if (typ === "Rate") {
					const rate = (parseFloat(productData.price) * amt) / 100;
					const afterDiscount = (parseFloat(productData.price) - rate).toFixed(2);
					setType(typ);
					setvoucherAmount(amt);
					setVoucherId(null);
					setVoucher(voucher);
					setPaymentAmount(afterDiscount);
				}
				setDiscountCodeModal(false)
				setLoading(false)
			})
			.catch(err => {
				setDiscountCodeInput("");
				setLoading(false);
				mySwal.fire("Error", err.response.data.error, "error");
			});
	}

	const chooseVoucher = (typ, amt, id) => {
		if (user) {
			// console.log({typ , amt , id , amount});
			const voucher = `${typ === "Flat" ? "RM" : ""} ${amt} ${typ === "Rate" ? "%" : ""}`;
			// console.log({voucher});
			if (typ === "Flat") {
				const afterDiscount = (parseFloat(productData.price) - amt).toFixed(2);
				// console.log({afterDiscount});
				// console.log(afterDiscount < 0 ? 0 : afterDiscount);
				setType(typ);
				setvoucherAmount(amt);
				setVoucherId(id);
				setVoucher(voucher);
				setPaymentAmount(afterDiscount < 0 ? 0 : afterDiscount);
			} else if (typ === "Rate") {
				const rate = (parseFloat(productData.price) * amt) / 100;
				const afterDiscount = (parseFloat(productData.price) - rate).toFixed(2);
				// console.log({amount , rate , afterDiscount});
				setType(typ);
				setvoucherAmount(amt);
				setVoucherId(id);
				setVoucher(voucher);
				setPaymentAmount(afterDiscount);
			}
			setOpen(false);
		}
	};

	const removeVoucher = () => {
		if (user) {
			setVoucher(null);
			setOpen(false);
			setType(null);
			setvoucherAmount(null);
			setVoucherId(null);
			// setPaymentAmount(amount);
			setDiscountCode("");
			setDiscountCodeInput("");
			setDiscountCodeModal(false);
		}
	};

	const renderVouchers = () => {
		if (user) {
			if (user.vouchers && user.vouchers.length) {
				return user.vouchers
					.filter(voucher => voucher.available === true)
					.map(voucher => {
						return (
							<div key={voucher.id} onClick={e => chooseVoucher(voucher.type, voucher.amount, voucher.id)} className="list-group-item">
								<p>{voucher.title}</p>
								<h6>
									{t("Discount")} : {voucher.type === "Flat" ? "RM" : null}
									{voucher.amount}
									{voucher.type === "Rate" ? "%" : null}
								</h6>
							</div>
						);
					});
			}
			else {
				return (
					<div className="mt-4">
						<p style={{ color: "red" }}>{t("No Vouchers Available")}</p>
					</div>
				);
			}
		}
		else {
			return null;
		}
	};

	const renderCredit = () => {
		if (user) {
			return (
				<div>
					{
						itemCredit.length ?
							itemCredit.map(credit => {
								return (
									<div>
										<div className="list-group-item payment-list-group-item">
											<div>
												<p style={{ fontSize: "24px", fontWeight: "bolder", marginLeft: "40%" }} className="text-secondary">
													{t("OR")}
												</p>

											</div>
										</div>
										<div className="list-group-item payment-list-group-item">
											<div>
												<p style={{ fontSize: "24px", fontWeight: "bolder" }} className="text-secondary">
													{credit.Type}
												</p>
											</div>
											<div>
												<p style={{ fontSize: "24px", fontWeight: "bolder" }}>{productData ? /\./g.test(credit.value) ? credit.value.toFixed(2) : credit.value : ""} CREDITS</p>
											</div>
										</div>
									</div>
								)
							})
							:
							""
					}
				</div>
			)
		}
	}

	const handlePointSubmit = e => {
		console.log(e.target.id)
		if (user) {
			if (voucherId || discountCode) {
				mySwal.fire("Error", t("Voucher and discount code is not allow in Points payment"), "error");
			}
			else {
				mySwal.fire({
					title: t("Confirmation"),
					text: t("Total") + " " + itemPoint + " points " + t("to pay the order"),
					icon: "question",
					showCancelButton: true,
					allowOutsideClick: true,
				}).then(click => {
					if (click.isConfirmed) {
						mySwal.fire({
							text: t("Processing ..."),
							allowOutsideClick: false,
							didOpen: () => {
								mySwal.showLoading();
								axios.post('/api/payment/createTrans', { TID, Token, id: user.id, itemPoint })
									.then(res => {
										if (res) {
											mySwal.close();
											if (res.data.status === "Success") {
												history.push("/thankyou");
											}
										}
									}).catch(err => {
										mySwal.fire("Error", err.response.data.error, "error").then(() => (e.target.disabled = false));
									});
							}
						})
					}
				})
			}
		}
		else {
			mySwal.fire(t("Info"), t("Please login again"), "info");
		}
	}

	const handleWalletSubmit = e => {
		if (user) {
			if (voucherId || discountCode) {
				const data = {
					userId: user.id,
					voucher: voucherId ? voucherId : "",
					discountCode: discountCode ? discountCode : ""
				}

				mySwal.fire({
					title: t("Confirmation"),
					text: t("Total") + " " + `RM${paymentAmount}` + " " + t("to pay the order"),
					icon: "question",
					showCancelButton: true,
					allowOutsideClick: true,
				}).then(click => {
					if (click.isConfirmed) {
						mySwal.fire({
							text: t("Processing ..."),
							allowOutsideClick: false,
							didOpen: () => {
								mySwal.showLoading();
								axios.post('/api/payment/rmPayment', { TID, Token, id: data })
									.then(res => {
										mySwal.close();
										if (res.data.voucher) {
											history.push("/thankyou");
										}
										// else {
										// 	window.location.href = res.data.url
										// }
									}).catch(err => {
										mySwal.fire("Error", err.response.data.error, "error").then(() => (e.target.disabled = false));
									});
							}
						})
					}
				})
			}
			else {
				mySwal.fire({
					title: t("Confirmation"),
					text: t("Total") + " " + `RM${productData.price}` + " " + t("to pay the order"),
					icon: "question",
					allowOutsideClick: true,
					showCancelButton: true,
				}).then(click => {
					if (click.isConfirmed) {
						mySwal.fire({
							text: t("Processing ..."),
							allowOutsideClick: false,
							didOpen: () => {
								mySwal.showLoading();
								axios.post('/api/payment/rmPayment', { TID, Token, id: { userId: user.id } })
									.then(res => {
										// console.log("No voucher", res)
										if (res) {
											mySwal.close();
											window.location.href = res.data.url
										}
									}).catch(err => {
										mySwal.fire("Error", err.response.data.error, "error").then(() => (e.target.disabled = false));
									});
							}
						})
					}
				})
			}
		}
		else {
			mySwal.fire(t("Info"), t("Please login again"), "info");
		}
	}

	const handleCreditSubmit = e => {
		console.log(e.target.id)
		const type = e.target.id;
		let typeAmount = ''

		for (let i = 0; i < itemCredit.length; i++) {
			if (type === itemCredit[i].Type) {
				typeAmount = itemCredit[i].value;
				break;
			}
		}

		if (user) {
			if (voucherId || discountCode) {
				mySwal.fire("Error", t("Voucher and discount code is not allow in Credits payment"), "error");
			}
			else {
				mySwal.fire({
					title: t("Confirmation"),
					text: t("Total") + " " + typeAmount + " " + type + " credits " + t("to pay the order"),
					icon: "question",
					showCancelButton: true
				}).then(click => {
					if (click.isConfirmed) {
						mySwal.fire({
							text: t("Processing ..."),
							allowOutsideClick: false,
							didOpen: () => {
								mySwal.showLoading();
								axios.post('/api/payment/createTransPPR', { TID, Token, id: user.id, type: type, amount: typeAmount })
									.then(res => {
										if (res) {
											mySwal.close();
											if (res.data.status === "Success") {
												history.push("/thankyou");
											}
										}
									}).catch(err => {
										mySwal.fire("Error", err.response.data.error, "error").then(() => (e.target.disabled = false));
									});
							}
						})
					}
				})
			}
		}
		else {
			mySwal.fire(t("Info"), t("Please login again"), "info");
		}
	}

	const handleCancel = e => {
		if (productData) {
			setProduct([]);
		}
		history.push("/");
	}

	const handlePayment = (e) => {
		// mySwal
		// 	.fire({
		// 		title: t('Payment'),
		// 		text: t('Please select the payment option:'),
		// 		icon: 'question',
		// 		confirmButtonText: 'Points',
		// 		cancelButtonText: user.isPPR ? 'Credits' : '',
		// 		denyButtonText: t('Epayment'),
		// 		showDenyButton: true,
		// 		showCancelButton: user.isPPR ? true : false,
		// 		allowOutsideClick: true, // Allow closing when clicking outside
		// 		allowEscapeKey: false, // Prevent closing when pressing Esc key
		// 	})
		// 	.then((result) => {
		// 		// console.log(result);
		// 		if (result.isConfirmed) {
		// 			handlePointSubmit();
		// 		} else if (result.isDenied) {
		// 			handleWalletSubmit();
		// 		} else if (result.dismiss === mySwal.DismissReason.cancel) {
		// 			handleCreditSubmit()
		// 		} else {
		// 			// Handle other cases or leave it empty
		// 		}
		// 	});
		const buttonsHTML = userCategory.map((option) => `<button class="swal-button" id=${option}>${option}</button>`).join("");
		Swal.fire({
			title: t('Payment'),
			icon: 'question',
			showConfirmButton: false,
			// confirmButtonText: 'Points',
			// denyButtonText: t('Epayment'),
			html: `
			  <div>${t('Please select the payment option:')}</div>
			  <br />
			  <br />
			  <button class="swal-button" id="epaymentButton">Epayment</button>
			  <button class="swal-button" id="pointButton">Point</button>
			  ${buttonsHTML}
			`,
			preConfirm: () => {
				// Handle button click events here
			},
		});

		document.getElementById("epaymentButton").addEventListener("click", handleWalletSubmit);
		document.getElementById("pointButton").addEventListener("click", handlePointSubmit);
		userCategory.forEach(option => {
			document.getElementById(option).addEventListener("click", handleCreditSubmit);
		});
	};

	return (
		<div id="payment-page">
			<div id="user-header">
				<h5>
					<strong>{t("Order Details and Payment")}</strong>
				</h5>
			</div>
			<div id="user-body">
				{
					productData ? (
						<div className="list-group payment-list-group">
							<div className="text-center">
								<img src={productData.imageUrl} alt="product" style={{ width: "40%" }} />
							</div>
							<div className="list-group-item payment-list-group-item">
								<div>
									<p style={{ fontSize: "24px", fontWeight: "bolder" }} className="text-secondary">{t("Product")} </p>
								</div>
							</div>
							<div style={{ marginLeft: "4%" }}>
								<h5>  {productData ? productData.name : ""}</h5>
							</div>
							<br />
							<br />
							<div className="list-group-item payment-list-group-item">
								<div>
									<p style={{ fontSize: "24px", fontWeight: "bolder" }} className="text-secondary">
										{t("PRICE")}
									</p>
								</div>
								<div>
									<p style={{ fontSize: "24px", fontWeight: "bolder" }}>RM {productData ? /\./g.test(productData.price) ? productData.price.toFixed(2) : productData.price : ""}</p>
								</div>
							</div>
							<div className="list-group-item payment-list-group-item">
								<div>
									<p style={{ fontSize: "24px", fontWeight: "bolder", marginLeft: "40%" }} className="text-secondary">
										{t("OR")}
									</p>
								</div>
							</div>
							<div className="list-group-item payment-list-group-item">
								<div>
									<p style={{ fontSize: "24px", fontWeight: "bolder" }} className="text-secondary">
										{t("POINTS")}
									</p>
								</div>
								<div>
									<p style={{ fontSize: "24px", fontWeight: "bolder" }}>{productData ? /\./g.test(itemPoint) ? itemPoint.toFixed(2) : itemPoint : ""} POINTS</p>
								</div>
							</div>
							{renderCredit()}
							<br />
						</div>
					) : null
				}
				{
					user ? (
						<div>
							<br />
							{
								discountCode || !voucherId ? (
									<div className="list-group-item payment-list-group-item">
										<div>
											<p className="text-secondary">{t("DISCOUNT CODE")}</p>
										</div>
										<div>
											<button
												id="select-voucher"
												type="button"
												onClick={e => setDiscountCodeModal(true)}
												className="text-secondary"
												style={{ border: "none", backgroundColor: "white", outline: "none", fontWeight: "bold", paddingRight: "0px", fontSize: "large" }}
											>
												{discountCode && voucher ? `${discountCode} (${voucher})` : t("Enter code >")}
											</button>
										</div>
									</div>
								) : null
							}
							{
								voucherId || !discountCode ? (
									<div className="list-group-item payment-list-group-item">
										<div>
											<p className="text-secondary">{t("VOUCHER")}</p>
										</div>
										<div>
											<button
												id="select-voucher"
												type="button"
												onClick={e => setOpen(true)}
												className="text-secondary"
												style={{ border: "none", backgroundColor: "white", outline: "none", fontWeight: "bold", paddingRight: "0px", fontSize: "large" }}
											>
												{voucherId ? voucher : t("Select Voucher >")}
											</button>
										</div>
									</div>
								) : null
							}
						</div>
					) : null}

				{/* CUSTOM VOUCHER MODAL */}
				{user ? (
					<div>
						<div onClick={e => setOpen(false)} className={isOpen ? "custom-overlay isOpen" : "custom-overlay"}></div>
						<div className={isOpen ? "custom-modal isOpen" : "custom-modal"}>
							<div className="custom-modal-header">
								{voucher ? (
									<button
										id="remove-voucher"
										onClick={e => removeVoucher()}
										type="button"
										className="custom-modal-title m-0 p-0"
										style={{ color: "red", border: "none", backgroundColor: "white", margin: 0, padding: 0 }}
									>
										<i className="fas fa-ban"></i> {t("Remove Voucher")}
									</button>
								) : (
									<h6 className="custom-modal-title">
										<i className="fas fa-tags"></i> {t("Select Your Voucher")}
									</h6>
								)}
							</div>

							<div className="custom-modal-body">
								<ul className="list-group list-group-flush px-4">{renderVouchers()}</ul>
							</div>
						</div>
					</div>
				) : null}

				<div className="mt-5 px-3" style={{ margin: "5%" }}>
					<br />
					<br />
					<button id="pay" onClick={handlePayment} className="btn form-control big-button">
						{t("PAY NOW")}
					</button>
					<br />
					<br />
					<button id="pay" onClick={handleCancel} className="btn form-control big-button">
						{t("CANCEL ORDER")}
					</button>
				</div>
			</div>

			<Modal
				show={discountCodeModal}
				onHide={e => setDiscountCodeModal(false)}
				keyboard={false}
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>{t("Enter Discount Code")}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div>
						<form onSubmit={checkDiscountCode}>
							{
								discountCode ? (
									<div className="form-group text-left">
										<label htmlFor="code">{t("Discount Code")}</label>
										<input type="text" id="code" name="code" value={discountCode} className="form-control browser-default" readOnly />
									</div>
								) : (
									<div className="form-group text-left">
										<label htmlFor="code">{t("Discount Code")}</label>
										<input type="text" id="code" name="code" value={discountCodeInput} className="form-control browser-default" onChange={e => setDiscountCodeInput(e.target.value.toUpperCase())} required />
									</div>
								)
							}
							<div className="text-center pt-3">
								{
									loading ? (
										<div className="text-center">
											<div className={`spinner-border text-${process.env.REACT_APP_STORETYPE === "cuci" ? "warning" : "primary"}`} role="status">
												<span className="sr-only">{t("Loading...")}</span>
											</div>
										</div>
									) : discountCode ? <input type="button" value={t("Remove Code")} onClick={() => removeVoucher()} className="btn btn-primary mx-2" />
										: <input type="submit" value={t("Submit")} className="btn btn-primary mx-2" />
								}
								<button type="button" className="btn btn-secondary" onClick={e => setDiscountCodeModal(false)}>
									{t("CLOSE")}
								</button>
							</div>
						</form>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		payment: state.payment,
		user: state.user.user,
		token: state.user.token
	};
};

const mapDispatchToProps = dispatch => {
	return {
		checkToken: () => dispatch(checkToken()),
		savePaymentProduct: data => dispatch(savePaymentProduct(data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
