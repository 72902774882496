import React, { Component } from "react";
import { connect } from "react-redux";
import Sidebar from "./components/Sidebar";
import Topbar from "./components/Topbar";
import Loading from "../../admin/components/Loading";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

const mySwal = withReactContent(Swal);

const colorSets = [
    {
        headColor: "#c596c4",
        bodyColor: "linear-gradient(135deg , #c8bfde , #a1c3e9)"
    },
    {
        headColor: "#fbcc7d",
        bodyColor: "linear-gradient(135deg , #f6b997 , #f38695)"
    },
    {
        headColor: "#93c9ed",
        bodyColor: "linear-gradient(135deg , #6599d3 , #77cebb)"
    },
    {
        headColor: "#c7a1aa",
        bodyColor: "linear-gradient(135deg , #f2d0e0 , #c6a9d2)"
    },
    {
        headColor: "#b6da98",
        bodyColor: "linear-gradient(135deg , #cee6a9 , #dbde91)"
    }
];

const randomNumber = Math.floor(Math.random() * colorSets.length);
const selectedColor = colorSets[randomNumber];

class ReferralSetting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggled: false,
            referral: false,
            loading: false,
            distributorId: "",
            enableToken: false,
            stamp: false,
            combined: false,
            specificStamp: "",
            data: null,
            voucher_eligible: false,
            voucher_amount: 0,
            voucher_type: "",
            voucher_expiry: true,
            voucher_duration: "",
            voucher_title: "",
            voucher_description: "",
            voucher_washer_use: false,
            voucher_dryer_use: false,
            // token_eligible: false,
            token_amount: 0,
            stamp_eligible: false,
            stamp_combined: 0,
            stamp_washer: 0,
            stamp_dryer: 0,
            point_eligible: 0,
            point_amount: 0,
            number_of_referral: 0,
            amount_transaction: 0,
        };
    }

    componentDidMount() {
        if (!this.props.client || !this.props.client.referral_setting) {
            return this.props.history.push('/client');
        }
    }

    handleToggle = () => this.setState({ ...this.state, toggled: !this.state.toggled });

    handleSelectDistributor = (e) => {
        let sendThis = {
            distributorId: e.target.value
        }

        this.setState({
            ...this.state,
            loading: true,
            distributorId: e.target.value
        });

        axios.post('/api/distributor/setting/getReferralSettings', sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
            .then(res => {
                this.setState({
                    ...this.state,
                    loading: false,
                    referral: res.data.referral,
                    data: res.data,
                    enableToken: res.data.token,
                    stamp: res.data.stamp,
                    combined: res.data.combined,
                    specificStamp: res.data.specificStamp,
                    voucher_eligible: res.data.referralSetting[0].voucher_setting.voucher_eligible,
                    voucher_amount: res.data.referralSetting[0].voucher_setting.voucher_amount,
                    voucher_type: res.data.referralSetting[0].voucher_setting.voucher_type,
                    voucher_expiry: res.data.referralSetting[0].voucher_setting.voucher_expiry,
                    voucher_duration: res.data.referralSetting[0].voucher_setting.voucher_duration,
                    voucher_title: res.data.referralSetting[0].voucher_setting.voucher_title,
                    voucher_description: res.data.referralSetting[0].voucher_setting.voucher_description,
                    voucher_washer_use: res.data.referralSetting[0].voucher_setting.voucher_washer_use,
                    voucher_dryer_use: res.data.referralSetting[0].voucher_setting.voucher_dryer_use,
                    // token_eligible: res.data.referralSetting[0].token_eligible,
                    token_amount: res.data.referralSetting[0].token_amount,
                    stamp_eligible: res.data.referralSetting[0].stamp_eligible,
                    stamp_combined: res.data.referralSetting[0].stamp_combined,
                    stamp_washer: res.data.referralSetting[0].stamp_washer,
                    stamp_dryer: res.data.referralSetting[0].stamp_dryer,
                    point_eligible: res.data.referralSetting[0].point_eligible,
                    point_amount: res.data.referralSetting[0].point_amount,
                    number_of_referral: res.data.referralSetting[0].number_of_referral,
                    amount_transaction: res.data.referralSetting[0].amount_transaction
                });
            })
            .catch(err => {
                this.setState({
                    ...this.state,
                    loading: false
                });
                mySwal.fire("Error", err.response.data.error, "error");
            });
    }

    handleEnable = e => {

        axios
            .post("/api/admin/setting/enableReferral", { id: this.state.distributorId, role: "distributor", data: !this.state.referral }, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
            .then(res => {
                this.setState({
                    ...this.state,
                    referral: res.data.data
                });
            })
            .catch(err => mySwal.fire("Error", err.response.data.error, "error"));
    };

    handleCheckbox = e => {
        this.setState({
            ...this.state,
            [e.target.id]: !this.state[e.target.id]
        });
    };

    handleChange = e => {
        this.setState({
            ...this.state,
            [e.target.id]: e.target.value
        });
    };

    handleSubmit = e => {
        e.preventDefault();

        const sendThis = {
            id: this.state.distributorId,
            role: "distributor",
            voucher_eligible: this.state.voucher_eligible,
            voucher_amount: this.state.voucher_amount,
            voucher_type: this.state.voucher_type,
            voucher_expiry: this.state.voucher_expiry,
            voucher_duration: this.state.voucher_duration,
            voucher_title: this.state.voucher_title,
            voucher_description: this.state.voucher_description,
            voucher_washer_use: this.state.voucher_washer_use,
            voucher_dryer_use: this.state.voucher_dryer_use,
            // token_eligible: this.state.token_eligible,
            token_amount: this.state.token_amount,
            stamp_eligible: this.state.stamp_eligible,
            stamp_combined: this.state.stamp_combined,
            stamp_washer: this.state.stamp_washer,
            stamp_dryer: this.state.stamp_dryer,
            point_eligible: this.state.point_eligible,
            point_amount: this.state.point_amount,
            number_of_referral: this.state.number_of_referral,
            amount_transaction: this.state.amount_transaction
        };

        this.setState({
            ...this.state,
            loading: true
        });

        axios.post('/api/admin/setting/updateReferral', sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
            .then(res => {
                this.setState({
                    ...this.state,
                    loading: false,
                })

                mySwal.fire({
                    title: "Success",
                    text: "The referral setting has been updated successfully.",
                    icon: "success",
                    confirmButtonColor: "#ffd109"
                });
            })
            .catch(err => {
                mySwal.fire("Error", err.response.data.error, "error");
                this.setState({
                    ...this.state,
                    loading: false
                });
            });
    };

    render() {

        const stampSetting = () => {
            if (this.state.combined) {
                return (
                    <div className="form-group">
                        <label htmlFor="stamp_combined">Number of Stamp : </label>
                        <input type="number" id="stamp_combined" name="stamp_combined" value={this.state.stamp_combined || 0} onChange={this.handleChange} min="0" required />
                    </div>
                );
            } else {
                if (this.state.specificStamp === "both") {
                    return (
                        <div>
                            <div className="form-group">
                                <label htmlFor="stamp_washer">Number of Washer Stamp: </label>
                                <input type="number" id="stamp_washer" name="stamp_washer" value={this.state.stamp_washer || 0} onChange={this.handleChange} min="0" required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="stamp_dryer">Number of Dryer Stamp: </label>
                                <input type="number" id="stamp_dryer" name="stamp_dryer" value={this.state.stamp_dryer || 0} onChange={this.handleChange} min="0" required />
                            </div>
                        </div>
                    );
                } else if (this.state.specificStamp === "washerOnly") {
                    return (
                        <div className="form-group">
                            <label htmlFor="stamp_washer">Number of Washer Stamp: </label>
                            <input type="number" id="stamp_washer" name="stamp_washer" value={this.state.stamp_washer || 0} onChange={this.handleChange} min="0" required />
                        </div>
                    );
                } else if (this.state.specificStamp === "dryerOnly") {
                    return (
                        <div className="form-group">
                            <label htmlFor="stamp_dryer">Number of Dryer Stamp: </label>
                            <input type="number" id="stamp_dryer" name="stamp_dryer" value={this.state.stamp_dryer || 0} onChange={this.handleChange} min="0" required />
                        </div>
                    );
                }
            }
        }

        return (
            <div id="admin-dashboard" className="admin-page-container">
                <Sidebar toggled={this.state.toggled} setToggled={this.handleToggle} />
                <div className="admin-page">
                    <Topbar handleToggle={this.handleToggle} />
                    <div className="admin-content">

                        <div className="text-center">
                            <h3>Referral Settings</h3>
                        </div>
                        <div className="pb-2">
                            <label htmlFor="distributorId">Select Distributor: </label>
                            <select id="distributorId" name="distributorId" className="browser-default form-select" value={this.state.distributorId} onChange={this.handleSelectDistributor}>
                                <option disabled value="">Select Distributor</option>
                                {
                                    this.props.client.distributor.map(distributor => <option key={distributor.id} value={distributor.id}>{distributor.name}</option>)
                                }
                            </select>
                        </div>

                        {
                            this.state.distributorId && !this.state.loading ? (
                                <div>
                                    <div className="mt-4 mb-5">
                                        <div className="form-group">
                                            <label>
                                                <input type="checkbox" id="enable" name="enable" checked={this.state.referral} onChange={this.handleEnable} />
                                                <span>Enable Referral Feature?</span>
                                            </label>
                                        </div>
                                    </div>

                                    {this.state.distributorId && this.state.referral ? (
                                        <div className="card">
                                            <div className="card-header">
                                                <h5 className="card-title">Referral Setting</h5>
                                            </div>

                                            <form onSubmit={this.handleSubmit}>
                                                <div id="dashboard-content" className="card-body">
                                                    <div className="container">
                                                        <div className="form-group">
                                                            <label htmlFor="number_of_referral">Maximum number of referrals for each user : </label>
                                                            <input type="number" id="number_of_referral" name="number_of_referral" value={this.state.number_of_referral || 0} required min="0" onChange={this.handleChange} />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="amount_transaction">Minimum amount of transaction : <small> (RM)</small></label>
                                                            <input type="number" id="amount_transaction" name="amount_transaction" value={this.state.amount_transaction || 0} required min="0" onChange={this.handleChange} />
                                                        </div>
                                                    </div>

                                                    {
                                                        this.state.stamp ? (
                                                            <div className="card">
                                                                <div className="card-header">
                                                                    <h6 className="m-0 p-0">Stamp</h6>
                                                                </div>

                                                                <div className="card-body">
                                                                    <div className="form-group">
                                                                        <label htmlFor="stamp_eligible">
                                                                            <input type="checkbox" id="stamp_eligible" name="stamp_eligible" checked={this.state.stamp_eligible || false} onChange={this.handleCheckbox} />
                                                                            <OverlayTrigger
                                                                                placement="top"
                                                                                overlay={
                                                                                    <Tooltip>
                                                                                        If ticked, the system will give stamp to user
                                                                                    </Tooltip>
                                                                                }
                                                                            >
                                                                                <span>Eligibility</span>
                                                                            </OverlayTrigger>
                                                                        </label>
                                                                    </div>
                                                                    {
                                                                        stampSetting()
                                                                    }
                                                                </div>
                                                            </div>
                                                        ) : null
                                                    }

                                                    <div className="card" style={{ width: "initial" }}>
                                                        <div className="card-header">
                                                            <h6 className="m-0 p-0">Voucher</h6>
                                                        </div>

                                                        <div className="card-body">
                                                            <div>
                                                                <div>
                                                                    {/* stamp voucher preview */}
                                                                    <div className="voucher mb-5">
                                                                        <div className="voucher-ball left-ball"></div>
                                                                        <div className="voucher-head p-0 m-0" style={{ backgroundColor: selectedColor.headColor }}>
                                                                            <h4 style={{ width: "140px", height: "140px", padding: "18px 0 0 0" }}>DISCOUNT</h4>
                                                                        </div>

                                                                        <div className="voucher-content" style={{ background: selectedColor.bodyColor }}>
                                                                            <div>
                                                                                <h1>{this.state.voucher_type === "Flat" ? (`RM${this.state.voucher_amount}`) : (`${this.state.voucher_amount}%`)}</h1>
                                                                            </div>

                                                                            <div>
                                                                                <h5>{this.state.voucher_title}</h5>
                                                                                <h6>{this.state.voucher_description}</h6>
                                                                            </div>
                                                                        </div>
                                                                        <div className="voucher-ball right-ball"></div>
                                                                    </div>
                                                                </div>

                                                                <div className="text-center">
                                                                    {/* checkboxes */}
                                                                    <div style={{ maxWidth: "400px", margin: "auto", display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                                                                        <div className="form-group">
                                                                            <label htmlFor="voucher_eligible">
                                                                                <input type="checkbox" id="voucher_eligible" name="voucher_eligible" checked={this.state.voucher_eligible || false} onChange={this.handleCheckbox} />
                                                                                <OverlayTrigger
                                                                                    placement="top"
                                                                                    overlay={
                                                                                        <Tooltip>
                                                                                            If ticked, the system will give voucher to user
                                                                                        </Tooltip>
                                                                                    }
                                                                                >
                                                                                    <span>Eligibility</span>
                                                                                </OverlayTrigger>
                                                                            </label>
                                                                        </div>

                                                                        <div className="form-group">
                                                                            <label htmlFor="voucher_expiry">
                                                                                <input type="checkbox" id="voucher_expiry" name="voucher_expiry" checked={this.state.voucher_expiry || false} onChange={this.handleCheckbox} />
                                                                                <OverlayTrigger
                                                                                    placement="top"
                                                                                    overlay={
                                                                                        <Tooltip>
                                                                                            If ticked, the voucher will have expired date
                                                                                        </Tooltip>
                                                                                    }
                                                                                >
                                                                                    <span>Expiry</span>
                                                                                </OverlayTrigger>
                                                                            </label>
                                                                        </div>
                                                                    </div>

                                                                    <div style={{ maxWidth: "400px", margin: "auto", display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                                                                        <div className="form-group">
                                                                            <label>
                                                                                <input type="checkbox" id="voucher_washer_use" name="voucher_washer_use" checked={this.state.voucher_washer_use || false} onChange={this.handleCheckbox} />
                                                                                <OverlayTrigger
                                                                                    placement="top"
                                                                                    overlay={
                                                                                        <Tooltip>
                                                                                            If ticked, the voucher can apply to washer machine transaction
                                                                                        </Tooltip>
                                                                                    }
                                                                                >
                                                                                    <span>Washer Use</span>
                                                                                </OverlayTrigger>
                                                                            </label>
                                                                        </div>

                                                                        <div className="form-group">
                                                                            <label>
                                                                                <input type="checkbox" id="voucher_dryer_use" name="voucher_dryer_use" checked={this.state.voucher_dryer_use || false} onChange={this.handleCheckbox} />
                                                                                <OverlayTrigger
                                                                                    placement="top"
                                                                                    overlay={
                                                                                        <Tooltip>
                                                                                            If ticked, the voucher can apply to dryer machine transaction
                                                                                        </Tooltip>
                                                                                    }
                                                                                >
                                                                                    <span>Dryer Use</span>
                                                                                </OverlayTrigger>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div>
                                                                    {/* text and select */}
                                                                    {
                                                                        this.state.voucher_expiry
                                                                            ? (
                                                                                <div className="form-group">
                                                                                    <label htmlFor="voucher_duration">Voucher Lifespan : <small> (days)</small></label>
                                                                                    <input type="number" id="voucher_duration" name="voucher_duration" value={this.state.voucher_duration || 0} onChange={this.handleChange} min="0" required />
                                                                                </div>
                                                                            )
                                                                            : (
                                                                                null
                                                                            )
                                                                    }

                                                                    <div className="form-group">
                                                                        <label htmlFor="voucher_type">Discount Type : </label>
                                                                        <select className="browser-default form-select" id="voucher_type" name="voucher_type" value={this.state.voucher_type || ""} onChange={this.handleChange} required>
                                                                            <option value="" disabled>Select a discount type</option>
                                                                            <option value="Flat">Flat (- RM)</option>
                                                                            <option value="Rate">Rate (- %)</option>
                                                                        </select>
                                                                    </div>

                                                                    <div className="form-group">
                                                                        <label htmlFor="voucher_title">Voucher Title : </label>
                                                                        <input type="text" id="voucher_title" name="voucher_title" placeholder="Voucher Title" value={this.state.voucher_title || ""} onChange={this.handleChange} required />
                                                                    </div>

                                                                    <div className="form-group">
                                                                        <label htmlFor="voucher_description">Voucher Description : </label>
                                                                        <input type="text" id="voucher_description" name="voucher_description" placeholder="Voucher Description" value={this.state.voucher_description || ""} onChange={this.handleChange} required />
                                                                    </div>

                                                                    <div className="form-group">
                                                                        <label htmlFor="voucher_amount">Voucher Amount : <small>{this.state.voucher_type === "Flat" ? "(RM)" : "(%)"}</small></label>
                                                                        <input type="number" id="voucher_amount" name="voucher_amount" value={this.state.voucher_amount || 0} onChange={this.handleChange} min="0" required />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {this.state.enableToken ? (
                                                        <div className="card">
                                                            <div className="card-header">
                                                                <h6 className="m-0 p-0">Token</h6>
                                                            </div>

                                                            <div className="card-body">
                                                                <div className="form-group">
                                                                    {/* <label htmlFor="token_eligible">
                                                                        <input type="checkbox" id="token_eligible" name="token_eligible" checked={this.state.token_eligible || false} onChange={this.handleCheckbox} />
                                                                        <OverlayTrigger
                                                                            placement="top"
                                                                            overlay={
                                                                                <Tooltip>
                                                                                    If ticked, the system will give e-Token to user
                                                                                </Tooltip>
                                                                            }
                                                                        >
                                                                            <span>Eligibility</span>
                                                                        </OverlayTrigger>
                                                                    </label> */}
                                                                </div>

                                                                <div className="form-group">
                                                                    <label htmlFor="token_amount">Number of E-Token : </label>
                                                                    <input type="number" id="token_amount" name="token_amount" value={this.state.token_amount || 0} onChange={this.handleChange} min="0" required />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : null
                                                    }

                                                </div>
                                                {
                                                    this.state.loading ? <Loading /> : (
                                                        <div className="mb-5 mx-5">
                                                            <input type="submit" value="SUBMIT" className="btn big-button form-control" />
                                                        </div>
                                                    )
                                                }
                                            </form>
                                        </div>
                                    ) : null}
                                </div>
                            ) : this.state.loading ? <Loading />
                                : null
                        }

                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        client: state.client.client,
        token: state.client.token
    };
};

export default connect(mapStateToProps, null)(ReferralSetting);
