import React, { Component } from "react";
import { connect } from "react-redux";
import Sidebar from "../../components/Sidebar";
import Topbar from "../../components/Topbar";
import Loading from "../../components/Loading";
import { MDBDataTable } from "mdbreact";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { CSVLink } from "react-csv";

const mySwal = withReactContent(Swal);

class UserPerformance extends Component {
	constructor(props) {
		super(props);
		this.state = {
			toggled: false,
			data: null,
			loading: true,
			// outletId : "",
			startDate: "",
			endDate: ""
		};
	}
	_isMounted = false;

	componentDidMount() {
		this._isMounted = true;
		if (this._isMounted) {
			if (!this.props.admin.isAuthenticated) {
				return this.props.history.push("/admin/dashboard");
			}
			else {
				const { operatorId } = this.props.admin.admin;
				// console.log(operatorId)
				axios
					.post("/api/admin/report/user", { operatorId }, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
					.then(res => {
						// console.log(res)
						this.setState({
							...this.state,
							loading: false,
							data: res.data.data
						});
					})
					.catch(err => {
						this.setState({
							...this.state,
							loading: false
						});
						mySwal.fire("Error", err.response.data.error, "error");
					});
			}
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	handleToggle = () => this.setState({ ...this.state, toggled: !this.state.toggled });

	// handleChange = e => this.setState({
	//     ...this.state,
	//     [e.target.id] : e.target.value
	// });

	handleDate = dates => {
		const [start, end] = dates;
		// console.log({start , end});
		this.setState({
			...this.state,
			startDate: start,
			endDate: end
		});
	};



	handleFilter = e => {
		const { outletId, startDate, endDate } = this.state;
		let sendThis = {
			operatorId: this.props.admin.admin.operatorId
		};

		if (outletId) {
			sendThis.outletId = outletId;
		}
		if (startDate && endDate) {
			sendThis.startDate = new Date(startDate);
			sendThis.endDate = new Date(endDate).setHours(23, 59, 59, 999);
		}
		this.setState({
			...this.state,
			loading: true
		});
		axios
			.post("/api/admin/report/user", sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
			.then(res => {
				this.setState({
					...this.state,
					loading: false,
					data: res.data.data
				});
			})
			.catch(err => {
				this.setState({
					...this.state,
					loading: false
				});
				mySwal.fire("Error", err.response.data.error, "error");
			});
	};

	handleReset = e => {
		this.setState({
			...this.state,
			outletId: "",
			startDate: "",
			endDate: "",
			loading: true
		});
		const operatorId = this.props.admin.admin.operatorId;

		axios
			.post("/api/admin/report/user", { operatorId }, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
			.then(res => {
				this.setState({
					...this.state,
					loading: false,
					data: res.data.data
				});
			})
			.catch(err => {
				this.setState({
					...this.state,
					loading: false
				});
				mySwal.fire("Error", err.response.data.error, "error");
			});
	};

	render() {
		let data = {
			columns: [
				{
					label: "Name",
					field: "userName",
					sort: "asc"
				},
				{
					label: "Number",
					field: "number",
					sort: "asc"
				},
				{
					label: "Category",
					field: "category",
					sort: "asc"
				},
				{
					label: "Join Date",
					field: "joinedDate",
					sort: "asc"
				},
				{
					label: "Member ID",
					field: "memberId",
					sort: "asc"
				},
				{
					label: "Tier",
					field: "tier",
					sort: "asc"
				},
				{
					label: "Referrer",
					field: "referrer",
					sort: "asc"
				},
				{
					label: "Referrals",
					field: "referrals",
					sort: "asc"
				},
				{
					label: "Number of Usage (in week)",
					field: "weekUsage",
					sort: "asc"
				},
				{
					label: "Number of Usage (in month)",
					field: "monthUsage",
					sort: "asc"
				},
				{
					label: "Total Spent (RM)",
					field: "rmSpent",
					sort: "asc"
				},
				{
					label: "Total Spent (POINTS)",
					field: "totalPoint",
					sort: "asc"
				},
				{
					label: "Total Spent (CREDITS)",
					field: "totalCredit",
					sort: "asc"
				},
				{
					label: "Last Transaction Date",
					field: "lastTransaction",
					sort: "asc"
				},
				{
					label: "Voucher Collected",
					field: "voucherAcquired",
					sort: "asc"
				},
				{
					label: "Voucher Spent",
					field: "voucherUsed",
					sort: "asc"
				},
			],
			rows: this.state.data ?? []
		};

		let csv = [];

		if (this.state.data) {
			for (let i = 0; i < this.state.data.length; i++) {
				let data = {
					userName: this.state.data[i].userName,
					number: `=""${this.state.data[i].number}""`,
					joinedDate: this.state.data[i].joinedDate,
					memberId: this.state.data[i].memberId,
					tier: this.state.data[i].tier,
					referrer: this.state.data[i].referrer,
					referrals: this.state.data[i].referrals,
					weekUsage: this.state.data[i].weekUsage,
					monthUsage: this.state.data[i].monthUsage,
					// totalStamps: this.state.data[i].totalStamps,
					rmSpent: this.state.data[i].rmSpent,
					totalPoint: this.state.data[i].totalPoint,
					totalCredit: this.state.data[i].totalCredit,
					lastTransaction: this.state.data[i].lastTransaction,
					voucherAcquired: this.state.data[i].voucherAcquired,
					voucherUsed: this.state.data[i].voucherUsed,
					category: this.state.data[i].category,
				}
				csv.push(data);
			}
		}

		const csvData = {
			headers: [
				{ label: "Name", key: "userName" },
				{ label: "Number", key: "number" },
				{ label: "Category", key: "category" },
				{ label: "Join Date", key: "joinedDate" },
				{ label: "Member ID", key: "memberId" },
				{ label: "Tier", key: "tier" },
				{ label: "Referrer", key: "referrer" },
				{ label: "Referrals", key: "referrals" },
				{ label: "Number of Usage (in week)", key: "weekUsage" },
				{ label: "Number of Usage (in month)", key: "monthUsage" },
				{ label: "Total Spent (POINTS)", key: "totalPoint" },
				{ label: "Total Spent (CREDITS)", key: "totalCredit" },
				{ label: "Total Spent (RM)", key: "rmSpent" },
				{ label: "Last Transaction Date", key: "lastTransaction" },
				{ label: "Voucher Collected", key: "voucherAcquired" },
				{ label: "Voucher Spent", key: "voucherUsed" },
			],
			data: csv
		};

		if (this.props.admin.admin && this.props.admin.admin.token) {
			data.columns = [
				{
					label: "Name",
					field: "userName",
					sort: "asc"
				},
				{
					label: "Number",
					field: "number",
					sort: "asc"
				},
				{
					label: "Join Date",
					field: "joinedDate",
					sort: "asc"
				},
				{
					label: "Member ID",
					field: "memberId",
					sort: "asc"
				},
				{
					label: "Tier",
					field: "tier",
					sort: "asc"
				},
				{
					label: "Referrer",
					field: "referrer",
					sort: "asc"
				},
				{
					label: "Referrals",
					field: "referrals",
					sort: "asc"
				},
				{
					label: "Number of Usage (in week)",
					field: "weekUsage",
					sort: "asc"
				},
				{
					label: "Number of Usage (in month)",
					field: "monthUsage",
					sort: "asc"
				},
				{
					label: "Total Spent (RM)",
					field: "rmSpent",
					sort: "asc"
				},
				{
					label: "Total Spent (POINTS)",
					field: "totalPoint",
					sort: "asc"
				},
				{
					label: "Total Spent (CREDITS)",
					field: "totalCredit",
					sort: "asc"
				},
				{
					label: "Last Transaction Date",
					field: "lastTransaction",
					sort: "asc"
				},
				{
					label: "Voucher Collected",
					field: "voucherAcquired",
					sort: "asc"
				},
				{
					label: "Voucher Spent",
					field: "voucherUsed",
					sort: "asc"
				}
			];

			csvData.headers = [
				{ label: "Name", key: "userName" },
				{ label: "Number", key: "number" },
				{ label: "Category", key: "category" },
				{ label: "Join Date", key: "joinedDate" },
				{ label: "Member ID", key: "memberId" },
				{ label: "Tier", key: "tier" },
				{ label: "Referrer", key: "referrer" },
				{ label: "Referrals", key: "referrals" },
				{ label: "Number of Usage (in week)", key: "weekUsage" },
				{ label: "Number of Usage (in month)", key: "monthUsage" },
				{ label: "Total Spent (POINTS)", key: "totalPoint" },
				{ label: "Total Spent (CREDITS)", key: "totalCredit" },
				{ label: "Total Spent (RM)", key: "rmSpent" },
				{ label: "Last Transaction Date", key: "lastTransaction" },
				{ label: "Voucher Collected", key: "voucherAcquired" },
				{ label: "Voucher Spent", key: "voucherUsed" },
			]
		}

		return (
			<div className="admin-page-container">
				<Sidebar toggled={this.state.toggled} setToggled={this.handleToggle} />
				<div className="admin-page">
					<Topbar handleToggle={this.handleToggle} />
					<div className="admin-content">
						<div className="card">
							<div className="card-header">
								<h5 className="card-title">User Performance</h5>
							</div>

							<div className="card-body">
								<div className="mb-5">
									<h6>Filter</h6>
									<div>
										<label htmlFor="date">Select Date: </label>
										<DatePicker value={`${this.state.startDate ? new Date(this.state.startDate).toLocaleDateString() : ""} - ${this.state.endDate ? new Date(this.state.endDate).toLocaleDateString() : ""}`} onChange={this.handleDate} startDate={this.state.startDate} endDate={this.state.endDate} selectsRange shouldCloseOnSelect={false} />
									</div>

									<div className="d-flex">
										<button onClick={this.handleFilter} className="btn btn-primary btn-inline form-control me-3">
											Filter
										</button>
										<button onClick={this.handleReset} className="btn btn-secondary btn-inline form-control ms-3">
											Reset
										</button>
									</div>
								</div>
								<div className="text-center mt-5">
									{
										this.state.loading ? <Loading /> : (
											<div>
												<MDBDataTable data={data} noBottomColumns striped hover responsive bordered />
												<div className="d-grid gap-2 d-md-flex justify-content-md-end">
													<CSVLink className="btn btn-primary" data={csvData.data} headers={csvData.headers} filename="userPerformance.csv" target="_blank">
														Download Report
													</CSVLink>
												</div>
											</div>
										)
									}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		admin: state.admin
	};
};

export default connect(mapStateToProps, null)(UserPerformance);
