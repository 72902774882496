import { SCANNED_QRCODE, SAVE_CREDS, SAVE_PAYMENT_PRODUCT } from "./type";

export const scannedCode = data => {
	return {
		type: SCANNED_QRCODE,
		payload: data
	};
};

export const saveCreds = data => {
	return {
		type: SAVE_CREDS,
		payload: data
	};
};

export const savePaymentProduct = data => {
	return {
		type: SAVE_PAYMENT_PRODUCT,
		payload: data
	};
};
