import { useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from 'react-i18next';

import Sidebar from "../utilities/Sidebar";

const Transaction = ({ transactions, transfers, user }) => {
	const [toggled, setToggled] = useState(false);
	const [selected, setSelected] = useState("Transaction");

	const { t } = useTranslation();
	const renderDate = date => {
		const ascMonth = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
		const thisDate = new Date(date);

		let hour24 = "";
		let hour = "";

		if (thisDate.getUTCHours() >= 16) {
			hour24 = thisDate.getUTCHours() + 8 - 24;
		} else {
			hour24 = thisDate.getUTCHours() + 8;
		}

		if (hour24 > 12) {
			hour = hour24 - 12;
			if (hour.toString().length === 1) {
				hour = `0${hour}`
			}
		} else {
			hour = hour24;
			if (hour.toString().length === 1) {
				hour = `0${hour}`
			}
		}

		const minute = thisDate.getUTCMinutes().toString().length === 1 ? `0${thisDate.getUTCMinutes()}` : thisDate.getUTCMinutes().toString();
		const day = thisDate.getDate().toString().length === 1 ? `0${thisDate.getDate()}` : thisDate.getDate().toString();
		const month = ascMonth[thisDate.getMonth()];
		const year = thisDate.getFullYear();
		return `${hour}:${minute} ${hour24 >= 12 ? "pm" : "am"} | ${day} ${month} ${year}`;
	};

	return (
		<div id="transaction-page">
			<Sidebar toggled={toggled} setToggled={setToggled} />
			<div id="user-header">
				<h5>
					<i onClick={e => setToggled(true)} className="fas fa-bars me-3"></i> <strong>{selected === "Transaction" ? t("Transaction History") : selected === "Point" ? t("Point History") : selected === "Credit" ? t("Credit History") : ""}</strong>
				</h5>
			</div>
			<div>
				<p className="text-center pt-2">{selected === "Transaction" ? t("Only up to last 3 months transaction history") : selected === "Point" ? t("Only up to last 3 months transaction history") : selected === "Credit" ? t("Only up to last 3 months transaction history") : ""}</p>
				<ul className="nav nav-tabs m-auto">
					<li className="nav-item">
						<button onClick={() => setSelected("Transaction")} className={selected === "Transaction" ? "nav-link active font-weight-bold" : "nav-link"} style={{ backgroundColor: "white", outline: "none", borderBottom: selected === "Transaction" ? null : "1px solid #dee2e6" }}>
							{t("Transaction")}
						</button>
					</li>
					<li className="nav-item">
						<button onClick={() => setSelected("Point")} className={selected === "Point" ? "nav-link active font-weight-bold" : "nav-link"} style={{ backgroundColor: "white", outline: "none", borderBottom: selected === "Point" ? null : "1px solid #dee2e6" }}>
							{t("Point")}
						</button>
					</li>
					{
						//|| user?.credits[0].type === "PPR"
						user.isPPR ?
							<li className="nav-item">
								<button onClick={() => setSelected("Credit")} className={selected === "Credit" ? "nav-link active font-weight-bold" : "nav-link"} style={{ backgroundColor: "white", outline: "none", borderBottom: selected === "Credit" ? null : "1px solid #dee2e6" }}>
									{t("Credit")}
								</button>
							</li>
							// : user?.credits[0].type === "WAWASAN" ? 
							// <li className="nav-item">
							// 	<button onClick={() => setSelected("Wawasan")} className={selected === "Wawasan" ? "nav-link active font-weight-bold" : "nav-link"} style={{ backgroundColor: "white", outline: "none", borderBottom: selected === "Credit" ? null : "1px solid #dee2e6" }}>
							// 		{t("Wawasan")}
							// 	</button>
							// </li>
							: ""
					}
				</ul>
				{
					selected === "Transaction" ? (
						transactions.filter(transaction => transaction.status !== "pending")
							.map((transaction, indexCount) => {
								return (
									<div key={transaction.id} className="transaction-list" style={{ height: "auto" }}>
										<div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
											<div >
												{/* <h6 className="font-weight-bold m-1 p-0" style={{ color: "blue" }}>ID:{transaction.id}</h6> */}
												<h6 className="font-weight-bold m-1 p-0">{transaction.product_name}</h6>
												<h6 className="font-weight-bold m-1 p-0">{renderDate(transaction.createdAt)}</h6>
												{/* {transaction.voucherId ?
													<h6 className="font-weight-bold m-1 p-0" style={{ color: "red" }}>Voucher ID: {transaction.voucherId}</h6>
													: ""}
												{transaction.campaignId ?
													<h6 className="font-weight-bold m-1 p-0" style={{ color: "red" }}>Discount ID: {transaction.campaignId}</h6>
													: ""} */}
											</div>
											<div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", marginLeft: "4px" }}>
												<h4 style={{ color: transaction.status === "success" ? "green" : transaction.status === "failed" ? "red" : "#0d6efd", fontWeight: "bold" }}>{transaction.status.toUpperCase()}</h4>
												{transaction.method.toUpperCase()}
												<h4 style={{ color: "#ffd109", fontWeight: "bold" }}>
													{transaction.method === "Point" ? "" : transaction.method === "Credit" ? "" : "RM"}
													{transaction.payment_amount}
													{transaction.method === "Point" ? "POINT" : transaction.method === "Credit" ? "CREDIT" : ""}
													{/* {transaction.method === "Point" ? "POINT" : ""} */}
												</h4>
											</div>
										</div>
									</div>
								);
							})
					)
						:
						selected === "Point" ? (
							user.points.map(point => {
								return (
									<div key={point.id} className="transaction-list" style={{ height: "auto" }}>
										<div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
											<div style={{ marginTop: "15px" }}>
												{/* <h6 className="font-weight-bold m-1 p-0">ID: {point.id}</h6> */}
												<h6 className="font-weight-bold m-1 p-0">{point.remark}</h6>
												<h6 className="font-weight-bold m-1 p-0">{renderDate(point.createdAt)}</h6>
											</div>
											<div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", marginLeft: "10px" }}>
												<br />
												<h4 style={{ color: "green", fontWeight: "bold" }}>
													{point.amount >= 0 ? "EARN" : "DEDUCT"}
													<br />
												</h4>
												<h4 style={{ color: "#ffd109", fontWeight: "bold" }}>
													{point.amount} POINT
												</h4>
											</div>
										</div>
									</div>
								)
							})
						)
							: selected === "Credit" ? (
								user.credits.map(credit => {
									return (
										<div key={credit.id} className="transaction-list">
											<div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
												<div style={{ marginTop: "15px" }}>
													{/* <h6 className="font-weight-bold m-1 p-0">ID: {credit.id}</h6>
													<h6 className="font-weight-bold m-1 p-0">TID: {credit.transactionId}</h6> */}
													<h6 className="font-weight-bold m-1 p-0">{credit.type} CREDITS</h6>
													<h6 className="font-weight-bold m-1 p-0">{credit.remark}</h6>
													<h6 className="font-weight-bold m-1 p-0">{renderDate(credit.createdAt)}</h6>
												</div>
												<div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", marginLeft: "10px" }}>
													<br />
													<h4 style={{ color: "green", fontWeight: "bold" }}>
														{credit.amount >= 0 ? "GAIN" : "DEDUCT"}
														<br />
													</h4>
													<h4 style={{ color: "#ffd109", fontWeight: "bold" }}>
														{credit.amount} CREDIT
													</h4>
												</div>
											</div>
										</div>
									)
								})
							)
								: null
				}
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		transactions: state.user.user?.transactions || [],
		user: state.user.user
	};
};

export default connect(mapStateToProps, null)(Transaction);
