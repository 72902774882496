import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import queryString from 'query-string';
import { adminLogin, adminCheckSession, adminTokenLogin } from '../../../redux/actions/adminActions';
import { logoController } from '../../../dynamicController';
import viotLogo from "../../../assets/images/logo/ViotLogo.png"

const Login = ({ login, admin, checkSession, location, tokenLogin, themeLogo }) => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    useEffect(() => {
        const { token } = queryString.parse(location.search);
        // console.log({token});
        if (token) {
            tokenLogin({ token });
        } else {
            checkSession(() => console.log("Logged In"));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = e => {
        e.preventDefault();
        // console.log({username , password});
        login({ username, password });
    };

    if (admin.isAuthenticated) {
        return <Redirect to="/admin/dashboard" />
    } else {

        return (
            <div className="admin-bgImg">
                <div style={{ height: "100vh", width: "100vw", display: "flex", alignItems: "center" }}>
                    <div id="admin-login">
                        <div id="login-header">
                            <img src={viotLogo} alt="main logo" className="img-fluid" />
                            <br />
                            <br />
                            {/* <h4 className="text-left" style={{ color: "#4a536b" }}>Easy &amp; Fast Laundromate</h4>
                            <h6 className="text-left" style={{ color: "#4a536b" }}>Smart Laundry Management System.</h6> */}
                        </div>

                        <div id="login-form">
                            <h4>Login</h4>
                            <p>Enter your username and password to login.</p>
                            {
                                admin.error ? (
                                    <div className={process.env.REACT_APP_STORETYPE === "cuci" ? "alert alert-warning" : "alert alert-primary"} role="alert">
                                        <strong>{admin.error}</strong>
                                    </div>
                                ) : (null)
                            }
                            <form onSubmit={handleSubmit} className="mt-4">
                                <div className="form-group">
                                    {/* <label htmlFor="username">Username: </label> */}
                                    <input type="text" id="username" name="username" placeholder="Username" className="form-control browser-default" value={username} onChange={e => setUsername(e.target.value)} required />
                                </div>
                                <div className="form-group">
                                    {/* <label htmlFor="password">Password: </label> */}
                                    <input type="password" id="password" name="password" placeholder="Password" className="form-control browser-default" value={password} onChange={e => setPassword(e.target.value)} required />
                                </div>
                                <div className="mt-4">
                                    {
                                        admin.isLoading ? (
                                            <div className="text-center">
                                                <div className={process.env.REACT_APP_STORETYPE === "cuci" ? "spinner-border text-warning" : "spinner-border text-primary"} role="status">
                                                    <span className="sr-only">Loading ...</span>
                                                </div>
                                            </div>
                                        ) : (
                                            <input type="submit" value="LOGIN" className="btn form-control admin-login-button" />
                                        )
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

const mapStateToProps = state => {
    return {
        admin: state.admin,
        themeLogo: state.theme.logo
    };
};

const mapDispatchToProps = dispatch => {
    return {
        login: data => dispatch(adminLogin(data)),
        checkSession: callback => dispatch(adminCheckSession(callback)),
        tokenLogin: token => dispatch(adminTokenLogin(token))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);