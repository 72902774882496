import React, { Component } from 'react';
import axios from "axios";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import Topbar from '../components/Topbar';
import Sidebar from '../components/Sidebar';
import withReactContent from "sweetalert2-react-content";
const mySwal = withReactContent(Swal);

class EditUserType extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            token: false,
            name: null,
            type: '',
        };
    };

    componentDidMount() {
        if (!this.props.admin.admin) {
            this.props.history.push("/admin/dashboard");
        }
        else {
            axios
                .post("/api/admin/setting/getEditUserType", { id: this.props.location.state.typeId }, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
                .then(res => {
                    this.setState({
                        ...this.state,
                        name: res.data.name,
                        type: res.data.type,
                        loading: false
                    });
                })
                .catch(err =>
                    mySwal.fire("Error", err.response.data.error, "error")
                );
        }
    }

    handleToggle = () => this.setState({ ...this.state, toggled: !this.state.toggled });

    submitUserType = (e) => {
        e.preventDefault();

        if (this.state.name && this.state.type) {
            mySwal.fire({
                title: "Confirmation",
                text: "Are you sure you want to create new user type?",
                icon: "question",
                showCancelButton: true,
                cancelButtonText: "No",
                confirmButtonText: "Yes",
                cancelButtonColor: "#d33"
            })
                .then(click => {
                    if (click.isConfirmed && click.value) {
                        this.setState({
                            ...this.state,
                            loading: true
                        });
                    }

                    const type = this.state.type;
                    const sendThis = {
                        id: this.props.location.state.typeId,
                        name: this.state.name,
                        type: type,
                    };

                    axios.post('/api/admin/setting/editUserType', sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
                        .then(res => {
                            this.setState({
                                ...this.state,
                                loading: false
                            });

                            mySwal.fire({
                                title: "Success",
                                text: "Successfully edit the user type.",
                                icon: "success",
                                confirmButtonColor: "#ffd109"
                            });
                        })
                        .catch(err => {
                            console.log(err)
                            mySwal.fire("Error", err.response.data.error, "error");
                            this.setState({
                                ...this.state,
                                loading: false
                            });
                        });
                })
        }
    }

    onChange = (e) => {
        this.setState({ value: e.target.value });
    };

    handleSingle = (event) => {
        this.setState({ type: event.target.value });
    }

    handleChange = e => {
        this.setState({
            ...this.state,
            [e.target.id]: e.target.value
        });
    };

    render() {
        const options = [
            { value: 'PPR', label: 'PPR' },
            { value: 'WAWASAN', label: 'WAWASAN' }
        ];

        return (
            <div className="admin-page-container">
                <Sidebar toggled={this.state.toggled} setToggled={this.handleToggle} />
                <div className="admin-page">
                    <Topbar handleToggle={this.handleToggle} />
                    <div className="admin-content">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="card-title">Edit User Type: {this.props.location.state.typeId} </h5>
                            </div>
                            <div className="card-body">
                                <form onSubmit={this.submitUserType}>
                                    <div class="form-group ms-5">
                                        <h5>Name</h5>
                                        <input
                                            id='name'
                                            name='name'
                                            type="text"
                                            class="form-control"
                                            placeholder="Please enter suitable name."
                                            onChange={this.handleChange}
                                            value={this.state.name} />
                                    </div>
                                    <br />
                                    <div className="form-group ms-5">
                                        <h5>Type(s)</h5>
                                        <select class="form-select"
                                            aria-label="Default select example"
                                            value={this.state.type}
                                            onChange={this.handleSingle}
                                        >
                                            {options.map(option => (
                                                <option
                                                    key={option.value}

                                                >
                                                    {
                                                        option.label
                                                    }
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <br />
                                    <br />
                                    <div>
                                        {
                                            this.state.loading
                                                ? (
                                                    <div className="text-center">
                                                        <div className={`spinner-border text-${process.env.REACT_APP_STORETYPE === "cuci" ? "warning" : "primary"}`} role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                    </div>
                                                )
                                                : <input type="submit" value="SUBMIT" className="btn btn-block btn-warning big-button" />
                                        }
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
};

const mapStateToProps = state => {
    return {
        admin: state.admin
    };
};

// const mapDispatchToProps = dispatch => {
//     return {
//         updateCredit: data => dispatch(updateCredit(data))
//     };
// };

export default connect(mapStateToProps, null)(EditUserType);