import React, { Component } from 'react';
import axios from "axios";
import { connect } from "react-redux";
import Sidebar from '../components/Sidebar';
import Topbar from '../components/Topbar';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { updateCredit } from "../../../redux/actions/adminActions";

const mySwal = withReactContent(Swal);
class AddCredit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            token: false,
            rm_amount: 1,
            exchange_ratio: 1,
            credit_amount: 1,
            category: "GENERAL",
            colSpan: "1",
            type: "",
            terminalData: [{
                TID: "",
                id: 0,
                name: "",
                seriesNo: "",
                status: "",
                code: ""
            }],
            terminalCode: ""
        };
    };

    componentDidMount() {
        if (!this.props.admin.admin) {
            this.props.history.push("/admin/dashboard");
        }
        else {
            this.setState({
                loading: false
            });

            axios.post("/api/admin/setting/getTerminals", { id: this.props.admin.admin.operatorId }, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
                .then(res => {
                    const updatedTerminalData = [...this.state.terminalData, ...res.data];
                    this.setState({
                        terminalData: updatedTerminalData,
                        loading: false
                    })
                }).catch(err => {
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                    mySwal.fire("Error", err.response.data.error, "error");
                });
        }
    }

    handleToggle = () => this.setState({ ...this.state, toggled: !this.state.toggled });

    handleAddCredit = (e) => {
        e.preventDefault();
        if (this.state.credit_amount &&
            this.state.exchange_ratio &&
            this.state.type &&
            this.state.terminalCode) {
            mySwal.fire({
                title: "Confirmation",
                text: "Are you sure you want to add the credit setting?",
                icon: "question",
                showCancelButton: true,
                cancelButtonText: "No",
                confirmButtonText: "Yes",
                cancelButtonColor: "#d33"
            }).then(click => {
                if (click.isConfirmed && click.value) {
                    this.setState({
                        ...this.state,
                        loading: true
                    });
                }

                const sendThis = {
                    adminId: this.props.admin.admin.operatorId,
                    rm_amount: this.state.rm_amount,
                    exchange_ratio: this.state.exchange_ratio,
                    credit_amount: (this.state.rm_amount * this.state.exchange_ratio),
                    category: this.state.category,
                    colSpan: this.state.colSpan,
                    type: this.state.type,
                    terminalCode: this.state.terminalCode
                }

                axios.post('/api/admin/setting/addCreditSetting', sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
                    .then(res => {
                        if (res.data) {
                            this.setState({
                                ...this.state,
                                loading: false
                            });

                            this.props.updateCredit(sendThis);

                            mySwal.fire({
                                title: "Success",
                                text: "Your credit setting has been created successfully.",
                                icon: "success",
                                confirmButtonColor: "#ffd109"
                            });
                        }
                    }).catch(err => {
                        console.log(err)
                        mySwal.fire("Error", err.response.data.error, "error");
                        this.setState({
                            ...this.state,
                            loading: false
                        });
                    });
            })
        }
        else {
            mySwal.fire("Error", "Please fill all of the fields", "error");
        }
    }

    handleTerminal = (event) => {
        const terminal = event.target.value;
        const code = terminal.split(" - ")[1].trim();
        this.setState({
            ...this.state,
            terminalCode: code
        });
    }

    handleChange = e => {
        this.setState({
            ...this.state,
            [e.target.id]: e.target.value
        }, () => {
            this.calculateOutput();
        });
    };

    handleCol = e => {
        this.setState({
            ...this.state,
            type: e.target.value
        })
    }

    handleString = e => {
        this.setState({
            ...this.state,
            category: e.target.value
        })
    }

    calculateOutput() {
        const credit_amount = this.state.rm_amount * this.state.exchange_ratio;
        this.setState({ credit_amount });
    }



    render() {
        return (
            <div className="admin-page-container">
                <Sidebar toggled={this.state.toggled} setToggled={this.handleToggle} />
                <div className="admin-page">
                    <Topbar handleToggle={this.handleToggle} />
                    <div className="admin-content">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="card-title">Create Credit Setting</h5>
                            </div>
                            <div className="card-body">
                                {/* <form onSubmit={this.handlePointSetting}>
                                    <h5>Multiply Ratio</h5>
                                    <p>Credit multiply by the ratio depend on category (Covert to RM)</p>
                                    <div className="d-flex">
                                        <div className="form-group ms-5">
                                            <label style={{ width: "70%" }}>
                                                <input id="rm_amount" name="rm_amount" value={this.state.rm_amount} onChange={this.handleChange} required min="1"/>
                                                <span>Sample Ringgit Malaysia (RM)</span>
                                            </label>
                                        </div>

                                        <div className="form-group">
                                            <label style={{ width: "80%" }}>
                                                <input id="exchange_ratio" type="number" name="exchange_ratio" value={this.state.exchange_ratio} onChange={this.handleChange} required min="1" />
                                                <span>Ratio</span>
                                            </label>
                                        </div>
                                        <div className="form-group">
                                            <h4> = </h4>
                                        </div>
                                        <div className="form-group ms-5">
                                            <label style={{ width: "50%" }}>
                                                <input id="credit_amount" type="number" name="credit_amount" disabled value={this.state.credit_amount} />
                                                <span>Sample Credit</span>
                                            </label>
                                        </div>
                                        <div className="form-group ms-5">
                                            <label style={{ width: "70%" }}>
                                                <input id="type" name="type" min="1" value={this.state.type} onChange={this.handleCol} required />
                                                <span>Type</span>
                                            </label>
                                        </div>
                                        <div className="form-group ms-5">
                                            <label style={{ width: "50%" }}>
                                                <input id="category" name="category" value={this.state.category} onChange={this.handleString} required />
                                                <span>Category</span>
                                            </label>
                                        </div>
                                    </div>
                                    <br />
                                    <br />
                                    <div>
                                        <h5>Item Credit Calculation</h5>
                                        <p>Item Credit will based on that category price multiply by credit ratio</p>
                                    </div>
                                    <div>
                                        <p>{this.state.category} item: {this.state.rm_amount * (this.state.exchange_ratio)} CREDITS</p>
                                    </div>
                                    <br />
                                    <br />
                                    <div>
                                        {
                                            this.state.loading
                                                ? (
                                                    <div className="text-center">
                                                        <div className={`spinner-border text-${process.env.REACT_APP_STORETYPE === "cuci" ? "warning" : "primary"}`} role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                    </div>
                                                )
                                                : <input type="submit" value="SUBMIT" className="btn btn-block btn-warning big-button" />
                                        }
                                    </div>
                                </form> */}
                                <form onSubmit={this.handleAddCredit}>
                                    <h5>Multiply Ratio</h5>
                                    <p>Credit multiply by the ratio depend on category (Covert to RM)</p>
                                    <div className="d-flex">
                                        <div className="form-group ms-5">
                                            <label style={{ width: "70%" }}>
                                                <input id="rm_amount" name="rm_amount" value={this.state.rm_amount} onChange={this.handleChange} required min="1" />
                                                <span>Sample Ringgit Malaysia (RM)</span>
                                            </label>
                                        </div>

                                        <div className="form-group">
                                            <label style={{ width: "80%" }}>
                                                <input id="exchange_ratio" type="number" name="exchange_ratio" value={this.state.exchange_ratio} onChange={this.handleChange} required min="1" />
                                                <span>Ratio</span>
                                            </label>
                                        </div>
                                        <div className="form-group">
                                            <h4> = </h4>
                                        </div>
                                        <div className="form-group ms-5">
                                            <label style={{ width: "50%" }}>
                                                <input id="credit_amount" type="number" name="credit_amount" disabled value={this.state.credit_amount} />
                                                <span>Sample Credit</span>
                                            </label>
                                        </div>

                                    </div>
                                    <div>
                                        <div className="form-group ms-5">
                                            <label style={{ width: "30%" }}>
                                                <input id="type" name="type" value={this.state.type} onChange={this.handleCol} required />
                                                <span>Type</span>
                                            </label>
                                        </div>
                                        <div className="form-group ms-5">
                                            <label style={{ width: "30%" }}>
                                                <input id="category" name="category" value={this.state.category} disabled onChange={this.handleString} required />
                                                <span>Category</span>
                                            </label>
                                        </div>
                                        <br />
                                        <div className="form-group ms-5">
                                            <select class="form-select"
                                                aria-label="Default select example"
                                                // value={this.state.terminalCode}
                                                onChange={this.handleTerminal}
                                                style={{ width: "50%" }}
                                            >
                                                {this.state.terminalData.map(option => (
                                                    <option
                                                        key={option.id} >
                                                        {
                                                            option.name + " - " + option.code
                                                        }
                                                    </option>
                                                ))}
                                            </select>
                                            <label style={{ width: "30%" }}>
                                                <span>Terminal</span>
                                            </label>
                                        </div>
                                        <br />
                                    </div>
                                    <div>
                                        <h5>Item Credit Calculation</h5>
                                        <p>Item Credit will based on that category price multiply by credit ratio</p>
                                    </div>
                                    <div>
                                        <p>{this.state.category} item: {this.state.rm_amount * (this.state.exchange_ratio)} CREDITS</p>
                                    </div>
                                    <br />
                                    <br />
                                    <div>
                                        {
                                            this.state.loading
                                                ? (
                                                    <div className="text-center">
                                                        <div className={`spinner-border text-${process.env.REACT_APP_STORETYPE === "cuci" ? "warning" : "primary"}`} role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                    </div>
                                                )
                                                : <input type="submit" value="SUBMIT" className="btn btn-block btn-warning big-button" />
                                        }
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
};

const mapStateToProps = state => {
    return {
        admin: state.admin
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateCredit: data => dispatch(updateCredit(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCredit);