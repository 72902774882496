import React, { Component } from 'react';
import { connect } from 'react-redux';
import Topbar from './components/Topbar';
import Sidebar from "./components/Sidebar";
import Loading from '../../admin/components/Loading';
import SettingForm from '../../admin/components/SettingForm';
import axios from 'axios';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const mySwal = withReactContent(Swal);

class VoucherSetting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggled: false,
            loading: true,
            type: "Register",
            washerSetting: null,
            dryerSetting: null,
            combinedSetting: null,
            combined: false,
            specificStamp: "",
            stampTransactionAmount: 0,
            stamp: false,
            data: null,
            payAndGetRegVoucher: false,
            regVoucherAmount: 1,
            payAndGetBirthdayVoucher: false,
            birthdayVoucherAmount: 1
        };
    };

    componentDidMount() {
        if (!this.props.distributor || !this.props.distributor.voucher_setting) {
            return this.props.history.push('/distributor');
        } else {
            axios.post('/api/distributor/setting/getVoucherSettings', { distributorId: this.props.distributor.distributorId }, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
                .then(res => {
                    if (this.state.type === "Stamp") {
                        const washerVoucherSetting = res.data.setting.filter(setting => setting.type === "Washer Stamp")[0]
                        const dryerVoucherSetting = res.data.setting.filter(setting => setting.type === "Dryer Stamp")[0]
                        const combinedVoucherSetting = res.data.setting.filter(setting => setting.type === "Combined Stamp")[0]
                        this.setState({
                            ...this.state,
                            washerSetting: washerVoucherSetting,
                            dryerSetting: dryerVoucherSetting,
                            combinedSetting: combinedVoucherSetting,
                            loading: false,
                            combined: res.data.combined,
                            specificStamp: res.data.specificStamp,
                            stampTransactionAmount: res.data.stampTransactionAmount,
                            stamp: res.data.stamp,
                            data: res.data,
                            payAndGetRegVoucher: res.data.payAndGetRegVoucher,
                            regVoucherAmount: res.data.regVoucherAmount,
                            payAndGetBirthdayVoucher: res.data.payAndGetBirthdayVoucher,
                            birthdayVoucherAmount: res.data.birthdayVoucherAmount
                        });
                    } else {
                        const voucherSetting = res.data.setting.filter(setting => setting.type === this.state.type)[0]
                        this.setState({
                            ...this.state,
                            washerSetting: null,
                            dryerSetting: null,
                            combinedSetting: voucherSetting,
                            loading: false,
                            combined: res.data.combined,
                            specificStamp: res.data.specificStamp,
                            stampTransactionAmount: res.data.stampTransactionAmount,
                            stamp: res.data.stamp,
                            data: res.data,
                            payAndGetRegVoucher: res.data.payAndGetRegVoucher,
                            regVoucherAmount: res.data.regVoucherAmount,
                            payAndGetBirthdayVoucher: res.data.payAndGetBirthdayVoucher,
                            birthdayVoucherAmount: res.data.birthdayVoucherAmount
                        });
                    }
                })
                .catch(err => {
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                    mySwal.fire("Error", err.response.data.error, "error");
                });
        }
    };

    handleToggle = () => this.setState({ ...this.state, toggled: !this.state.toggled });

    handleSelect = val => {
        if (this.state.type !== val) {
            if (val === "Stamp") {
                const washerVoucherSetting = this.state.data.setting.filter(setting => setting.type === "Washer Stamp")[0]
                const dryerVoucherSetting = this.state.data.setting.filter(setting => setting.type === "Dryer Stamp")[0]
                const combinedVoucherSetting = this.state.data.setting.filter(setting => setting.type === "Combined Stamp")[0]
                this.setState({
                    ...this.state,
                    type: val,
                    washerSetting: washerVoucherSetting,
                    dryerSetting: dryerVoucherSetting,
                    combinedSetting: combinedVoucherSetting,
                    loading: false,
                    combined: this.state.data.combined,
                    specificStamp: this.state.data.specificStamp,
                    stampTransactionAmount: this.state.data.stampTransactionAmount,
                    stamp: this.state.data.stamp,
                    payAndGetRegVoucher: this.state.data.payAndGetRegVoucher,
                    regVoucherAmount: this.state.data.regVoucherAmount,
                    payAndGetBirthdayVoucher: this.state.data.payAndGetBirthdayVoucher,
                    birthdayVoucherAmount: this.state.data.birthdayVoucherAmount,
                });
            } else {
                const voucherSetting = this.state.data.setting.filter(setting => setting.type === val)[0]
                this.setState({
                    ...this.state,
                    type: val,
                    washerSetting: null,
                    dryerSetting: null,
                    combinedSetting: voucherSetting,
                    loading: false,
                    combined: this.state.data.combined,
                    specificStamp: this.state.data.specificStamp,
                    stampTransactionAmount: this.state.data.stampTransactionAmount,
                    stamp: this.state.data.stamp,
                    payAndGetRegVoucher: this.state.data.payAndGetRegVoucher,
                    regVoucherAmount: this.state.data.regVoucherAmount,
                    payAndGetBirthdayVoucher: this.state.data.payAndGetBirthdayVoucher,
                    birthdayVoucherAmount: this.state.data.birthdayVoucherAmount,
                });
            }
        }
    };

    handleCheckbox = (field, target) => {

        this.setState({
            ...this.state,
            [field]: {
                ...this.state[field],
                [target.id]: !this.state[field][target.id]
            }
        });
    };

    handleChange = (field, target) => {

        this.setState({
            ...this.state,
            [field]: {
                ...this.state[field],
                [target.id]: target.value
            }
        });
    };

    handleCombined = e => this.setState({
        ...this.state,
        combined: !this.state.combined
    });

    handlePayAndGetRegVoucher = e => this.setState({
        ...this.state,
        payAndGetRegVoucher: !this.state.payAndGetRegVoucher
    });

    handlePayAndGetBirthdayVoucher = e => this.setState({
        ...this.state,
        payAndGetBirthdayVoucher: !this.state.payAndGetBirthdayVoucher
    });

    submitCombined = e => {
        e.preventDefault();
        if (this.state.data.combined !== this.state.combined || this.state.data.specificStamp !== this.state.specificStamp || this.state.data.stampTransactionAmount !== this.state.stampTransactionAmount) {
            mySwal.fire({
                title: "Confirmation",
                text: "Are you sure you want to change how the stamping mechanics works?",
                icon: "question",
                showCancelButton: true,
                cancelButtonText: "No",
                confirmButtonText: "Yes",
                cancelButtonColor: "#d33"
            })
                .then(click => {
                    if (click.isConfirmed) {
                        this.setState({
                            ...this.state,
                            loading: true
                        });

                        const sendThis = {
                            id: this.props.distributor.distributorId,
                            role: "distributor",
                            combined: this.state.combined,
                            specificStamp: this.state.specificStamp,
                            stampTransactionAmount: this.state.stampTransactionAmount
                        };

                        axios.post('/api/admin/setting/setCombined', sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
                            .then(res => {

                                this.setState({
                                    ...this.state,
                                    loading: false,
                                    combined: res.data.combined,
                                    specificStamp: res.data.specificStamp,
                                    stampTransactionAmount: res.data.stampTransactionAmount,
                                    data: {
                                        ...this.state.data,
                                        combined: res.data.combined,
                                        specificStamp: res.data.specificStamp,
                                        stampTransactionAmount: res.data.stampTransactionAmount,
                                    }
                                });
                                mySwal.fire("Success", "The stamping mechanics setting has updated successfully!", "success");
                            })
                            .catch(err => {
                                this.setState({
                                    ...this.state,
                                    loading: false
                                });
                                mySwal.fire("Error", err.response.data.error, "error");
                            });
                    }
                })
        }
    };

    handleSpecific = e => this.setState({
        ...this.state,
        specificStamp: e.target.value
    });

    handleStampTransactionAmount = e => this.setState({
        ...this.state,
        stampTransactionAmount: e.target.value
    });

    handleRegVoucherAmount = e => this.setState({
        ...this.state,
        regVoucherAmount: e.target.value
    });

    handleBirthdayVoucherAmount = e => this.setState({
        ...this.state,
        birthdayVoucherAmount: e.target.value
    });

    handleSubmit = e => {
        e.preventDefault();

        mySwal.fire({
            title: "Confirmation",
            text: "Are you sure you want to change the voucher setting?",
            icon: "question",
            showCancelButton: true,
            cancelButtonText: "No",
            confirmButtonText: "Yes",
            cancelButtonColor: "#d33"
        })
            .then(click => {
                if (click.isConfirmed) {
                    const { type, washerSetting, dryerSetting, combinedSetting, payAndGetRegVoucher, regVoucherAmount, payAndGetBirthdayVoucher, birthdayVoucherAmount } = this.state;

                    const sendThis = {
                        id: this.props.distributor.distributorId,
                        role: "distributor",
                        type,
                        washerSetting,
                        dryerSetting,
                        combinedSetting,
                        payAndGetRegVoucher,
                        regVoucherAmount,
                        payAndGetBirthdayVoucher,
                        birthdayVoucherAmount
                    };

                    this.setState({
                        ...this.state,
                        loading: true
                    });

                    axios.post('/api/admin/setting/update', sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
                        .then(res => {
                            this.setState({
                                ...this.state,
                                loading: false,
                                data: {
                                    ...this.state.data,
                                    setting: res.data.data,
                                    payAndGetRegVoucher: this.state.type === "Register" ? payAndGetRegVoucher : this.state.data.payAndGetRegVoucher,
                                    regVoucherAmount: this.state.type === "Register" ? regVoucherAmount : this.state.data.regVoucherAmount,
                                    payAndGetBirthdayVoucher: this.state.selected === "Birthday" ? payAndGetBirthdayVoucher : this.state.data.payAndGetBirthdayVoucher,
                                    birthdayVoucherAmount: this.state.selected === "Birthday" ? birthdayVoucherAmount : this.state.data.birthdayVoucherAmount
                                }
                            })

                            mySwal.fire({
                                title: "Success",
                                text: "The voucher setting has been updated successfully.",
                                icon: "success",
                                confirmButtonColor: "#ffd109"
                            });
                        })
                        .catch(err => {
                            mySwal.fire("Error", err.response.data.error, "error");
                            this.setState({
                                ...this.state,
                                loading: false
                            });
                        });
                }
            })
    };


    render() {
        return (
            <div className="admin-page-container">
                <Sidebar toggled={this.state.toggled} setToggled={this.handleToggle} />

                <div className="admin-page">
                    <Topbar handleToggle={this.handleToggle} />

                    <div className="admin-content">

                        {
                            this.state.data && !this.state.loading ? (
                                <div>
                                    {
                                        this.state.stamp ? (
                                            <div className="card">
                                                <div className="card-header">
                                                    <h5 className="card-title">Mechanics Setting</h5>
                                                </div>

                                                <div className="card-body">
                                                    <form onSubmit={this.submitCombined}>

                                                        <div className="form-group">
                                                            <label htmlFor="stampTransactionAmount">Minimum transaction amount for getting stamp : (RM)</label>
                                                            <input type="number" id="stampTransactionAmount" name="stampTransactionAmount" value={this.state.stampTransactionAmount} onChange={this.handleStampTransactionAmount} min="0" step="any" required />
                                                        </div>

                                                        <div className="form-group">
                                                            <p>Select User Stamping Mechanic</p>

                                                            <p>
                                                                <label htmlFor="radio1">
                                                                    <input type="radio" name="combined" id="radio1" className="with-gap" checked={this.state.combined === false} onChange={this.handleCombined} />
                                                                    <span>Separated Stamping</span>
                                                                </label>
                                                            </p>

                                                            <p>
                                                                <label htmlFor="radio2">
                                                                    <input type="radio" name="combined" id="radio2" className="with-gap" checked={this.state.combined === true} onChange={this.handleCombined} />
                                                                    <span>Combined Stamping</span>
                                                                </label>
                                                            </p>
                                                        </div>

                                                        {
                                                            !this.state.combined
                                                                ? (
                                                                    <div>
                                                                        <p>Stamp for?</p>
                                                                        <div className="d-flex">
                                                                            <div className="form-group">
                                                                                <label htmlFor="both">
                                                                                    <input type="checkbox" id="both" name="specificStamp" checked={this.state.specificStamp === "both"} value="both" onChange={this.handleSpecific} />
                                                                                    <span>Both</span>
                                                                                </label>
                                                                            </div>

                                                                            <div className="form-group ms-5">
                                                                                <label htmlFor="washerOnly">
                                                                                    <input type="checkbox" id="washerOnly" name="specificStamp" checked={this.state.specificStamp === "washerOnly"} value="washerOnly" onChange={this.handleSpecific} />
                                                                                    <span>Washer Only</span>
                                                                                </label>
                                                                            </div>

                                                                            <div className="form-group ms-5">
                                                                                <label htmlFor="dryerOnly">
                                                                                    <input type="checkbox" id="dryerOnly" name="specificStamp" checked={this.state.specificStamp === "dryerOnly"} value="dryerOnly" onChange={this.handleSpecific} />
                                                                                    <span>Dryer Only</span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                                : null
                                                        }

                                                        <div>
                                                            {
                                                                this.state.loading
                                                                    ? (
                                                                        <div className="text-center">
                                                                            <div className={`spinner-border text-${process.env.REACT_APP_STORETYPE === "cuci" ? "warning" : "primary"}`} role="status">
                                                                                <span className="sr-only">Loading...</span>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                    : <input type="submit" value="SUBMIT" className="btn btn-block btn-warning big-button" disabled={this.state.data.combined === this.state.combined && this.state.data.specificStamp === this.state.specificStamp && this.state.data.stampTransactionAmount === this.state.stampTransactionAmount} />
                                                            }
                                                        </div>
                                                    </form>
                                                    <div className="mt-5">
                                                        <p><small>* Separated Stamping = Different washer and dryer stamp collection. (example: Using Washer will gain 1 washer's stamp, and Dryer will gain 1 dryer stamp)</small></p>
                                                        <p><small>* Combined Stamping = Washer and dryer has the same collection. (example: Using Washer or Dryer will gain 1 stamp)</small></p>
                                                    </div>
                                                    {
                                                        !this.state.combined
                                                            ? (
                                                                <div>
                                                                    <p><small>Stamp for : <strong>{this.state.specificStamp === "both" ? "Both" : this.state.specificStamp === "washerOnly" ? "Washer Only" : "Dryer Only"}</strong></small></p>
                                                                    <p><small>* {this.state.specificStamp === "both" ? "User will be able to collect both washer stamp and dryer stamp" : `User will only able to collect ${this.state.specificStamp === "washerOnly" ? "Washer" : "Dryer"} stamp`}</small></p>
                                                                </div>
                                                            )
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                        ) : null
                                    }

                                    <div className="card">
                                        <div className="card-header">
                                            <h5 className="card-title">Voucher Settings</h5>
                                        </div>

                                        <div className="card-body">
                                            <div>
                                                <ul className="nav nav-tabs m-auto">
                                                    <li className="nav-item">
                                                        <button onClick={e => this.handleSelect("Register")} className={this.state.type === "Register" ? "nav-link active font-weight-bold" : "nav-link"} style={{ backgroundColor: "white", outline: "none", borderBottom: this.state.type === "Register" ? null : "1px solid #dee2e6" }}>
                                                            Registration
                                                        </button>
                                                    </li>

                                                    {
                                                        this.state.stamp ? (
                                                            <li className="nav-item">
                                                                <button onClick={e => this.handleSelect("Stamp")} className={this.state.type === "Stamp" ? "nav-link active font-weight-bold" : "nav-link"} style={{ backgroundColor: "white", outline: "none", borderBottom: this.state.type === "Stamp" ? null : "1px solid #dee2e6" }}>
                                                                    Stamp
                                                                </button>
                                                            </li>
                                                        ) : null
                                                    }

                                                    <li className="nav-item">
                                                        <button onClick={e => this.handleSelect("Birthday")} className={this.state.type === "Birthday" ? "nav-link active font-weight-bold" : "nav-link"} style={{ backgroundColor: "white", outline: "none", borderBottom: this.state.type === "Birthday" ? null : "1px solid #dee2e6" }}>
                                                            Birthday
                                                        </button>
                                                    </li>
                                                </ul>

                                                <div className="mt-5">
                                                    {
                                                        this.state.loading
                                                            ? <Loading />
                                                            : <SettingForm
                                                                selected={this.state.type}
                                                                washerSetting={this.state.washerSetting}
                                                                dryerSetting={this.state.dryerSetting}
                                                                combinedSetting={this.state.combinedSetting}
                                                                handleCheckbox={this.handleCheckbox}
                                                                handleChange={this.handleChange}
                                                                handleSubmit={this.handleSubmit}
                                                                combined={this.state.data.combined}
                                                                payAndGetRegVoucher={this.state.payAndGetRegVoucher}
                                                                regVoucherAmount={this.state.regVoucherAmount}
                                                                handlePayAndGetRegVoucher={this.handlePayAndGetRegVoucher}
                                                                handleRegVoucherAmount={this.handleRegVoucherAmount}
                                                                payAndGetBirthdayVoucher={this.state.payAndGetBirthdayVoucher}
                                                                birthdayVoucherAmount={this.state.birthdayVoucherAmount}
                                                                handlePayAndGetBirthdayVoucher={this.handlePayAndGetBirthdayVoucher}
                                                                handleBirthdayVoucherAmount={this.handleBirthdayVoucherAmount}
                                                            />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : this.state.loading ? (
                                <Loading />
                            ) : null
                        }
                    </div>
                </div>
            </div>
        );
    };
};

const mapStateToProps = state => {
    return {
        distributor: state.distributor.distributor,
        token: state.distributor.token
    };
};

export default connect(mapStateToProps, null)(VoucherSetting);