import React, { Component } from "react";
import { connect } from "react-redux";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import Loading from "../components/Loading";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { enableReferral, updateReferral, updateVoucher } from "../../../redux/actions/adminActions";
import { Select } from 'antd';

const mySwal = withReactContent(Swal);
const { Option } = Select;
const options = []

const colorSets = [
    {
        headColor: "#c596c4",
        bodyColor: "linear-gradient(135deg , #c8bfde , #a1c3e9)"
    },
    {
        headColor: "#fbcc7d",
        bodyColor: "linear-gradient(135deg , #f6b997 , #f38695)"
    },
    {
        headColor: "#93c9ed",
        bodyColor: "linear-gradient(135deg , #6599d3 , #77cebb)"
    },
    {
        headColor: "#c7a1aa",
        bodyColor: "linear-gradient(135deg , #f2d0e0 , #c6a9d2)"
    },
    {
        headColor: "#b6da98",
        bodyColor: "linear-gradient(135deg , #cee6a9 , #dbde91)"
    }
];

const option = [
    {
        value: 'jack',
        label: 'Jack',
    },
    {
        value: 'lucy',
        label: 'Lucy',
    },
    {
        value: 'tom',
        label: 'Tom',
    },
]

const randomNumber = Math.floor(Math.random() * colorSets.length);
const selectedColor = colorSets[randomNumber];

class ReferralSetting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggled: false,
            referral: false,
            voucher_eligible: false,
            voucher_amount: 0,
            voucher_type: "",
            voucher_expiry: true,
            voucher_duration: "",
            voucher_title: "",
            voucher_description: "",
            // voucher_washer_use: false,
            // voucher_dryer_use: false,
            // token_eligible: false,
            // token_amount: 0,
            // stamp_eligible: false,
            // stamp_combined: 0,
            // stamp_washer: 0,
            // stamp_dryer: 0,
            point_eligible: true,
            point_amount: 0,
            number_of_referral: 0,
            amount_transaction: 0,
            loading: false,
            getAdminId: null,
            getAdminUsername: "",
            adminData: []
        };
    }

    componentDidMount() {
        if (!this.props.admin.isAuthenticated) {
            return this.props.history.push("/admin/dashboard");
        }
        else {
            const voucherSetting = this.props.admin.admin.setting.filter(setting => setting.type === "Referral")[0]
            this.setState({
                ...this.state,
                referral: this.props.admin.admin.referral,
                voucher_eligible: voucherSetting.voucher_eligible,
                voucher_amount: voucherSetting.voucher_amount,
                voucher_type: voucherSetting.voucher_type,
                voucher_expiry: voucherSetting.voucher_expiry,
                voucher_duration: voucherSetting.voucher_duration,
                voucher_title: voucherSetting.voucher_title,
                voucher_description: voucherSetting.voucher_description,
                point_eligible: this.props.admin.admin.referral.point_eligible,
                point_amount: this.props.admin.admin.referral.point_amount,
                number_of_referral: this.props.admin.admin.referral.number_of_referral,
                amount_transaction: this.props.admin.admin.referral.amount_transaction
            });

            const operatorId = this.props.admin.admin.operatorId;
            const type = "Referral Setting"

            axios
                .post("/api/admin/setting/getAllAdminTypePermission", { operatorId, type }, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
                .then(res => {
                    this.setState({ adminData: res.data.data })
                    // console.log(this.state.adminData)
                })
                .catch(err => mySwal.fire("Error", err.response.data.error, "error"));
        }
    }

    handleToggle = () => this.setState({ ...this.state, toggled: !this.state.toggled });

    getAdminInfo = (value, label) => {
        // console.log(`selected ${value} &  ${label}`);
        this.setState({
            ...this.state,
            getAdminId: value,
            // getAdminUsername: label
        })
    };

    handleSettingSubmit = e => {
        e.preventDefault();
        mySwal.fire({
            title: "Confirmation",
            text: "Are you sure you want to change the admin referral priority?",
            icon: "question",
            showCancelButton: true,
            cancelButtonText: "No",
            confirmButtonText: "Yes",
            cancelButtonColor: "#d33"
        })
            .then(click => {
                if (this.state.getAdminId) {
                    if (click.isConfirmed && click.value) {
                        this.setState({
                            ...this.state,
                            loading: true
                        });

                        const adminIdChange = this.state.getAdminId;
                        // console.log(adminIdChange)
                        const type = "Referral Setting"
                        axios
                            .post("/api/admin/setting/updateAdminTypePriority", { adminIdChange, type }, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
                            .then(res => {
                                this.setState({
                                    ...this.state,
                                    loading: false
                                });
                                mySwal.fire("Success", "The admin referral priority successfully!", "success");
                            })
                            .catch(err => mySwal.fire("Error", err.response.data.error, "error"));
                    }
                }
                else {
                    mySwal.fire("Error", "Please select the admin ID", "error");
                }

            })
    }

    handleEnable = e => {
        axios
            .post("/api/admin/setting/enableReferral", { id: this.props.admin.admin.operatorId, role: "operator", data: !this.state.referral }, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
            .then(res => {
                this.setState({
                    ...this.state,
                    referral: res.data.data
                });
                this.props.enableReferral(res.data.data);
            })
            .catch(err => mySwal.fire("Error", err.response.data.error, "error"));
    };

    handleCheckbox = e => {
        this.setState({
            ...this.state,
            [e.target.id]: !this.state[e.target.id]
        });
    };

    handleChange = e => {
        this.setState({
            ...this.state,
            [e.target.id]: e.target.value
        });
    };

    handleSubmit = e => {
        e.preventDefault();

        const sendThis = {
            id: this.props.admin.admin.operatorId,
            role: "operator",
            voucher_eligible: this.state.voucher_eligible,
            voucher_amount: this.state.voucher_amount,
            voucher_type: this.state.voucher_type,
            voucher_expiry: this.state.voucher_expiry,
            voucher_duration: this.state.voucher_duration,
            voucher_title: this.state.voucher_title,
            voucher_description: this.state.voucher_description,
            point_eligible: this.state.point_eligible,
            point_amount: this.state.point_amount,
            number_of_referral: this.state.number_of_referral,
            amount_transaction: this.state.amount_transaction
        };

        this.setState({
            ...this.state,
            loading: true
        });

        axios.post('/api/admin/setting/updateReferral', sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
            .then(res => {
                this.setState({
                    ...this.state,
                    loading: false,
                })

                this.props.updateReferral(sendThis);
                this.props.updateVoucher(res.data.data);

                mySwal.fire({
                    title: "Success",
                    text: "Your referral setting has been updated successfully.",
                    icon: "success",
                    confirmButtonColor: "#ffd109"
                });
            })
            .catch(err => {
                mySwal.fire("Error", err.response.data.error, "error");
                this.setState({
                    ...this.state,
                    loading: false
                });
            });
    };

    render() {
        return (
            <div id="admin-dashboard" className="admin-page-container">
                <Sidebar toggled={this.state.toggled} setToggled={this.handleToggle} />
                <div className="admin-page">
                    <Topbar handleToggle={this.handleToggle} />
                    <div className="admin-content">
                        {/* <div className="mt-4 mb-5">
                            <div className="form-group">
                                <label>
                                    <input type="checkbox" id="enable" name="enable" checked={this.state.referral} onChange={this.handleEnable} />
                                    <span>Enable Referral Feature?</span>
                                </label>
                            </div>
                        </div> */}
                        <div className="card">
                            <div className="card-header">
                                <h5 className="card-title">Referral Admin Settings</h5>
                            </div>


                            <div className="card-body">
                                <p>Upcoming user referral will follow the <strong>choosen latest admin</strong> voucher setting </p>
                                <Select
                                    showSearch
                                    placeholder="Select an admin"
                                    style={{ width: "50%" }}
                                    value={this.state.getAdminId}
                                    onChange={this.getAdminInfo}
                                // options={options}
                                >
                                    {this.state.adminData.map(item => (
                                        <Option key={item.id} value={item.id} >{item.username}</Option>
                                    ))}

                                </Select>
                                <br />
                                <br />
                                <button className="btn btn-primary" type="button" onClick={this.handleSettingSubmit}>
                                    SUBMIT
                                </button>

                            </div>

                        </div>
                        <br />

                        {this.props.admin.admin && this.props.admin.admin.referral ? (
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title">Referral Configuration</h5>
                                </div>

                                <form onSubmit={this.handleSubmit}>
                                    <div id="dashboard-content" className="card-body">
                                        <div className="container">
                                            <div className="form-group">
                                                <label htmlFor="number_of_referral">Maximum number of referrals for each user : </label>
                                                <input type="number" id="number_of_referral" name="number_of_referral" value={this.state.number_of_referral} required onChange={this.handleChange} min="0" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="amount_transaction">Minimum amount of transaction : <small> (RM)</small></label>
                                                <input type="number" id="amount_transaction" name="amount_transaction" value={this.state.amount_transaction} required onChange={this.handleChange} min="0" />
                                            </div>
                                        </div>
                                        <div className="card" style={{ width: "initial" }}>
                                            <div className="card-header">
                                                <h6 className="m-0 p-0">Voucher</h6>
                                            </div>

                                            <div className="card-body">
                                                <div>
                                                    <div>
                                                        {/* stamp voucher preview */}
                                                        <div className="voucher mb-5">
                                                            <div className="voucher-ball left-ball"></div>
                                                            <div className="voucher-head p-0 m-0" style={{ backgroundColor: selectedColor.headColor }}>
                                                                <h4 style={{ width: "140px", height: "140px", padding: "18px 0 0 0" }}>DISCOUNT</h4>
                                                            </div>

                                                            <div className="voucher-content" style={{ background: selectedColor.bodyColor }}>
                                                                <div>
                                                                    <h1>{this.state.voucher_type === "Flat" ? (`RM${this.state.voucher_amount}`) : (`${this.state.voucher_amount}%`)}</h1>
                                                                </div>

                                                                <div>
                                                                    <h5>{this.state.voucher_title}</h5>
                                                                    <h6>{this.state.voucher_description}</h6>
                                                                </div>
                                                            </div>
                                                            <div className="voucher-ball right-ball"></div>
                                                        </div>
                                                    </div>

                                                    <div className="text-center">
                                                        {/* checkboxes */}
                                                        <div style={{ maxWidth: "400px", margin: "auto", display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                                                            <div className="form-group">
                                                                <label htmlFor="voucher_eligible">
                                                                    <input type="checkbox" id="voucher_eligible" name="voucher_eligible" checked={this.state.voucher_eligible} onChange={this.handleCheckbox} />
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={
                                                                            <Tooltip>
                                                                                If ticked, the system will give voucher to user
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <span>Eligibility</span>
                                                                    </OverlayTrigger>
                                                                </label>
                                                            </div>

                                                            <div className="form-group">
                                                                <label htmlFor="voucher_expiry">
                                                                    <input type="checkbox" id="voucher_expiry" name="voucher_expiry" checked={this.state.voucher_expiry} onChange={this.handleCheckbox} />
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={
                                                                            <Tooltip>
                                                                                If ticked, the voucher will have expired date
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <span>Expiry</span>
                                                                    </OverlayTrigger>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {/* text and select */}
                                                        {
                                                            this.state.voucher_expiry
                                                                ? (
                                                                    <div className="form-group">
                                                                        <label htmlFor="voucher_duration">Voucher Lifespan : <small> (days)</small></label>
                                                                        <input type="number" id="voucher_duration" name="voucher_duration" value={this.state.voucher_duration} onChange={this.handleChange} min="0" required />
                                                                    </div>
                                                                )
                                                                : (
                                                                    null
                                                                )
                                                        }

                                                        <div className="form-group">
                                                            <label htmlFor="voucher_type">Discount Type : </label>
                                                            <select className="browser-default form-select" id="voucher_type" name="voucher_type" value={this.state.voucher_type} onChange={this.handleChange} required>
                                                                <option value="" disabled>Select a discount type</option>
                                                                <option value="Flat">Flat (- RM)</option>
                                                                <option value="Rate">Rate (- %)</option>
                                                            </select>
                                                        </div>

                                                        <div className="form-group">
                                                            <label htmlFor="voucher_title">Voucher Title : </label>
                                                            <input type="text" id="voucher_title" name="voucher_title" placeholder="Voucher Title" value={this.state.voucher_title} onChange={this.handleChange} required />
                                                        </div>

                                                        <div className="form-group">
                                                            <label htmlFor="voucher_description">Voucher Description : </label>
                                                            <input type="text" id="voucher_description" name="voucher_description" placeholder="Voucher Description" value={this.state.voucher_description} onChange={this.handleChange} required />
                                                        </div>

                                                        <div className="form-group">
                                                            <label htmlFor="voucher_amount">Voucher Amount : <small>{this.state.voucher_type === "Flat" ? "(RM)" : "(%)"}</small></label>
                                                            <input type="number" id="voucher_amount" name="voucher_amount" value={this.state.voucher_amount} onChange={this.handleChange} min="0" required />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* {this.props.admin.admin.token ? (
                                            <div className="card">
                                                <div className="card-header">
                                                    <h6 className="m-0 p-0">Point</h6>
                                                </div>

                                                <div className="card-body">
                                                    <div className="form-group">
                                                        <label htmlFor="token_eligible">
                                                            <input type="checkbox" id="token_eligible" name="token_eligible" checked={this.state.token_eligible} onChange={this.handleCheckbox} />
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip>
                                                                        If ticked, the system will give e-Token to user
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span>Eligibility</span>
                                                            </OverlayTrigger>
                                                        </label>
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="token_amount">Number of E-Token : </label>
                                                        <input type="number" id="token_amount" name="token_amount" value={this.state.token_amount} onChange={this.handleChange} min="0" required />
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null
                                        } */}

                                    </div>
                                    {
                                        this.state.loading ? <Loading /> : (
                                            <div className="mb-5 mx-5">
                                                <input type="submit" value="SUBMIT" className="btn big-button form-control" />
                                            </div>
                                        )
                                    }
                                </form>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        admin: state.admin
    };
};

const mapDispatchToProps = dispatch => {
    return {
        enableReferral: data => dispatch(enableReferral(data)),
        updateReferral: data => dispatch(updateReferral(data)),
        updateVoucher: data => dispatch(updateVoucher(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReferralSetting);
