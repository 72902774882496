import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { MDBDataTable } from "mdbreact";
import { Modal, Tooltip, OverlayTrigger } from "react-bootstrap";
import axios from "axios";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import Sidebar from './components/Sidebar';
import Topbar from './components/Topbar';
import Loading from "../../admin/components/Loading";

const mySwal = withReactContent(Swal);

const DiscountCode = ({ token, client }) => {

    const history = useHistory();

    const [toggled, setToggled] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [code, setCode] = useState("");
    const [start_date, setStartDate] = useState("");
    const [end_date, setEndDate] = useState("");
    const [discount_amount, setDiscountAmount] = useState("");
    const [discount_type, setDiscountType] = useState("");
    const [washer_use, setWasherUse] = useState(false);
    const [dryer_use, setDryerUse] = useState(false);
    const [redeem_per_user, setRedeemPerUser] = useState("");
    const [redeem_per_day, setRedeemPerDay] = useState("");
    const [campaignId, setCampaignId] = useState("");
    const [distributorId, setDistributorId] = useState("");

    useEffect(() => {
        if (!client || !client.discount_code) {
            history.push('/client')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleToggle = () => {
        setToggled(!toggled);
    }

    const handleSelectDistributor = (e) => {
        setDistributorId(e.target.value);
        setLoading(true);

        axios
            .post("/api/admin/setting/getCampaign", { id: e.target.value, role: "distributor" }, { headers: { "Content-Type": "application/json", "auth-token": token } })
            .then(res => {
                setData(res.data);
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
                mySwal.fire("Error", err.response.data.error, "error")
            });
    }

    const handleDate = dates => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    const hideModal = () => {
        setCode("");
        setStartDate("");
        setEndDate("");
        setDiscountAmount("");
        setDiscountType("");
        setWasherUse(false);
        setDryerUse(false);
        setRedeemPerUser("");
        setRedeemPerDay("");
        setCampaignId("");
        setShowModal(false);
    }

    const addCampaignCode = () => {
        setCode("");
        setStartDate("");
        setEndDate("");
        setDiscountAmount("");
        setDiscountType("");
        setWasherUse(false);
        setDryerUse(false);
        setRedeemPerUser("");
        setRedeemPerDay("");
        setCampaignId("");
        setShowModal(true);
    }

    const editCampaignCode = (campaignId) => {
        const selectedCampaign = data.filter(data => data.id === campaignId)[0]
        setCode(selectedCampaign.code);
        setStartDate(new Date(selectedCampaign.start_date));
        setEndDate(new Date(selectedCampaign.end_date));
        setDiscountAmount(selectedCampaign.discount_amount);
        setDiscountType(selectedCampaign.discount_type);
        setWasherUse(selectedCampaign.washer_use);
        setDryerUse(selectedCampaign.dryer_use);
        setRedeemPerUser(selectedCampaign.redeem_per_user);
        setRedeemPerDay(selectedCampaign.redeem_per_day);
        setCampaignId(selectedCampaign.id);
        setShowModal(true);
    }

    const editCampaign = (e) => {
        e.preventDefault();

        if (!washer_use && !dryer_use) {
            mySwal.fire("Missing Field", "Please select can be used for washer or dryer", "info");
        } else if (!start_date && !end_date) {
            mySwal.fire("Missing Field", "Please fill in the start date and end date", "info");
        } else if (parseFloat(discount_amount) <= 0) {
            mySwal.fire("Missing Field", "Discount amount should be more than 0", "info");
        } else if (parseInt(redeem_per_user) <= 0) {
            mySwal.fire("Missing Field", "Redeem per user should be more than 0", "info");
        } else if (parseInt(redeem_per_day) <= 0) {
            mySwal.fire("Missing Field", "Redeem per day should be more than 0", "info");
        } else {
            setLoading(true);

            const sendThis = {
                id: campaignId,
                start_date,
                end_date,
                discount_amount,
                discount_type,
                washer_use,
                dryer_use,
                redeem_per_user,
                redeem_per_day
            }

            axios
                .post("/api/admin/setting/editCampaign", sendThis, { headers: { "Content-Type": "application/json", "auth-token": token } })
                .then(res => {

                    axios
                        .post("/api/admin/setting/getCampaign", { id: distributorId, role: "distributor" }, { headers: { "Content-Type": "application/json", "auth-token": token } })
                        .then(res => {
                            setData(res.data);
                            setShowModal(false);
                            setLoading(false);
                            mySwal.fire("Success", "Your discount code have edited successfully", "success")
                        })
                        .catch(err => {
                            setLoading(false);
                            mySwal.fire("Error", err.response.data.error, "error")
                        });
                })
                .catch(err => {
                    setLoading(false);
                    mySwal.fire("Error", err.response.data.error, "error")
                });
        }
    }

    const addCampaign = (e) => {
        e.preventDefault();

        if (!washer_use && !dryer_use) {
            mySwal.fire("Missing Field", "Please select can be used for washer or dryer", "info");
        } else if (!start_date && !end_date) {
            mySwal.fire("Missing Field", "Please fill in the start date and end date", "info");
        } else if (parseFloat(discount_amount) <= 0) {
            mySwal.fire("Missing Field", "Discount amount should be more than 0", "info");
        } else if (parseInt(redeem_per_user) <= 0) {
            mySwal.fire("Missing Field", "Redeem per user should be more than 0", "info");
        } else if (parseInt(redeem_per_day) <= 0) {
            mySwal.fire("Missing Field", "Redeem per day should be more than 0", "info");
        } else {
            setLoading(true);

            const sendThis = {
                id: distributorId,
                role: "distributor",
                code,
                start_date,
                end_date,
                discount_amount,
                discount_type,
                washer_use,
                dryer_use,
                redeem_per_user,
                redeem_per_day
            }

            axios
                .post("/api/admin/setting/createCampaign", sendThis, { headers: { "Content-Type": "application/json", "auth-token": token } })
                .then(res => {

                    axios
                        .post("/api/admin/setting/getCampaign", { id: distributorId, role: "distributor" }, { headers: { "Content-Type": "application/json", "auth-token": token } })
                        .then(res => {
                            setLoading(false);
                            setData(res.data);
                            setShowModal(false)
                            mySwal.fire("Success", "Your discount code have added successfully", "success")
                        })
                        .catch(err => {
                            setLoading(false);
                            mySwal.fire("Error", err.response.data.error, "error")
                        });
                })
                .catch(err => {
                    setLoading(false);
                    mySwal.fire("Error", err.response.data.error, "error")
                });
        }
    }

    const deleteCampaign = campaignId => {
        mySwal
            .fire({
                title: "Confirmation",
                text: "Delete this entry?",
                icon: "question",
                showCancelButton: true,
                cancelButtonText: "No",
                confirmButtonText: "Yes"
            })
            .then(click => {
                if (click.isConfirmed && click.value) {
                    setLoading(true);

                    axios
                        .post("/api/admin/setting/deleteCampaign", { id: campaignId }, { headers: { "Content-Type": "application/json", "auth-token": token } })
                        .then(res => {

                            axios
                                .post("/api/admin/setting/getCampaign", { id: distributorId, role: "distributor" }, { headers: { "Content-Type": "application/json", "auth-token": token } })
                                .then(res => {
                                    setData(res.data);
                                    setLoading(false);
                                    mySwal.fire("Success", "Your discount code have deleted successfully", "success")
                                })
                                .catch(err => {
                                    setLoading(false);
                                    mySwal.fire("Error", err.response.data.error, "error")
                                });
                        })
                        .catch(err => {
                            setLoading(false);
                            mySwal.fire("Error", err.response.data.error, "error")
                        });
                }
            });
    }

    let tableData = {
        columns: [
            {
                label: "Created Date",
                field: "createdAt",
                sort: "asc"
            },
            {
                label: "Discount code",
                field: "code",
                sort: "asc"
            },
            {
                label: "Start Date",
                field: "start_date",
                sort: "asc"
            },
            {
                label: "End Date",
                field: "end_date",
                sort: "asc"
            },
            {
                label: "Redeem Per User",
                field: "redeem_per_user",
                sort: "asc"
            },
            {
                label: "Redeem Per Day",
                field: "redeem_per_day",
                sort: "asc"
            },
            {
                label: "Discount Type",
                field: "discount_type",
                sort: "asc"
            },
            {
                label: "Discount Amount",
                field: "discount_amount",
                sort: "asc"
            },
            {
                label: "Washer Use",
                field: "washer_use",
                sort: "asc"
            },
            {
                label: "Dryer Use",
                field: "dryer_use",
                sort: "asc"
            },
            {
                label: "Action",
                field: "action"
            }
        ],
        rows: []
    };

    if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
            const dataObject = {
                createdAt: data[i].createdAt,
                code: data[i].code,
                start_date: data[i].start_date,
                end_date: data[i].end_date,
                redeem_per_user: data[i].redeem_per_user,
                redeem_per_day: data[i].redeem_per_day,
                discount_type: data[i].discount_type === "Flat" ? `${data[i].discount_type} (RM)` : `${data[i].discount_type} (%)`,
                discount_amount: data[i].discount_amount,
                washer_use: data[i].washer_use ? "Yes" : "No",
                dryer_use: data[i].dryer_use ? "Yes" : "No",
                action: (
                    <div>
                        <i className="fas fa-edit" onClick={() => editCampaignCode(data[i].id)}></i>
                        <i className="fas fa-trash-alt ms-3" onClick={() => deleteCampaign(data[i].id)}></i>
                    </div>
                )
            }
            tableData.rows.push(dataObject);
        }
    }

    return (
        <div className="admin-page-container">
            <Sidebar toggled={toggled} setToggled={handleToggle} />

            <div className="admin-page">
                <Topbar handleToggle={handleToggle} />

                <div className="admin-content">

                    <div className="text-center">
                        <h3>Discount Code</h3>
                    </div>
                    <div className="pb-2">
                        <label htmlFor="distributorId">Select Distributor: </label>
                        <select id="distributorId" name="distributorId" className="browser-default form-select" value={distributorId} onChange={e => handleSelectDistributor(e)}>
                            <option disabled value="">Select Distributor</option>
                            {
                                client.distributor.map(distributor => <option key={distributor.id} value={distributor.id}>{distributor.name}</option>)
                            }
                        </select>
                    </div>

                    {
                        distributorId ? (
                            <div className="card">
                                <div className="card-header d-flex justify-content-between">
                                    <h5 className="card-title">Discount Code</h5>

                                    <button className="btn btn-primary" type="button" onClick={e => addCampaignCode()}>
                                        Add Discount Code
                                    </button>
                                </div>

                                <div className="card-body">
                                    <div>
                                        {
                                            loading ? <Loading /> :
                                                data.length > 0 ? <MDBDataTable data={tableData} noBottomColumns striped hover responsive bordered /> :
                                                    (
                                                        <div className="text-center">
                                                            <h1> - No Discount Code - </h1>
                                                        </div>
                                                    )
                                        }
                                    </div>
                                </div>
                            </div>
                        ) : null
                    }
                </div>
            </div>
            <Modal
                show={showModal}
                onHide={e => hideModal()}
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{campaignId ? "Edit Discount Code" : "Add Discount Code"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <form onSubmit={campaignId ? editCampaign : addCampaign}>
                            {
                                campaignId ? (
                                    <div className="form-group text-left">
                                        <label htmlFor="code">Discount Code</label>
                                        <input type="text" id="code" name="code" value={code} className="form-control browser-default" required readOnly />
                                    </div>
                                ) : (
                                    <div className="form-group text-left">
                                        <label htmlFor="code">Discount Code</label>
                                        <input type="text" id="code" name="code" value={code} className="form-control browser-default" onChange={e => setCode(e.target.value.toUpperCase())} required />
                                    </div>
                                )
                            }

                            <div className="form-group text-left">
                                <label htmlFor="date">Select Period: </label>
                                <DatePicker value={`${start_date ? `${new Date(start_date).getDate()}/${new Date(start_date).getMonth() + 1}/${new Date(start_date).getFullYear()}` : ""} - ${end_date ? `${new Date(end_date).getDate()}/${new Date(end_date).getMonth() + 1}/${new Date(end_date).getFullYear()}` : ""}`} onChange={handleDate} startDate={start_date} endDate={end_date} selectsRange shouldCloseOnSelect={false} required />
                            </div>

                            <div className="form-group text-left">
                                <label htmlFor="redeem_per_user">Redeem per user <small>(How many times the discount code can be used for each user)</small> : </label>
                                <input type="number" className="form-control browser-default" id="redeem_per_user" name="redeem_per_user" value={redeem_per_user} onChange={e => setRedeemPerUser(e.target.value)} min="1" step="1" required />
                            </div>

                            <div className="form-group text-left">
                                <label htmlFor="redeem_per_day">Redeem per day <small>(How many times the discount code can be used in one day)</small> : </label>
                                <input type="number" className="form-control browser-default" id="redeem_per_day" name="redeem_per_day" value={redeem_per_day} onChange={e => setRedeemPerDay(e.target.value)} min="1" step="1" required />
                            </div>

                            <div className="form-group text-left">
                                <label htmlFor="discount_type">Discount Type : </label>
                                <select className="browser-default form-select" id="discount_type" name="discount_type" value={discount_type} onChange={e => setDiscountType(e.target.value)} required>
                                    <option value="" disabled>Select a discount type</option>
                                    <option value="Flat">Flat (- RM)</option>
                                    <option value="Rate">Rate (- %)</option>
                                </select>
                            </div>

                            <div className="form-group text-left">
                                <label htmlFor="discount_amount">Discount Amount : <small>{discount_type === "Flat" ? "(RM)" : discount_type === "Rate" ? "(%)" : ""}</small></label>
                                <input type="number" className="form-control browser-default" id="discount_amount" name="discount_amount" value={discount_amount} onChange={e => setDiscountAmount(e.target.value)} min="0" step="0.01" required />
                            </div>

                            <div className="text-center">
                                <div style={{ maxWidth: "400px", margin: "auto", display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                                    <div className="form-group">
                                        <label>
                                            <input type="checkbox" id="washer_use" name="washer_use" checked={washer_use} onChange={e => setWasherUse(!washer_use)} />
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip>
                                                        If ticked, the discount code can apply to washer machine transaction
                                                    </Tooltip>
                                                }
                                            >
                                                <span>Washer Use</span>
                                            </OverlayTrigger>
                                        </label>
                                    </div>

                                    <div className="form-group">
                                        <label>
                                            <input type="checkbox" id="dryer_use" name="dryer_use" checked={dryer_use} onChange={e => setDryerUse(!dryer_use)} />
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip>
                                                        If ticked, the discount code can apply to dryer machine transaction
                                                    </Tooltip>
                                                }
                                            >
                                                <span>Dryer Use</span>
                                            </OverlayTrigger>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <p className="p-0 m-0"><small>* The user enter the discount code {code ? `(${code})` : ""} in the payment page will get {discount_type === "Flat" ? "RM" : ""}{discount_amount}{discount_type === "Rate" ? "%" : ""} discount</small></p>
                            {start_date && end_date ? <p className="p-0 m-0"><small>* The discount code only can be used start from {new Date(start_date).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })} until {new Date(end_date).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })}</small></p> : ""}
                            {redeem_per_user ? <p className="p-0 m-0"><small>* Every user only can use the discount code {redeem_per_user} times</small></p> : ""}
                            {redeem_per_day ? <p className="p-0 m-0"><small>* Only {redeem_per_day} transactions can use the discount code in one day</small></p> : ""}
                            {washer_use && !dryer_use ? <p className="p-0 m-0"><small>* The discount code only can be used for washer machine</small></p> : ""}
                            {!washer_use && dryer_use ? <p className="p-0 m-0"><small>* The discount code only can be used for dryer machine</small></p> : ""}
                            {washer_use && dryer_use ? <p className="p-0 m-0"><small>* The discount code can be used for washer or dryer machine</small></p> : ""}

                            <div className="text-center pt-3">
                                {loading ? (
                                    <div className="text-center">
                                        <div className={`spinner-border text-${process.env.REACT_APP_STORETYPE === "cuci" ? "warning" : "primary"}`} role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                ) : <input type="submit" value={campaignId ? "Edit Discount Code" : "Add Discount Code"} className="btn btn-primary mx-2" />}
                                <button type="button" className="btn btn-secondary" onClick={e => hideModal()}>
                                    Close
                                </button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        client: state.client.client,
        token: state.client.token
    };
};

export default connect(mapStateToProps, null)(DiscountCode);