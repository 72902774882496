import React, { Component } from "react";
import axios from "axios";
import Papa from 'papaparse';
import Swal from "sweetalert2";
import { connect } from "react-redux";
import Topbar from "../components/Topbar";
import Loading from "../components/Loading";
import Sidebar from "../components/Sidebar";
import withReactContent from "sweetalert2-react-content";
import { MDBDataTable } from "mdbreact";
import template from './userTypetemplate.csv'

const mySwal = withReactContent(Swal);

class UserTypeSetting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggled: false,
            loading: true,
            data: [],
            csvData: [],
            failData: []
        };
    }

    _isMounted = false;
    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted) {
            if (!this.props.admin.isAuthenticated) {
                return this.props.history.push("/admin/dashboard");
            }
            else {
                axios.post("/api/admin/setting/getUserTypeSetting", { id: this.props.admin.admin.operatorId }, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
                    .then(res => {
                        this.setState({
                            ...this.state,
                            data: res.data,
                            loading: false
                        })
                    }).catch(err => {
                        this.setState({
                            ...this.state,
                            loading: false
                        });
                        mySwal.fire("Error", err.response.data.error, "error");
                    });
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleToggle = () => this.setState({ ...this.state, toggled: !this.state.toggled });

    handleFileUpload = (event) => {
        this.setState({ csvData: [] });
        const file = event.target.files[0];
        if (file) {
            // Check the file type
            if (file.type !== 'text/csv') {
                // Display an error message or handle the error accordingly
                // console.error('Invalid file type. Only CSV files are allowed.');
                mySwal.fire("Error", 'Invalid file type. Only CSV files are allowed.', "error");
                return;
            }

            const reader = new FileReader();
            reader.onload = (e) => {
                const contents = e.target.result;
                Papa.parse(contents, {
                    header: true,
                    complete: (results) => {
                        const csvData = results.data
                            .filter((row) => row.phone && row.name && row.type)
                            .map((row) => ({
                                phone: row.phone,
                                name: row.name,
                                type: row.type
                            }));
                        this.setState({ csvData });
                    }
                });
            };
            reader.readAsText(file);
        }
    };

    deleteType = name => {
        mySwal.fire({
            title: "Confirmation",
            text: "Are you sure you want to delete the type?",
            icon: "question",
            showCancelButton: true,
            cancelButtonText: "No",
            confirmButtonText: "Yes",
            cancelButtonColor: "#d33"
        }).then(click => {
            if (click.isConfirmed && click.value) {
                this.setState({
                    ...this.state,
                    loading: true
                });
                axios.post('/api/admin/setting/deleteUserType', { name }, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
                    .then(res => {
                        axios
                            .post("/api/admin/setting/getUserTypeSetting", { id: this.props.admin.admin.operatorId }, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
                            .then(res => {
                                this.setState({
                                    ...this.state,
                                    data: res.data,
                                    loading: false
                                })
                            }).catch(err => {
                                this.setState({
                                    ...this.state,
                                    loading: false
                                });
                                mySwal.fire("Error", err.response.data.error, "error");
                            });
                    }).catch(err => {
                        this.setState({
                            ...this.state,
                            loading: false
                        });
                        mySwal.fire("Error", err.response.data.error, "error");
                    });
            }
        });
    }

    updateUser = () => {
        mySwal.fire({
            title: "Confirmation",
            text: "Are you sure you want to update the users type?",
            icon: "question",
            showCancelButton: true,
            cancelButtonText: "No",
            confirmButtonText: "Yes",
            cancelButtonColor: "#d33"
        }).then(click => {
            if (this.state.csvData && this.state.csvData.length) {
                if (click.isConfirmed && click.value) {
                    this.setState({
                        loading: true
                    });

                    axios.post("/api/admin/setting/updateUserCategory", { data: this.state.csvData }, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
                        .then(res => {
                            if (res.data) {
                                this.setState({
                                    loading: false,
                                    failData: res.data.failData
                                });

                                mySwal.fire({
                                    title: "Success",
                                    text: `Successfully update ${res.data.count} users.`,
                                    icon: "success",
                                    confirmButtonColor: "#ffd109"
                                });
                            }
                            else {
                                this.setState({
                                    ...this.state,
                                    loading: false
                                });
                                mySwal.fire("Error", "Error when updating user type", "error");
                            }
                        }).catch(err => {
                            this.setState({
                                ...this.state,
                                loading: false
                            });
                            mySwal.fire("Error", err.response.data.error, "error");
                        });
                }
            }
            else {
                mySwal.fire("Error", 'Please upload the correct file type before submitting.', "error");
            }
        });
    }

    handleDownload = () => {
        const link = document.createElement('a');
        link.href = template;
        link.setAttribute('download', 'template.csv');
        link.click();
    };

    render() {
        const { csvData } = this.state;
        let tableData = {
            columns: [
                {
                    label: "ID",
                    field: "id",
                    sort: "asc"
                },
                {
                    label: "Name",
                    field: "name",
                },
                {
                    label: "Type",
                    field: "type",
                },
                {
                    label: "Action",
                    field: "action"
                }
            ],
            rows: []
        };

        if (this.state.data.length) {
            for (let i = 0; i < this.state.data.length; i++) {
                const dataObject = {
                    id: this.state.data[i].id,
                    name: this.state.data[i].name,
                    type: this.state.data[i].type,
                    createdAt: this.state.data[i].createdAt,
                    updatedAt: this.state.data[i].updatedAt,
                    action: (
                        <div>
                            <i className="fas fa-edit fa-lg" onClick={() => this.props.history.push("/admin/userType/edit", { typeId: this.state.data[i].id })}></i>
                            <i className="fas fa-trash-alt fa-lg ms-3" onClick={() => this.deleteType(this.state.data[i].name)}></i>
                        </div>
                    )
                };
                tableData.rows.push(dataObject);
            }
        }

        let tableCSVData = {
            columns: [
                {
                    label: "Phone",
                    field: "phone",
                },
                {
                    label: "Type Name",
                    field: "type"
                },
                {
                    label: "Remark",
                    field: "remark",
                },
            ],
            rows: []
        };

        // console.log(this.state.failData)
        if (this.state.failData.length) {
            for (let i = 0; i < this.state.failData.length; i++) {
                const dataObject = {
                    phone: this.state.failData[i].phone,
                    type: this.state.failData[i].type,
                    remark: this.state.failData[i].remark,
                };
                tableCSVData.rows.push(dataObject);
            }
        }

        const renderList = () => {
            if (this.state.loading) {
                return (
                    <Loading />
                );
            }
            else {
                if (!this.state.data.length) {
                    return (
                        <div className="text-center">
                            <h3> - NO USER TYPE - </h3>
                        </div>
                    );
                }
                else {
                    return (
                        <MDBDataTable data={tableData} noBottomColumns striped hover responsive bordered />
                    );
                }
            }
        }

        const renderFailRecord = () => {
            if (this.state.loading) {
                return (
                    <Loading />
                );
            }
            else {
                if (!this.state.failData.length) {
                    return (
                        <div className="text-center">
                            <br />
                            <div>
                                <p>Please ensure it enter the valid phone number and it is in
                                    <br />
                                    text form inside the template, for example: 0123456789.
                                </p>
                                <a href="#" onClick={this.handleDownload}>Download CSV Template</a>
                            </div>
                            <br />
                            <br />
                        </div>
                    );
                }
                else {
                    return (
                        <div>
                            <h4>Records that failed to update</h4>
                            <br />
                            <MDBDataTable data={tableCSVData} noBottomColumns striped hover responsive bordered />
                        </div>
                    );
                }
            }
        }

        return (
            <div className="admin-page-container">
                <Sidebar toggled={this.state.toggled} setToggled={this.handleToggle} />
                <div className="admin-page">
                    <Topbar handleToggle={this.handleToggle} />
                    <div className="admin-content">
                        <div className="card">
                            <div className="card-header d-flex justify-content-between">
                                <h5 className="card-title">User Type setting</h5>
                                <button className="btn btn-primary" type="button"
                                    onClick={() => this.props.history.push("/admin/userType/create")}
                                >
                                    ADD USER TYPE
                                </button>
                            </div>
                            <div className="card-body">
                                {
                                    renderList()
                                }
                            </div>
                        </div>
                        <br />

                        <div className="card">
                            <div className="card-header d-flex justify-content-between">
                                <h5 className="card-title">Update User By File</h5>

                            </div>
                            <div className="card-body">
                                {
                                    renderFailRecord()
                                }
                                <br />
                                <input type="file" onChange={event => this.handleFileUpload(event)} />
                                <button className="btn btn-primary" type="button" onClick={this.updateUser}>Update User</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        admin: state.admin
    };
};

// const mapDispatchToProps = dispatch => {
// 	return {
// 		// updatePaymentSetting: data => dispatch(updatePaymentSetting(data)),
// 	};
// };

export default connect(mapStateToProps, null)(UserTypeSetting);
