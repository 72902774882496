import React, { Component } from 'react';
import axios from "axios";
import { connect } from "react-redux";
import Sidebar from '../components/Sidebar';
import Topbar from '../components/Topbar';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { updatePoint } from "../../../redux/actions/adminActions";

const mySwal = withReactContent(Swal);
class EditPoint extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            token: false,
            rm_amount: 0,
            exchange_ratio: 0,
            point_amount: 0,
            category: "",
            colSpan: "",
            multiply: 0
        };
    };

    componentDidMount() {
        if (!this.props.admin.admin) {
            this.props.history.push("/admin/dashboard");
        } else {
            axios
                .post("/api/admin/setting/getEditPointSetting", { pointId: this.props.location.state.pointId }, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
                .then(res => {
                    this.setState({
                        ...this.state,
                        rm_amount: res.data.rm_amount,
                        exchange_ratio: res.data.point_ratio,
                        point_amount: res.data.rm_amount / res.data.point_ratio,
                        category: res.data.category,
                        colSpan: res.data.category_colSpan,
                        multiply: res.data?.multiply,
                        loading: false
                    });
                })
                .catch(err => mySwal.fire("Error", err.response.data.error, "error"));
        }
    }

    handleToggle = () => this.setState({ ...this.state, toggled: !this.state.toggled });

    handlePointSetting = (e) => {
        e.preventDefault();
        //console.log("Ringgit", this.state.rm_amount)
        if (this.state.rm_amount && this.state.exchange_ratio) {
            mySwal.fire({
                title: "Confirmation",
                text: "Are you sure you want to change the point setting?",
                icon: "question",
                showCancelButton: true,
                cancelButtonText: "No",
                confirmButtonText: "Yes",
                cancelButtonColor: "#d33"
            })
                .then(click => {
                    if (click.isConfirmed && click.value) {
                        this.setState({
                            ...this.state,
                            loading: true
                        });
                    }

                    const sendThis = {
                        id: this.props.location.state.pointId,
                        adminId: this.props.admin.admin.operatorId,
                        rm_amount: this.state.rm_amount,
                        exchange_ratio: this.state.exchange_ratio,
                        point_amount: this.state.rm_amount / this.state.exchange_ratio,
                        category: this.state.category,
                        colSpan: this.state.colSpan,
                        multiply: this.state.multiply
                    }

                    axios.post('/api/admin/setting/editPointRatio', sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
                        .then(res => {
                            this.setState({
                                ...this.state,
                                loading: false
                            });
                            this.props.updatePoint(sendThis);

                            mySwal.fire({
                                title: "Success",
                                text: "Your point setting has been updated successfully.",
                                icon: "success",
                                confirmButtonColor: "#ffd109"
                            });
                        })
                        .catch(err => {
                            console.log(err)
                            mySwal.fire("Error", err.response.data.error, "error");
                            this.setState({
                                ...this.state,
                                loading: false
                            });
                        });
                })
        }
    }

    // getCategory = value => {
    //     // console.log(`selected ${value}`);
    //     this.setState({
    //         ...this.state,
    //         category: value
    //     })
    // };

    handleChange = e => {
        this.setState({
            ...this.state,
            [e.target.id]: e.target.value
        }, () => {
            this.calculateOutput();
        });
    };

    handleString = e => {
        this.setState({
            ...this.state,
            category: e.target.value
        })
    }

    handleCol = e => {
        this.setState({
            ...this.state,
            [e.target.id]: e.target.value
        })
    }

    calculateOutput() {
        const point_amount = this.state.rm_amount / this.state.exchange_ratio;
        this.setState({ point_amount });
    }

    render() {
        return (
            <div className="admin-page-container">
                <Sidebar toggled={this.state.toggled} setToggled={this.handleToggle} />
                <div className="admin-page">
                    <Topbar handleToggle={this.handleToggle} />
                    <div className="admin-content">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="card-title">Edit Point ID:  {this.props.location.state.pointId}</h5>
                            </div>
                            <div className="card-body">
                                <form onSubmit={this.handlePointSetting}>
                                    <h5>Divide Ratio</h5>
                                    <p>Item based on that category divided by the ratio (For User)</p>
                                    <div className="d-flex">
                                        <div className="form-group">
                                            <label style={{ width: "70%" }}>
                                                <input id="rm_amount" type="number" name="rm_amount" value={this.state.rm_amount} onChange={this.handleChange} required min="1" />
                                                <span>Sample Ringgit Malaysia (RM)</span>
                                            </label>
                                        </div>
                                        <div className="form-group">
                                            <label style={{ width: "80%" }}>
                                                <input id="exchange_ratio" type="number" name="exchange_ratio" value={this.state.exchange_ratio} onChange={this.handleChange} required min="1" />
                                                <span>Ratio</span>
                                            </label>
                                        </div>
                                        <div className="form-group">
                                            <h4> = </h4>
                                        </div>
                                        <div className="form-group ms-5">
                                            <label style={{ width: "50%" }}>
                                                <input id="point_amount" name="point_amount" disabled='true' value={this.state.point_amount} />
                                                <span>Sample Exchanged Point</span>
                                            </label>
                                        </div>
                                        {/* <div className="form-group ms-5">
                                            <label style={{ width: "50%" }}>
                                                <input id="colSpan" name="colSpan" type="number" min="1" value={this.state.colSpan} onChange={this.handleCol} required />
                                                <span>ColSpan</span>
                                            </label>
                                        </div> */}
                                        <div className="form-group ms-5">
                                            <label style={{ width: "50%" }}>
                                                <input id="category" name="category" value={this.state.category} onChange={this.handleString} required  disabled/>
                                                <span>Category</span>
                                            </label>
                                        </div>
                                    </div>
                                    <br />
                                    <br />
                                    <div>
                                        <h5>Item Point Calculation</h5>
                                        <br />
                                        <div style={{ display: "flex" }}>
                                            <p>Item RM multiply by </p>
                                            <input id="multiply" name="multiply" required value={this.state.multiply} style={{ width: "3%", height: "22px", marginLeft: "5px" }} type="number" min="1" max="100" onChange={this.handleCol} />
                                            <p>{this.state.category} =  Item point: {this.state.rm_amount * this.state.multiply} POINT</p>
                                        </div>
                                    </div>
                                    <br />
                                    <div>
                                        {
                                            this.state.loading
                                                ? (
                                                    <div className="text-center">
                                                        <div className={`spinner-border text-${process.env.REACT_APP_STORETYPE === "cuci" ? "warning" : "primary"}`} role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                    </div>
                                                )
                                                : <input type="submit" value="SUBMIT" className="btn btn-block btn-warning big-button" />
                                        }
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
};

const mapStateToProps = state => {
    return {
        admin: state.admin

    };
};

const mapDispatchToProps = dispatch => {
    return {
        updatePoint: data => dispatch(updatePoint(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPoint);