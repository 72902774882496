import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import i18n from "i18next";
import whatsapp from "../../../assets/images/whatapps.png";

const mySwal = withReactContent(Swal);

class Feedback extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            number: "",
            comment: "",
            location: "",
            name: "",
            userId: ""
        };
    };

    componentDidMount() {
        if (this.props.user) {
            this.setState({
                ...this.state,
                name: this.props.user.name,
                number: this.props.user.number,
                loading: false,
                userId: this.props.user.id
            });
        } 
    }

    handleChange = e => {
        this.setState({
            ...this.state,
            [e.target.id]: e.target.value
        });
    };

    handleSubmit = e => {
        e.preventDefault();

        const { number, location, comment, name, userId } = this.state;

        if (number && comment) {
            mySwal.fire({
                title: i18n.t("Confirmation"),
                text: i18n.t("Are you sure you want to submit your feedback?"),
                icon: "question",
                showCancelButton: true,
                cancelButtonText: i18n.t("No"),
                confirmButtonText: i18n.t("Yes"),
                cancelButtonColor: "#d33",
                customClass: {
                    confirmButton: 'confirm-submit-feedback-yes',
                    cancelButton: 'confirm-submit-feedback-no'
                }
            })
                .then(click => {

                    if (click.isConfirmed && click.value) {
                        this.setState({
                            ...this.state,
                            loading: true
                        });

                        const sendThis = {
                            number,
                            comment,
                            location,
                            name,
                            userId,
                            status: "Pending"
                        };
                        
                        axios.post('/api/user/sendfeedback', sendThis)
                            .then(res => {
                                this.setState({
                                    ...this.state,
                                    loading: false
                                });
                                mySwal.fire(i18n.t("Success"), i18n.t("Your feedback is submitted successfully"), "success");
                            })
                            .catch(err => {
                                this.setState({
                                    ...this.state,
                                    loading: false
                                });
                                mySwal.fire("Error", err.response.data.error, "error");
                            });
                    }
                })
        } else {
            mySwal.fire(i18n.t("Info"), i18n.t("Please fill in all the required fields before submit."), "info");
        }
    };

    render() {

        return (
            <div id="setting-page">
                <div id="setting-main">
                    <div id="user-header">
                        <h5><i onClick={e => this.props.history.goBack()} className="fas fa-chevron-left me-3"></i> <strong>{i18n.t("Feedback")}</strong></h5>
                    </div>

                    <div id="user-body">
                        <h1 className="text-center pt-3 px-3">{i18n.t("Give us your feedback!")}</h1>
                        {
                            process.env.REACT_APP_STORETYPE === "cuci" ? (
                                <div className="pt-3 d-flex px-2">
                                    <div>
                                        <img src={whatsapp} alt="whatsapp-icon" className="img-fluid" style={{ width: "80px" }} onClick={() => window.location = "https://wa.me/60129278893"} />
                                    </div>
                                    <div>{i18n.t("If require urgent response, please contact us through whatapp")}, <a href="https://wa.me/60129278893">0129278893</a></div>
                                </div>
                            ) : null
                        }
                        <div className="px-5 py-3">
                            <form onSubmit={this.handleSubmit}>

                                <div className="form-group">
                                    <p>{i18n.t("Name")}</p>
                                    <input type="text" name="name" id="name" className="form-control" placeholder={i18n.t("Type your name here (optional)")} onChange={this.handleChange} value={this.state.name} />
                                </div>

                                <div className="form-group">
                                    <p>{i18n.t("Phone Number")} <i style={{ color: "red" }}>*{i18n.t("required")}</i></p>
                                    <input type="number" name="number" id="number" className="form-control" placeholder={i18n.t("Type your number here")} onChange={this.handleChange} value={this.state.number} required />
                                </div>

                                <div className="form-group">
                                    {/* <p>{i18n.t("Outlet")} <i style={{ color: "red" }}>*{i18n.t("required")}</i></p> */}
                                    <p>{i18n.t("Vending Machine Location")}</p>
                                    {/* <select name="outletId" id="outletId" className="form-select" value={this.state.outletId} onChange={this.handleChange}>
                                        <option value="" disabled>{i18n.t("Select an outlet")}</option>
                                        {this.state.outlets.map(outlet => <option key={outlet.id} value={outlet.id}>{outlet.outlet_fullname}</option>)}
                                    </select> */}
                                    <input type="text" name="location" id="location" className="form-control" placeholder={i18n.t("Type your location here (optional)")} onChange={this.handleChange} value={this.state.location} />
                                </div>

                                {/* <div className="form-group pt-3">
                                    <p>{i18n.t("Outlet Cleanliness")} <i style={{ color: "red" }}>*{i18n.t("required")}</i></p>
                                    <ReactStars
                                        count={5}
                                        onChange={this.handleClean}
                                        size={55}
                                        activeColor="#ffd700"
                                        value={this.state.clean}
                                    />
                                </div>

                                <div className="form-group">
                                    <p>{i18n.t("Washer Machine Performance")} <i style={{ color: "red" }}>*{i18n.t("required")}</i></p>
                                    <ReactStars
                                        count={5}
                                        onChange={this.handleWasher}
                                        size={55}
                                        activeColor="#ffd700"
                                        value={this.state.washer}
                                    />
                                </div>

                                <div className="form-group">
                                    <p>{i18n.t("Dryer Machine Performance")} <i style={{ color: "red" }}>*{i18n.t("required")}</i></p>
                                    <ReactStars
                                        count={5}
                                        onChange={this.handleDryer}
                                        size={55}
                                        activeColor="#ffd700"
                                        value={this.state.dryer}
                                    />
                                </div>

                                <div className="form-group">
                                    <p>{i18n.t("Customer Service")} <i style={{ color: "red" }}>*{i18n.t("required")}</i></p>
                                    <ReactStars
                                        count={5}
                                        onChange={this.handleService}
                                        size={55}
                                        activeColor="#ffd700"
                                        value={this.state.service}
                                    />
                                </div> */}

                                <div className="form-group">
                                    <p>{i18n.t("Comment")} <i style={{ color: "red" }}>*{i18n.t("required")}</i></p>
                                    <textarea id="comment" name="comment" className="form-control" placeholder={i18n.t("Type your comment here")} onChange={this.handleChange} value={this.state.comment}></textarea>
                                </div>

                                <div>
                                    {
                                        this.state.loading
                                            ? (
                                                <div className="text-center">
                                                    <div className="spinner-border" style={{ color: "#ffd109" }} role="status">
                                                        <span className="sr-only">Loading ...</span>
                                                    </div>
                                                </div>
                                            )
                                            :
                                            <input id="submit-feedback" type="submit" value={i18n.t("SUBMIT")} className="btn big-button form-control mt-3" />
                                    }
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );

    };
};

const mapStateToProps = state => {
    return {
        user: state.user.user,
        // operatorId: state.user.operatorId,
        // payment: state.payment.machine
    };
};

export default connect(mapStateToProps, null)(Feedback);