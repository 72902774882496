import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import Sidebar from "../../components/Sidebar";
import Topbar from "../../components/Topbar";
import Loading from "../../components/Loading";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { MDBDataTable } from "mdbreact";
import { CSVLink } from "react-csv";
import { Tabs, Tab, Modal } from 'react-bootstrap';

const mySwal = withReactContent(Swal);

class MemberDetailsReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggled: false,
            loading: true,
            transaction: [],
            // purchaseHistory: [],
            giveaway: [],
            // transfer: [],
            transactionStatus: "Success",
            remark: "",
            showModal: false,
            transactionId: "",
            refundAmount: "",
            selectedTabs: "transaction"
        };
    }
    _isMounted = false;

    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted) {
            if (!this.props.admin.isAuthenticated || !this.props.admin.admin || !this.props.location.state) {
                this.props.history.push("/admin/dashboard");
            } else {
                const sendThis = {
                    operatorId: this.props.admin.admin.operatorId,
                    userId: this.props.location.state.userId,
                    transactionStatus: this.state.transactionStatus
                }

                // console.log(sendThis)
                axios
                    .post("/api/admin/report/memberDetails", sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
                    .then(res => {
                        this.setState({
                            ...this.state,
                            loading: false,
                            transaction: res.data.transaction,
                            // purchaseHistory: res.data.purchaseHistory,
                            giveaway: res.data.giveaway,
                            // transfer: res.data.transfer
                        });
                    })
                    .catch(err => mySwal.fire("Error", err.response.data.error, "error"));
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleToggle = () => this.setState({ ...this.state, toggled: !this.state.toggled });

    handleChange = e => this.setState({
        ...this.state,
        [e.target.id]: e.target.value
    });

    handleFilter = e => {

        this.setState({
            ...this.state,
            loading: true,
            transactionStatus: e.target.value
        });

        const sendThis = {
            operatorId: this.props.admin.admin.operatorId,
            userId: this.props.location.state.userId,
            transactionStatus: e.target.value
        }

        axios
            .post("/api/admin/report/memberDetails", sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
            .then(res => {
                this.setState({
                    ...this.state,
                    loading: false,
                    transaction: res.data.transaction,
                    // purchaseHistory: res.data.purchaseHistory,
                    giveaway: res.data.giveaway,
                    // transfer: res.data.transfer
                });
            })
            .catch(err => {
                this.setState({
                    ...this.state,
                    loading: false
                });
                mySwal.fire("Error", err.response.data.error, "error")
            });
    };

    refund = () => {
        if (!this.state.remark) {
            mySwal.fire("Error", "Please fill in the remark", "error")
        } else {
            const sendThis = {
                transactionId: this.state.transactionId,
                refundBy: this.props.admin.admin.operatorId,
                remark: this.state.remark,
                role: "operator"
            }

            this.setState({
                ...this.state,
                loading: true,
                showModal: false
            });

            axios
                .post("/api/admin/setting/refund", sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
                .then(res => {

                    const sendThis = {
                        operatorId: this.props.admin.admin.operatorId,
                        userId: this.props.location.state.userId,
                        transactionStatus: "Refund"
                    }

                    axios
                        .post("/api/admin/report/memberDetails", sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
                        .then(res => {

                            this.setState({
                                ...this.state,
                                loading: false,
                                transaction: res.data.transaction,
                                // purchaseHistory: res.data.purchaseHistory,
                                giveaway: res.data.giveaway,
                                // transfer: res.data.transfer,
                                transactionStatus: "Refund",
                                remark: "",
                                transactionId: "",
                                refundAmount: ""
                            });
                        })
                        .catch(err => mySwal.fire("Error", err.response.data.error, "error"));

                })
                .catch(err => mySwal.fire("Error", err.response.data.error, "error"));
        }
    }

    requery = (orderId, userId) => {
        this.setState({
            ...this.state,
            loading: true
        });

        axios
            .post("/api/token/checkRmOrder", { userId, orderId }, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
            .then(res => {

                const sendThis = {
                    operatorId: this.props.admin.admin.operatorId,
                    userId: this.props.location.state.userId,
                    transactionStatus: this.state.transactionStatus
                }

                axios
                    .post("/api/admin/report/memberDetails", sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
                    .then(res => {
                        this.setState({
                            ...this.state,
                            loading: false,
                            transaction: res.data.transaction,
                            // purchaseHistory: res.data.purchaseHistory,
                            giveaway: res.data.giveaway,
                            // transfer: res.data.transfer
                        });
                    })
                    .catch(err => {
                        mySwal.fire("Error", err.response.data.error, "error")
                        this.setState({
                            ...this.state,
                            loading: false
                        });
                    });
            })
            .catch(err => {
                mySwal.fire("Error", err.response.data.error, "error")
                this.setState({
                    ...this.state,
                    loading: false
                });
            })
    }

    render() {
        let transaction = {
            columns: [
                {
                    label: "Transaction Date",
                    field: "createdAt",
                    sort: "asc"
                },
                // {
                //     label: "Outlet",
                //     field: "outlet",
                //     sort: "asc"
                // },
                // {
                //     label: "Machine",
                //     field: "machine",
                //     sort: "asc"
                // },
                // {
                //     label: "Machine Type",
                //     field: "type",
                //     sort: "asc"
                // },
                // {
                //     label: "Machine Capacity",
                //     field: "capacity",
                //     sort: "asc"
                // },
                {
                    label: "Payment Method",
                    field: "method",
                    sort: "asc"
                },
                {
                    label: "Total Amount",
                    field: "totalAmount",
                    sort: "asc"
                },
                {
                    label: "Payment Amount",
                    field: "paymentAmount",
                    sort: "asc"
                },
                {
                    label: "Discount From",
                    field: "discountFrom",
                    sort: "asc"
                },
                {
                    label: "Discount Type",
                    field: "discountType",
                    sort: "asc"
                },
                {
                    label: "Discount Amount",
                    field: "discountAmount",
                    sort: "asc"
                },
                {
                    label: "Discount Code",
                    field: "discountCode",
                    sort: "asc"
                },
            ],
            rows: []
        };

        const transactionCsvData = {
            headers: [
                { label: "Transaction Date", key: "createdAt" },
                // { label: "Outlet", key: "outlet" },
                // { label: "Machine", key: "machine" },
                // { label: "Machine Type", key: "type" },
                // { label: "Machine Capacity", key: "capacity" },
                { label: "Payment Method", key: "method" },
                { label: "Total Amount", key: "totalAmount" },
                { label: "Payment Amount", key: "paymentAmount" },
                { label: "Discount From", key: "discountFrom" },
                { label: "Discount Type", key: "discountType" },
                { label: "Discount Amount", key: "discountAmount" },
                { label: "Discount Code", key: "discountCode" },
            ],
            data: []
        };

        if (this.state.transactionStatus === "Success") {
            transaction.columns.push(
                {
                    label: "Action",
                    field: "action",
                    sort: "asc"
                }
            )
        }

        if (this.state.transactionStatus === "Failed") {
            transaction.columns.push(
                {
                    label: "Remark",
                    field: "remark",
                    sort: "asc"
                }
            )

            transactionCsvData.headers.push(
                { label: "Remark", key: "remark" }
            )
        }

        if (this.state.transactionStatus === "Refund") {
            transaction.columns.push(
                {
                    label: "Remark",
                    field: "remark",
                    sort: "asc"
                },
                {
                    label: "Refund Date",
                    field: "refundDate",
                    sort: "asc"
                }
            )

            transactionCsvData.headers.push(
                { label: "Remark", key: "remark" },
                { label: "Refund Date", key: "refundDate" }
            )
        }

        if (this.state.transaction.length > 0) {
            for (let i = 0; i < this.state.transaction.length; i++) {
                const dataObject = {
                    createdAt: this.state.transaction[i].createdAt,
                    // outlet: this.state.transaction[i].outlet,
                    // machine: this.state.transaction[i].machine,
                    // type: this.state.transaction[i].type,
                    // capacity: this.state.transaction[i].capacity,
                    method: this.state.transaction[i].method,
                    totalAmount: this.state.transaction[i].method === "TOKEN" ? `${this.state.transaction[i].totalAmount} TOKEN` : `RM ${(this.state.transaction[i].totalAmount).toFixed(2)}`,
                    paymentAmount: this.state.transaction[i].method === "TOKEN" ? `${this.state.transaction[i].paymentAmount} TOKEN` : `RM ${(this.state.transaction[i].paymentAmount).toFixed(2)}`,
                    discountFrom: this.state.transaction[i].totalAmount === this.state.transaction[i].paymentAmount ? "-" : this.state.transaction[i].voucherId ? "Voucher" : this.state.transaction[i].discountId ? "Discount Code" : "Promotion",
                    discountType: this.state.transaction[i].totalAmount === this.state.transaction[i].paymentAmount ? "-" : this.state.transaction[i].voucherId ? this.state.transaction[i].voucherType : this.state.transaction[i].discountId ? this.state.transaction[i].discountType : "-",
                    discountAmount: this.state.transaction[i].totalAmount === this.state.transaction[i].paymentAmount ? "-" : this.state.transaction[i].voucherId ? this.state.transaction[i].voucherAmount : this.state.transaction[i].discountId ? this.state.transaction[i].discountAmount : "-",
                    discountCode: this.state.transaction[i].discountCode ? this.state.transaction[i].discountCode : "-",
                    action: (
                        this.state.transaction[i].method === "TOKEN" ?
                            <button type="button" className="btn btn-primary"
                                onClick={e => this.setState({
                                    ...this.state,
                                    showModal: true,
                                    transactionId: this.state.transaction[i].id,
                                    refundAmount: this.state.transaction[i].totalAmount
                                })}
                            >
                                Refund
                            </button> : null
                    ),
                    remark: this.state.transaction[i].remark,
                    refundDate: this.state.transaction[i].refundDate
                }

                transaction.rows.push(dataObject);

                transactionCsvData.data.push(dataObject)
            }
        }

        // let purchaseHistory = {
        //     columns: [
        //         {
        //             label: "Purchase Date",
        //             field: "date",
        //             sort: "asc"
        //         },
        //         // {
        //         //     label: "e-Token Purchased (Tokens)",
        //         //     field: "token",
        //         //     sort: "asc"
        //         // },
        //         {
        //             label: "Bought e-Token using (RM)",
        //             field: "amount",
        //             sort: "asc"
        //         },
        //         {
        //             label: "Transaction ID",
        //             field: "transactionId",
        //             sort: "asc"
        //         },
        //         {
        //             label: "Reference ID",
        //             field: "referenceId",
        //             sort: "asc"
        //         },
        //         {
        //             label: "Method",
        //             field: "method",
        //             sort: "asc"
        //         },
        //         {
        //             label: "Status",
        //             field: "status",
        //             sort: "asc"
        //         },
        //         {
        //             label: "Action",
        //             field: "action",
        //             sort: "asc"
        //         }
        //     ],
        //     rows: []
        // };

        // const purchaseHistoryCsvData = {
        //     headers: [
        //         { label: "Purchase Date", key: "date" },
        //         // { label: "e-Token Purchased (Tokens)", key: "token" },
        //         { label: "Bought e-Token using (RM)", key: "amount" },
        //         { label: "Transaction ID", key: "transactionId" },
        //         { label: "Reference ID", key: "referenceId" },
        //         { label: "Method", key: "method" },
        //         { label: "Status", key: "status" }
        //     ],
        //     data: this.state.purchaseHistory
        // };

        // if (this.state.purchaseHistory.length > 0) {
        //     for (let i = 0; i < this.state.purchaseHistory.length; i++) {
        //         const dataObject = {
        //             date: this.state.purchaseHistory[i].date,
        //             token: this.state.purchaseHistory[i].token,
        //             amount: this.state.purchaseHistory[i].amount,
        //             transactionId: this.state.purchaseHistory[i].transactionId,
        //             referenceId: this.state.purchaseHistory[i].referenceId,
        //             method: this.state.purchaseHistory[i].method,
        //             status: this.state.purchaseHistory[i].status,
        //             action: (
        //                 this.state.purchaseHistory[i].status === "Failed" && this.state.purchaseHistory[i].type === "rm" ?
        //                     <button type="button" className="btn btn-primary" onClick={() => this.requery(this.state.purchaseHistory[i].id, this.state.purchaseHistory[i].userId)}>
        //                         Requery
        //                     </button> : null
        //             )
        //         }
        //         purchaseHistory.rows.push(dataObject)
        //     }
        // }

        // let giveaway = {
        //     columns: [
        //         {
        //             label: "Reward Date",
        //             field: "date",
        //             sort: "asc"
        //         },
        //         {
        //             label: "Reward Type",
        //             field: "rewardType",
        //             sort: "asc"
        //         },
        //         {
        //             label: "Remark",
        //             field: "remark",
        //             sort: "asc"
        //         },
        //         {
        //             label: "Voucher ID",
        //             field: "voucherId",
        //             sort: "asc"
        //         },
        //         {
        //             label: "Voucher Type",
        //             field: "voucherType",
        //             sort: "asc"
        //         },
        //         {
        //             label: "Voucher Amount",
        //             field: "voucherAmount",
        //             sort: "asc"
        //         },
        //     ],
        //     rows: this.state.giveaway
        // };

        // const giveawayCsvData = {
        //     headers: [
        //         { label: "Reward Date", key: "date" },
        //         { label: "Reward Type", key: "rewardType" },
        //         { label: "Remark", key: "remark" },
        //         { label: "Voucher ID", key: "voucherId" },
        //         { label: "Voucher Type", key: "voucherType" },
        //         { label: "Voucher Amount", key: "voucherAmount" },
        //     ],
        //     data: this.state.giveaway
        // };

        // if (this.props.admin?.admin?.stamp) {
        //     giveaway.columns.push(
        //         {
        //             label: "Washer Stamp",
        //             field: "washerStamp",
        //             sort: "asc"
        //         },
        //         {
        //             label: "Dryer Stamp",
        //             field: "dryerStamp",
        //             sort: "asc"
        //         },
        //     );

        //     giveawayCsvData.headers.push(
        //         { label: "Washer Stamp", key: "washerStamp" },
        //         { label: "Dryer Stamp", key: "dryerStamp" }
        //     )
        // }

        // if (this.props.admin?.admin?.token) {
        //     giveaway.columns.push(
        //         {
        //             label: "e-Token Amount",
        //             field: "tokenAmount",
        //             sort: "asc"
        //         },
        //     );

        //     giveawayCsvData.headers.push(
        //         { label: "e-Token Amount", key: "tokenAmount" }
        //     )
        // }

        // let transfer = {
        //     columns: [
        //         {
        //             label: "Transfer Date",
        //             field: "date",
        //             sort: "asc"
        //         },
        //         {
        //             label: "Transfer Amount (Token)",
        //             field: "amount",
        //             sort: "asc"
        //         },
        //         {
        //             label: "Number (Transfer From)",
        //             field: "transferFromNumber",
        //             sort: "asc"
        //         },
        //         {
        //             label: "Name (Transfer From)",
        //             field: "transferFromName",
        //             sort: "asc"
        //         },
        //         {
        //             label: "Member ID (Transfer From)",
        //             field: "transferFromMemberId",
        //             sort: "asc"
        //         },
        //         {
        //             label: "Number (Transfer To)",
        //             field: "transferToNumber",
        //             sort: "asc"
        //         },
        //         {
        //             label: "Name (Transfer To)",
        //             field: "transferToName",
        //             sort: "asc"
        //         },
        //         {
        //             label: "Member ID (Transfer To)",
        //             field: "transferToMemberId",
        //             sort: "asc"
        //         },
        //     ],
        //     rows: this.state.transfer
        // };

        // const transferCsvData = {
        //     headers: [
        //         { label: "Transfer Date", key: "date" },
        //         { label: "Transfer Amount (Token)", key: "amount" },
        //         { label: "Number (Transfer From)", key: "transferFromNumber" },
        //         { label: "Name (Transfer From)", key: "transferFromName" },
        //         { label: "Member ID (Transfer From)", key: "transferFromMemberId" },
        //         { label: "Number (Transfer To)", key: "transferToNumber" },
        //         { label: "Name (Transfer To)", key: "transferToName" },
        //         { label: "Member ID (Transfer To)", key: "transferToMemberId" },
        //     ],
        //     data: []
        // };

        // if (this.state.transfer.length > 0) {
        //     for (let i = 0; i < this.state.transfer.length; i++) {
        //         const transferDataObject = {
        //             date: this.state.transfer[i].date,
        //             amount: this.state.transfer[i].amount,
        //             transferFromNumber: `=""${this.state.transfer[i].transferFromNumber}""`,
        //             transferFromName: this.state.transfer[i].transferFromName,
        //             transferFromMemberId: this.state.transfer[i].transferFromMemberId,
        //             transferToNumber: `=""${this.state.transfer[i].transferToNumber}""`,
        //             transferToName: this.state.transfer[i].transferToName,
        //             transferToMemberId: this.state.transfer[i].transferToMemberId
        //         }
        //         transferCsvData.data.push(transferDataObject)
        //     }
        // }

        return (
            <div className="admin-page-container">
                <Sidebar toggled={this.state.toggled} setToggled={this.handleToggle} />
                <div className="admin-page">
                    <Topbar handleToggle={this.handleToggle} />
                    <div className="admin-content">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="card-title">Member Details Report ({this.props.location.state.memberId})</h5>
                            </div>

                            <div className="card-body">
                                {
                                    this.state.loading ? <Loading /> : (
                                        <div>
                                            <Tabs activeKey={this.state.selectedTabs} onSelect={(k) => this.setState({ ...this.state, selectedTabs: k })}>
                                                <Tab eventKey="transaction" title="Transaction">
                                                    <div className="mb-3 mt-3">
                                                        <h6>Filter</h6>
                                                        <div>
                                                            <label htmlFor="transactionStatus">Transaction Status: </label>
                                                            <select id="transactionStatus" name="transactionStatus" className="browser-default form-select" value={this.state.transactionStatus} onChange={this.handleFilter}>
                                                                <option value="Success">Success</option>
                                                                <option value="Failed">Failed</option>
                                                                <option value="Refund">Refund</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <MDBDataTable data={transaction} noBottomColumns striped hover responsive bordered />
                                                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                        <CSVLink className="btn btn-primary" data={transactionCsvData.data} headers={transactionCsvData.headers} filename={`transactionReport(${this.props.location.state.memberId}).csv`} target="_blank">
                                                            Download Report
                                                        </CSVLink>
                                                    </div>
                                                </Tab>
                                                {/* {
                                                    this.props.admin?.admin?.token ? (
                                                        <Tab eventKey="purchaseHistory" title="Token Reload">
                                                            <MDBDataTable data={purchaseHistory} noBottomColumns striped hover responsive bordered />
                                                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                                <CSVLink className="btn btn-primary" data={purchaseHistoryCsvData.data} headers={purchaseHistoryCsvData.headers} filename={`tokenPurchaseReport(${this.props.location.state.memberId}).csv`} target="_blank">
                                                                    Download Report
                                                                </CSVLink>
                                                            </div>
                                                        </Tab>
                                                    ) : null
                                                } */}
                                                {/* {
                                                    this.props.admin?.admin?.token ? (
                                                        <Tab eventKey="transfer" title="Token Transfer">
                                                            <MDBDataTable data={transfer} noBottomColumns striped hover responsive bordered />
                                                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                                <CSVLink className="btn btn-primary" data={transferCsvData.data} headers={transferCsvData.headers} filename={`tokenTransferReport(${this.props.location.state.memberId}).csv`} target="_blank">
                                                                    Download Report
                                                                </CSVLink>
                                                            </div>
                                                        </Tab>
                                                    ) : null
                                                } */}
                                                {/* <Tab eventKey="giveaway" title="User Reward">
                                                    <MDBDataTable data={giveaway} noBottomColumns striped hover responsive bordered />
                                                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                        <CSVLink className="btn btn-primary" data={giveawayCsvData.data} headers={giveawayCsvData.headers} filename={`userRewardReport(${this.props.location.state.memberId}).csv`} target="_blank">
                                                            Download Report
                                                        </CSVLink>
                                                    </div>
                                                </Tab> */}
                                            </Tabs>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    show={this.state.showModal}
                    onHide={() => this.setState({
                        ...this.state,
                        showModal: false,
                        transactionId: "",
                        remark: "",
                        refundAmount: ""
                    })}
                    keyboard={false}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Refund ({this.state.refundAmount} TOKEN)</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <div className="form-group">
                                <label htmlFor="remark">Remark</label>
                                <input type="text" id="remark" name="remark" value={this.state.remark} onChange={this.handleChange} required />
                            </div>

                            <div className="text-center">
                                <button type="button" className="btn btn-primary" onClick={e => this.refund()}>
                                    Refund
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        admin: state.admin
    };
};

export default connect(mapStateToProps, null)(MemberDetailsReport);
