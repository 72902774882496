import { Switch, Route, Redirect } from "react-router-dom";

import Login from "./pages/Login";
import AdminDashboard from "./pages/Dashboard";

// import StorePerformance from "./pages/reports/StorePerformance";
// import TokenReport from "./pages/reports/TokenReport";
import Faq from "./pages/Faq";
import News from "./pages/News";
import AddFaq from "./pages/AddFaq";
import EditFaq from "./pages/EditFaq";
import AddNews from "./pages/AddNews";
import Referral from "./pages/Referral";
import AddPoint from "./pages/AddPoint";
import EditNews from "./pages/EditNews";
import TokenSetting from "./pages/Token";
import Customize from "./pages/Customize";
import EditPoint from "./pages/EditPoint";
import AddCredit from "./pages/AddCredit";
import EditCredit from "./pages/EditCredit";
import VoucherSetting from "./pages/Setting";
import UserDetails from "./pages/UserDetails";
import AddUserType from "./pages/AddUserType";
import EditUserType from "./pages/EditUserType";
import DiscountCode from "./pages/DiscountCode";
import SmsReport from "./pages/reports/SmsReport";
import RewardHistory from "./pages/RewardHistory";
import CreditSetting from "./pages/CreditSetting";
import TokenDetail from "./pages/reports/TokenDetail";
import UserTypeSetting from "./pages/UserTypeSetting";
import FeedbackReport from "./pages/reports/FeedbackReport";
import UserPerformance from "./pages/reports/UserPerformance";
import VoucherRedemption from "./pages/reports/VoucherRedemption";
import MemberDetailsReport from "./pages/reports/MemberDetailsReport";
import TokenPurchaseHistory from "./pages/reports/TokenPurchaseHistory";


const Admin = () => {
	return (
		<div>
			<Switch>
				<Route exact path="/admin/login" component={Login} />
				<Route exact path="/admin/dashboard" component={AdminDashboard} />
				<Route exact path="/admin/voucher/setting" component={VoucherSetting} />
				{/* <Route exact path="/admin/outlet/performance" component={StorePerformance} /> */}
				<Route exact path="/admin/user/performance" component={UserPerformance} />
				<Route exact path="/admin/voucher/redemption" component={VoucherRedemption} />
				{/* <Route exact path="/admin/token/report" component={TokenReport} /> */}
				<Route exact path="/admin/user/details" component={UserDetails} />
				<Route exact path="/admin/user/details/memberDetails" component={MemberDetailsReport} />
				<Route exact path="/admin/setting/customize" component={Customize} />
				<Route exact path="/admin/token/setting" component={TokenSetting} />
				<Route exact path="/admin/token/report/detail" component={TokenDetail} />
				<Route exact path="/admin/token/report/purchasehistory" component={TokenPurchaseHistory} />
				{/* <Route exact path="/admin/giveaway" component={Giveaway} /> */}
				<Route exact path="/admin/giveaway/history" component={RewardHistory} />
				<Route exact path="/admin/sms/report" component={SmsReport} />
				<Route exact path="/admin/faq" component={Faq} />
				<Route exact path="/admin/faq/add" component={AddFaq} />
				<Route exact path="/admin/faq/edit" component={EditFaq} />
				<Route exact path="/admin/referral" component={Referral} />
				<Route exact path="/admin/news" component={News} />
				<Route exact path="/admin/news/add" component={AddNews} />
				<Route exact path="/admin/news/edit" component={EditNews} />
				<Route exact path="/admin/discountCode" component={DiscountCode} />
				<Route exact path="/admin/point/edit" component={EditPoint} />
				<Route exact path="/admin/point/create" component={AddPoint} />
				<Route exact path="/admin/creditSetting" component={CreditSetting} />
				<Route exact path="/admin/creditSetting/edit" component={EditCredit} />
				<Route exact path="/admin/creditSetting/add" component={AddCredit} />
				<Route exact path="/admin/feedback/report" component={FeedbackReport} />
				<Route exact path="/admin/userType/setting" component={UserTypeSetting} />
				<Route exact path="/admin/userType/create" component={AddUserType} />
				<Route exact path="/admin/userType/edit" component={EditUserType} />
				<Redirect to="/admin/login" />
			</Switch>
		</div>
	);
};

export default Admin;