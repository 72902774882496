import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import axios from "axios";
import { Spinner } from "react-bootstrap";

const IframePayment = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [product, setProduct] = useState([]);
  const location = useLocation();
  const { TID, Token } = queryString.parse(location.search);

  useEffect(() => {
    if (TID && Token) {
      setError("");
      setLoading(true);
      axios
        .post("/api/payment/frame", { TID, Token })
        .then(res => {
          setProduct(res.data.data);
          setLoading(false);
        })
        .catch(err => {
          setError(err.response.data.error);
          setLoading(false);
        });
    }
  }, []);
  return (
    <div id="payment-page">
      <div id="user-body">
        <div className="container text-center">
          {loading ? (
            <Spinner style={{ position: "absolute", top: "50%", left: "50%" }} animation="border" variant="primary" />
          ) : (
            <div>
              {error ? (
                <div>
                  <p style={{ color: "red", paddingTop: "5px" }}>{error}</p>
                </div>
              ) : null}
              <div className="pt-3">
                <img src={product.imageUrl} alt="product" style={{ width: "20%" }} />
                <br />
                <br />
                <h5 className="pt-2">{product.name}</h5>
                <h5>Price: RM {product.price}</h5>
                <h5>Stock: {product.stock}</h5>
              </div>
              <br />
              <div className="pt-3">
                <div style={{ margin: "20px", textAlign: "center" }}>
                  <h5>
                    <strong>
                      Scan the QR code to register <br /> or scan to pay inside the application
                    </strong>
                  </h5>
                  {/* {QR Code} */}
                  <img src={product.memberUrl} alt="tng" style={{ width: "50%", height: "50%" }} />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default IframePayment;
