import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Accordion } from 'react-bootstrap';
import i18n from "i18next";

import { faqImage } from "../../../dynamicController";

const mySwal = withReactContent(Swal);

class UserFaq extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: [],
            defaultFaq: [],
            token: false
        };
    };

    componentDidMount() {
        axios
            .post("/api/user/getFaq")
            .then(res => {
                //console.log(res)
                this.setState({
                    ...this.state,
                    data: res.data.data,
                    defaultFaq: faqImage(),
                    token: res.data.token,
                    loading: false
                });
            })
            .catch(err => mySwal.fire("Error", err.response.data.error, "error"));
    }

    render() {

        const renderList = () => {

            if (this.state.loading) {
                return (
                    <div className="text-center">
                        <div className="spinner-border" style={{ color: "#ffd109" }} role="status">
                            <span className="sr-only">Loading ...</span>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div>
                        <Accordion>
                            {
                                this.state.data.map((faq, i) => {
                                    return (
                                        <Accordion.Item eventKey={faq.id} key={i}>
                                            <Accordion.Header><b>{this.props.language === "ms" && faq.questionBm ? faq.questionBm : this.props.language === "zh" && faq.questionCh ? faq.questionCh : faq.question}</b></Accordion.Header>
                                            <Accordion.Body>
                                                {
                                                    faq.image ? <img src={faq.image} alt="faqImage" className="img-fluid" style={{ padding: "10px 10px 10px 10px" }} /> : null
                                                }
                                                {this.props.language === "ms" && faq.answerBm ? faq.answerBm : this.props.language === "zh" && faq.answerCh ? faq.answerCh : faq.answer}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    );
                                })
                            }
                        </Accordion>
                    </div>
                );
            }
        }

        return (
            <div id="setting-page">
                <div id="setting-main">
                    <div id="user-header">
                        <h5><i onClick={e => this.props.history.goBack()} className="fas fa-chevron-left me-3"></i> <strong>{i18n.t("FAQs")}</strong></h5>
                    </div>

                    <div id="user-body">
                        {
                            renderList()
                        }
                    </div>
                </div>
            </div>
        );

    };
};

const mapStateToProps = state => {
    return {
        operatorId: state.user.operatorId,
        language: state.user.language
    };
};

export default connect(mapStateToProps, null)(UserFaq);