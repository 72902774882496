import { IS_LOADING, IS_LOADED, SHOW_ERROR, CLEAR_ERROR, REMOVE_TOKEN, ADMIN_LOGIN, UPDATE_THEME, UPDATE_VOUCHER, UPDATE_MECHANIC, ADD_PACKAGE, DEL_PACKAGE, ENABLE_REFERRAL, UPDATE_REFERRAL, UPDATE_PAYMENT_SETTING, UPDATE_REGISTER_VOUCHER, UPDATE_BIRTHDAY_VOUCHER, UPDATE_RELOAD_VOUCHER, UPDATE_POINT } from "../actions/type";

const initState = {
	admin: null,
	token: localStorage.getItem("laundry-admin"),
	isAuthenticated: false,
	isLoading: false,
	error: null
};

const adminReducer = (state = initState, action) => {
	switch (action.type) {
		case IS_LOADING:
			return {
				...state,
				isLoading: true
			};
		case IS_LOADED:
			return {
				...state,
				isLoading: false
			};
		case SHOW_ERROR:
			return {
				...state,
				error: action.payload
			};
		case CLEAR_ERROR:
			return {
				...state,
				error: null
			};
		case REMOVE_TOKEN:
			localStorage.removeItem("laundry-admin");
			return {
				...state,
				token: null,
				isAuthenticated: false,
				admin: null
			};
		case ADMIN_LOGIN:
			localStorage.setItem("laundry-admin", action.payload.token);
			return {
				...state,
				admin: action.payload.admin,
				token: action.payload.token,
				isAuthenticated: true
			};
		case UPDATE_THEME:
			localStorage.setItem("theme", action.payload.className);
			return {
				...state,
				admin: {
					...state.admin,
					theme: action.payload
				}
			};

		case UPDATE_VOUCHER:
			return {
				...state,
				admin: {
					...state.admin,
					setting: action.payload
				}
			};

		case UPDATE_MECHANIC:
			return {
				...state,
				admin: {
					...state.admin,
					combined: action.payload.combined,
					specificStamp: action.payload.specificStamp,
					stampTransactionAmount: action.payload.stampTransactionAmount
				}
			};

		case UPDATE_PAYMENT_SETTING:
			return {
				...state,
				admin: {
					...state.admin,
					token: action.payload.token,
					epayment: action.payload.epayment,
					nonMemberPayment: action.payload.nonMemberPayment
				}
			};

		case ADD_PACKAGE:
			return {
				...state,
				admin: {
					...state.admin,
					tokenPackages: [...state.admin.tokenPackages, action.payload]
				}
			};

		case DEL_PACKAGE:
			return {
				...state,
				admin: {
					...state.admin,
					tokenPackages: state.admin.tokenPackages.filter(pkg => pkg.id !== action.payload)
				}
			};

		case ENABLE_REFERRAL:
			return {
				...state,
				admin: {
					...state.admin,
					referral: action.payload
				}
			};

		case UPDATE_REFERRAL:
			return {
				...state,
				admin: {
					...state.admin,
					referralSetting: {
						...state.admin.referralSetting,
						// token_eligible: action.payload.token_eligible,
						// token_amount: action.payload.token_amount,
						// stamp_eligible: action.payload.stamp_eligible,
						// stamp_combined: action.payload.stamp_combined,
						// stamp_washer: action.payload.stamp_washer,
						// stamp_dryer: action.payload.stamp_dryer,
						point_eligible: action.payload.point_eligible,
						point_amount: action.payload.point_amount,
						number_of_referral: action.payload.number_of_referral,
						amount_transaction: action.payload.amount_transaction
					}
				}
			};

		case UPDATE_REGISTER_VOUCHER:
			return {
				...state,
				admin: {
					...state.admin,
					payAndGetRegVoucher: action.payload.payAndGetRegVoucher,
					regVoucherAmount: action.payload.regVoucherAmount
				}
			};

		case UPDATE_BIRTHDAY_VOUCHER:
			return {
				...state,
				admin: {
					...state.admin,
					payAndGetBirthdayVoucher: action.payload.payAndGetBirthdayVoucher,
					birthdayVoucherAmount: action.payload.birthdayVoucherAmount
				}
			};

		case UPDATE_RELOAD_VOUCHER:
			return {
				...state,
				admin: {
					...state.admin,
					tokenPackages: action.payload
				}
			};

		case UPDATE_POINT:
			return {
				...state,
				admin: {
					...state.admin,
					rm_amount: action.payload.rm_amount,
					exchange_ratio: action.payload.exchange_ratio,
					point_amount: action.payload.point_amount
				}
			};

		default:
			return state;
	}
};

export default adminReducer;
