import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { devLogout } from "../../../redux/actions/devActions";
import tAntlysisLogo from '../../../assets/images/logo/tAntlysisLogo.png';

const Sidebar = ({ logout, setShow }) => {
    return (
        <div>
			<div>
				<img src={tAntlysisLogo} alt="antlysis" className="img-fluid" />
			</div>
            <div>
				<ul className="nav flex-column pl-5">
					{/* <li className="nav-item">
						<NavLink activeClassName="active" id="dev-sidebar-nav" className="nav-link" to="/dev/addDistributor">
							<span>Add Distributor</span>
						</NavLink>
					</li> */}

					<li className="nav-item pt-3">
						<NavLink activeClassName="active" id="dev-sidebar-nav" className="nav-link" to="/dev/addOperator">
							<span>Add Operator</span>
						</NavLink>
					</li>

					{/* <li className="nav-item pt-3">
						<NavLink activeClassName="active" id="dev-sidebar-nav" className="nav-link" to="/dev/addOutlet">
							<span>Add Outlet</span>
						</NavLink>
					</li>

					<li className="nav-item pt-3">
						<NavLink activeClassName="active" id="dev-sidebar-nav" className="nav-link" to="/dev/updateAllOutlet">
							<span>Update All Outlet</span>
						</NavLink>
					</li> */}

                    {/* <li className="nav-item pt-3">
						<NavLink activeClassName="active" id="dev-sidebar-nav" className="nav-link" to="/dev/updateOutlet">
							<span>Update One Outlet</span>
						</NavLink>
					</li> */}

                    <li className="nav-item pt-3">
						<NavLink activeClassName="active" id="dev-sidebar-nav" className="nav-link" to="/dev/updateOperator">
							<span>Update Operator</span>
						</NavLink>
					</li>

					<li className="nav-item pt-3">
						<NavLink activeClassName="active" id="dev-sidebar-nav" className="nav-link" to="/dev/disableOperator">
							<span>Disable Operator</span>
						</NavLink>
					</li>

					<li className="nav-item pt-3">
						<p className="nav-link" id="dev-sidebar-nav" onClick={() => logout()}>
							<span>Logout</span>
						</p>
					</li>

				</ul>
			</div>
			<div className="sidebar-close-btn"><button className="btn btn-danger font-weight-bold" onClick={() => setShow(false)}>Close</button></div>
        </div>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(devLogout())
    };
};

export default connect(null, mapDispatchToProps)(Sidebar);