import React from 'react';

import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import { connect } from 'react-redux';

import { ReactNotifications, Store } from 'react-notifications-component'

import LandingPage from './components/user/landingPage/LandingPage';
import PrivacyPolicy from './components/user/agreement/PrivacyPolicy';
import TermsConditions from './components/user/agreement/TermsConditions';
import UserFaq from './components/user/utilities/UserFaq';
import Main from './components/user/main/Main';
import UserUtils from './components/user/utilities/UserUtils';
import Assistant from './components/assistant/Assistant';
import Admin from './components/admin/Admin';
import Dev from './components/dev/Dev';
import Distributor from './components/distributor/Distributor';
import Thankyou from './components/user/utilities/Thankyou';
import Client from './components/client/Client';
import IFrame from "./components/user/utilities/payment/iframePayment"

function App({ sw, theme }) {
  //console.log("APP MADE BY KHOR LI CHUAN %c:^)", "color: lime; font-weight: bold; font-size: 100px; border: 1px lime solid; border-radius: 50%; padding: 3px;");
  const updateServiceWorker = () => {
    const registrationWaiting = sw.registration.waiting;
    console.log("WAITING : ", registrationWaiting);
    if (registrationWaiting) {
      console.log("UPDATE NOW");
      registrationWaiting.postMessage({ type: "SKIP_WAITING" });
      registrationWaiting.addEventListener('statechange', e => {
        console.log("Event : ", e);
        if (e.target.state === "activated") {
          // window.location.reload(true);
          // eslint-disable-next-line
          window.location.href = window.location.href;
        };
      });
    };
  };

  // if (sw.waiting) {
  //   Store.addNotification({
  //     title: "New Update!",
  //     message: "New update is available, click to update now.",
  //     type: "warning",
  //     animationIn: ['animate__animated animate__flipInX'],
  //     animationOut: ['animate__animated animate__flipOutX'],
  //     insert: "top",
  //     container: "bottom-center",
  //     onRemoval: (id, removedBy) => {
  //       updateServiceWorker();
  //       console.log({ id, removedBy });
  //     }
  //   });
  // };

  return (
    <BrowserRouter>
      <div className={theme}>
        <ReactNotifications />
        <Switch>
          <Route exact path="/" component={Main} />
          <Route exact path="/welcome" component={LandingPage} />
          <Route exact path="/iframe" component={IFrame} />
          <Route exact path="/privacyPolicy" component={PrivacyPolicy} />
          <Route exact path="/termsConditions" component={TermsConditions} />
          <Route exact path="/faq" component={UserFaq} />
          <Route exact path="/thankyou" component={Thankyou} />
          <Route path="/user" component={UserUtils} />
          <Route path="/admin" component={Admin} />
          <Route path="/dev" component={Dev} />
          <Route path="/distributor" component={Distributor} />
          <Route path="/client" component={Client} />
          <Route exact path="/assistant" component={Assistant} />
          <Redirect to="/" />
        </Switch>
      </div>
    </BrowserRouter>
  );
}

const mapStateToProps = state => {
  return {
    sw: state.serviceWorker,
    theme: state.theme.theme
  };
};

export default connect(mapStateToProps, null)(App);