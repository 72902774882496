import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { CardGroup, Card } from 'react-bootstrap';
import i18n from "i18next";

import birthdayBanner from "../../../../assets/images/news/birthdayBanner.jpg";
import newUserBanner from "../../../../assets/images/news/newUserBanner.jpg";
import referralBanner from "../../../../assets/images/news/referralBanner.jpg";
import stampBanner from "../../../../assets/images/news/stampBanner.jpg";
import tokenBanner from "../../../../assets/images/news/tokenBanner.jpg";

const mySwal = withReactContent(Swal);

class NewsSlider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            voucherSetting: null,
            referralSetting: null,
            news: []
        };
    };

    componentDidMount() {
        // localStorage.getItem('operatorId')
        // console.log('id--2', this.props.operatorId)
        // if (this.props.operatorId) {

        // }
        axios
            .post("/api/user/getVoucherSetting", { operatorId: 3 })
            .then(res => {
                this.setState({
                    ...this.state,
                    voucherSetting: res.data.voucherSetting,
                    referralSetting: res.data.referralSetting
                });

                axios
                    .post("/api/user/getNews")
                    .then(res => {
                        this.setState({
                            ...this.state,
                            news: res.data,
                            loading: false
                        });
                    })
                    .catch(err => mySwal.fire("Error", "hehe", "error"));


            })
            .catch(err => mySwal.fire("Error", err.response.data.error, "error"));

    }

    render() {

        const renderStampBanner = () => {
            if (this.props.stamp) {
                if (this.props.combinedSetting) {
                    if (this.state.voucherSetting && this.state.voucherSetting.filter(setting => setting.type === "Combined Stamp")[0].voucher_eligible) {
                        return (
                            <Card className={this.props.page === "dashboard" ? "newsCard" : null} onClick={() => this.props.history.push('/user/news/stamp')}>
                                <Card.Img variant="top" src={stampBanner} />
                                <Card.Body style={{ padding: "0.5rem 1rem" }}>
                                    <Card.Text>{i18n.t("Learn More")}</Card.Text>
                                </Card.Body>
                            </Card>
                        );
                    }
                } 
                // else {
                //     if (this.state.voucherSetting && this.state.voucherSetting.filter(setting => setting.type === "Washer Stamp")[0].voucher_eligible || this.state.voucherSetting.filter(setting => setting.type === "Dryer Stamp")[0].voucher_eligible) {
                //         return (
                //             <Card className={this.props.page === "dashboard" ? "newsCard" : null} onClick={() => this.props.history.push('/user/news/stamp')}>
                //                 <Card.Img variant="top" src={stampBanner} />
                //                 <Card.Body style={{ padding: "0.5rem 1rem" }}>
                //                     <Card.Text>{i18n.t("Learn More")}</Card.Text>
                //                 </Card.Body>
                //             </Card>
                //         );
                //     }
                // }
            }
        }

        const renderReferralBanner = () => {
            if (this.props.referral) {
                if (this.state.referralSetting && this.state.voucherSetting && (this.state.referralSetting.stamp_eligible || this.state.referralSetting.token_eligible || this.state.referralSetting.point_eligible || this.state.voucherSetting.filter(setting => setting.type === "Referral")[0].voucher_eligible)) {
                    return (
                        <Card className={this.props.page === "dashboard" ? "newsCard" : null} onClick={() => this.props.history.push('/user/news/referral')}>
                            <Card.Img variant="top" src={referralBanner} />
                            <Card.Body style={{ padding: "0.5rem 1rem" }}>
                                <Card.Text>{i18n.t("Learn More")}</Card.Text>
                            </Card.Body>
                        </Card>
                    );
                }
            }
        }

        const renderList = () => {

            if (this.state.loading) {
                return (
                    <div className="text-center">
                        <div className="spinner-border" style={{ color: "#ffd109" }} role="status">
                            <span className="sr-only">Loading ...</span>
                        </div>
                    </div>
                );
            } else {
                return (
                    <CardGroup className={this.props.page === "dashboard" ? "newsSlider" : null}>
                        {this.state.voucherSetting.filter(setting => setting.type === "Register")[0].voucher_eligible ? (
                            <Card className={this.props.page === "dashboard" ? "newsCard" : null} onClick={() => this.props.history.push('/user/news/newUser')}>
                                <Card.Img variant="top" src={newUserBanner} />
                                <Card.Body style={{ padding: "0.5rem 1rem" }}>
                                    <Card.Text>{i18n.t("Learn More")}</Card.Text>
                                </Card.Body>
                            </Card>
                        ) : null}
                        {
                            renderReferralBanner()
                        }
                        {this.state.voucherSetting.filter(setting => setting.type === "Birthday")[0].voucher_eligible ? (
                            <Card className={this.props.page === "dashboard" ? "newsCard" : null} onClick={() => this.props.history.push('/user/news/birthday')}>
                                <Card.Img variant="top" src={birthdayBanner} />
                                <Card.Body style={{ padding: "0.5rem 1rem" }}>
                                    <Card.Text>{i18n.t("Learn More")}</Card.Text>
                                </Card.Body>
                            </Card>
                        ) : null}
                        {
                            renderStampBanner()
                        }
                        {this.props.token ? (
                            <Card className={this.props.page === "dashboard" ? "newsCard" : null} onClick={() => this.props.history.push('/user/news/token')}>
                                <Card.Img variant="top" src={tokenBanner} />
                                <Card.Body style={{ padding: "0.5rem 1rem" }}>
                                    <Card.Text>{i18n.t("Learn More")}</Card.Text>
                                </Card.Body>
                            </Card>
                        ) : null}
                        {
                            this.state.news.map((news, i) => {
                                if (news.endDate) {
                                    if (new Date(news.endDate) >= new Date()) {
                                        return (
                                            <Card className={this.props.page === "dashboard" ? "newsCard" : null} onClick={() => this.props.history.push(`/user/news/${news.id}`)} key={i}>
                                                <Card.Img variant="top" src={news.banner} />
                                                <Card.Body style={{ padding: "0.5rem 1rem" }}>
                                                    <Card.Text>{i18n.t("Learn More")}</Card.Text>
                                                </Card.Body>
                                            </Card>
                                        );
                                    }
                                } else {
                                    return (
                                        <Card className={this.props.page === "dashboard" ? "newsCard" : null} onClick={() => this.props.history.push(`/user/news/${news.id}`)} key={i}>
                                            <Card.Img variant="top" src={news.banner} />
                                            <Card.Body style={{ padding: "0.5rem 1rem" }}>
                                                <Card.Text>{i18n.t("Learn More")}</Card.Text>
                                            </Card.Body>
                                        </Card>
                                    );
                                }
                            })
                        }
                    </CardGroup>
                );
            }
        }

        return (
            <div>
                {
                    renderList()
                }
            </div>
        );

    };
};

const mapStateToProps = state => {
    return {
        operatorId: state.user.operatorId,
        token: state.user.user.tokenSetting,
        combinedSetting: state.user.user.combinedSetting,
        referral: state.user.user.referral,
        // stamp: state.user.user.stamp
    };
};

export default connect(mapStateToProps, null)(NewsSlider);