import { laundroText } from "../../../dynamicController";

const { REACT_APP_STORETYPE } = process.env

const TermsConditions = ({ history }) => {

    return (
        <div id="agreement-page">
            <nav id="agreement-nav">
                <button onClick={e => history.goBack()} style={{ outline: "none", backgroundColor: "transparent", border: "none" }}><i className="fas fa-arrow-left"></i></button>
            </nav>

            {
                REACT_APP_STORETYPE === "cleanpro" ? (
                    <div className="mt-4 mb-5 px-5">
                        <h3 style={{ color: "#1C75BC" }}>TERMS &amp; CONDITIONS</h3>
                        <div style={{ textAlign: "justify" }}>
                            <p>IMPORTANT:</p>
                            <p>
                                The Services (hereinafter defined) is made available to you by Cleanpro Express Sdn Bhd
                                (Company No.: 940931-M) (“CE”) subject to the Terms and Conditions herein (“Terms and
                                Conditions”). Please read these Terms and Conditions carefully before registering an Account
                                with CE. The acceptance of these Terms and Conditions by you shall constitute an agreement
                                between CE and you for the Services rendered by CE. For the avoidance of doubt, these
                                Terms and Conditions shall ONLY apply to the Services hereunder and not to any other CE’s
                                products. Where applicable, this Terms and Conditions shall be subject to the Cleanpro Pay
                                Terms and Conditions (“Cleanpro Pay T&C”) which is available at Mobile App.
                            </p>
                            <ol>
                                <b><li>DEFINITION</li></b>
                                <p>
                                    In these Terms and Conditions, unless the context otherwise requires or the contrary intention
                                    appears, the following words shall have the meaning assigned to it:
                                </p>
                                <table className="table">
                                    <tbody>
                                        <tr>
                                            <td>“Account”</td>
                                            <td>
                                                means the same definition as provided in the
                                                Cleanpro Pay T&amp;C;
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>“Charges”</td>
                                            <td>
                                                means any type of available usage
                                                transactions for washing, drying and etc.;
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>“Careline”</td>
                                            <td>
                                                means CE’s customer service center which
                                                can be contacted at 03-2770 0100;
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>“GST”</td>
                                            <td>
                                                means the Goods and Services Tax under
                                                the Goods and Services Tax Act 2014 which
                                                will be imposed on Charges as stated under
                                                this Terms and Conditions;
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>"Mobile Application / (Mobile App)"</td>
                                            <td>
                                                means the same definition as provided in the
                                                Cleanpro Pay T &amp; C;
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>“Services”</td>
                                            <td>
                                                means the electronic money (e-money)
                                                services via Mobile App offered by CE
                                                and/or its subsidiaries to you;
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>“Website”</td>
                                            <td>
                                                means CE’s website at {" "}
                                                <a href="https://cleanproexpress.com/">www.cleanproexpress.com</a>;
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <b><li>INTERPRETATION</li></b>
                                <p>
                                    2.1. Whenever these Terms and Conditions refer to a number of days, such number shall refer
                                    to calendar days.
                                </p>
                                <p>
                                    2.2. Words of any gender shall include all genders and words using the singular shall include
                                    the plural and vice versa.
                                </p>
                                <b><li>GENERAL</li></b>
                                <p>
                                    3.1. You are to comply with these Terms and Conditions to avoid any suspension or revocation
                                    of our Services to you. Your continued use of the Account binds you to these Terms and
                                    Conditions at all relevant times.
                                </p>
                                <p>
                                    3.2. You may not assign any of the obligations or benefits of these Terms and Conditions.
                                </p>
                                <b><li>ACCOUNT REGISTRATION</li></b>
                                <p>
                                    4.1. The application and registration of Account shall be subject to terms and conditions as
                                    prescribed by CE and/or its subsidiaries under the Cleanpro Pay T&C in the Mobile App.
                                </p>
                                <p>
                                    4.2. You may register for the Services at the CE Portal or by installing the Mobile App and
                                    activate your Account.
                                </p>
                                <p>
                                    4.3. Once the Account is activated, you are to reload monies into your Account to enable you
                                    to pay for Charges (where applicable).
                                </p>
                                <b><li>REFUND</li></b>
                                <p>
                                    5.1. You are to notify CE’s Careline if you discover any error or discrepancy in your Account
                                    within thirty (30) days from the date of the error or discrepancy in the transaction, failing which
                                    you shall be deemed to have accepted the correctness of the transaction. CE shall then carry
                                    out the verification into that particular transaction. If CE is satisfied that there was an error or
                                    the discrepancy is on CE’s part, CE shall refund the monies for that transaction’s Charges into
                                    your Account within thirty (30) working days after the verification is completed.
                                </p>
                                <p>
                                    5.2. If CE finds no error or discrepancy in the Account, CE reserves the right not to refund any
                                    monies into your Account and a minimal administrative fees or charge will be deducted from
                                    your Account.
                                </p>
                                <b><li>DISCLAIMER</li></b>
                                <p>
                                    6.1. To the extent permitted by law, CE expressly disclaims any representations or warranties,
                                    expressed or implied, relating to the Services, Account and our infrastructure or systems
                                    including without limitation, any implied or express warranties as to quality, fitness for a
                                    particular purpose, conformity to or compatibility with any technology or the up- time status of
                                    or security of our systems. Neither CE and/or its subsidiaries nor their agents shall be liable
                                    for any costs, expenses, losses, damages, penalties, fines, taxes and other liabilities that you
                                    may suffer or incur or may be claimed against you due to any act or omission on our part.
                                </p>
                                <b><li>INDEMNITY</li></b>
                                <p>
                                    7.1. You shall keep CE indemnified against all loss, damage, liability or expense, arising from
                                    any claims for libel, invasion of privacy, infringement of copyright, patent, breach of confidence
                                    or privilege or breach of any law or regulation whatsoever or arising from your breach of these
                                    Terms and Conditions, your use of the Services, the contents transmitted, received or stored
                                    via the Account or part thereof and/or for all other claims arising out of any act or omission to
                                    act by you or any unauthorized use or exploitation of the Account or part thereof that was
                                    registered to you and your vehicle.
                                </p>
                                <p>
                                    7.2. You shall also agree to fully indemnify and hold CE harmless from and against any claim
                                    brought by a third party resulting from your use of the Services and/or in respect of all losses,
                                    costs, actions, proceedings, claims, damages, expenses (including reasonable legal costs and
                                    expenses), or liabilities, whatsoever suffered, or incurred directly or indirectly by CE in
                                    consequence of such use of the Services and/or your breach or non-observance of any of
                                    these Terms and Conditions and/or any applicable terms and conditions of any new services
                                    which CE may provide from time to time.
                                </p>
                                <p>
                                    7.3. You shall defend and pay all costs, damages, awards, fees (including any reasonable
                                    legal fees) and judgments awarded against CE arising from the above claims, and shall
                                    provide CE with notice of such claims, full authority to defend, compromise or settle such
                                    claims, and reasonable assistance necessary to defend such claims, at your sole costs and
                                    expenses.
                                </p>
                                <b><li>TERMINATION OF ACCOUNT</li></b>
                                <p>
                                    8.1. You may, at any time, terminate the Account by giving notice of such termination via the
                                    Mobile App only.
                                </p>
                                <p>
                                    8.2. Upon termination of your Account, all usage of CE linked to this Account and Services
                                    provided herein shall no longer be valid.
                                </p>
                                <b><li>CONSEQUENCES OF TERMINATION</li></b>
                                <p>
                                    9.1. In the event of termination of Account, all outstanding and/or pending Charges will be
                                    deducted from the funds in your Account.
                                </p>
                                <p>
                                    9.2. The unutilized value or balance in the Account (if any) shall be refunded to you within
                                    seven (7) working days from the date of your request for a refund or termination.
                                </p>
                                <p>
                                    9.3. The termination or suspension of the Account shall not affect the rights of CE to seek
                                    appropriate relief against you for antecedent breaches of these Terms and Conditions.
                                </p>
                                <b><li>COSTS AND EXPENSES</li></b>
                                <p>
                                    10.1. You agree to pay all costs, including legal fees, incurred by CE to collect any monies
                                    due under the terms of these Terms and Conditions.
                                </p>
                                <b><li>ENTIRE AGREEMENT AND AMENDMENT</li></b>
                                <p>
                                    11.1. These Terms and Conditions forms the entire Agreement between the parties in respect
                                    of the subject matter covered herein.
                                </p>
                                <p>
                                    11.2. CE reserves the right at its absolute discretion, from time to time, to vary, add to, delete
                                    or otherwise amend these Terms and Conditions or any part thereof by giving you twenty one
                                    (21) days’ prior notice of such changes, additions or deletions. Your continued use of the
                                    Services which is associated with Account shall constitute your unconditional acceptance of
                                    such variation, addition, deletion or amendments. You are advised to visit the Website from
                                    time to time to keep updated of the latest version to these Terms and Conditions (if any).
                                </p>
                                <p>
                                    11.3. A copy of the revised Terms and Conditions, including the effective date, will be provided
                                    through the Website and Mobile App. Your first use of the Services following the publishing of
                                    any revised Terms and Conditions (via the means mentioned above) will constitute your
                                    acceptance of the revised Terms and Conditions. You may choose to opt out and/or decline
                                    the revised Terms and Conditions by not using the Services after the effective date for the
                                    revised Terms and Conditions and terminating the Services and /or Account in accordance to
                                    Clause 11.
                                </p>
                                <p>
                                    11.4. You may inform CE in writing of any changes to the information provided by you in your
                                    Account as following:
                                </p>
                                <ol type="a">
                                    <li>Change in credit card account status (closed account, maximum credit use)</li>
                                    <li>Expiration date of credit card account</li>
                                </ol>
                                <p>
                                    11.5. The Charges imposed pursuant to these Terms and Conditions may change from time
                                    to time. CE shall notify you of any changes to the Charges by giving no less than twenty one
                                    (21) days prior to the effective date of the said changes to Charges.
                                </p>
                                <b><li>SET-OFF</li></b>
                                <p>
                                    12.1. CE shall have the right to set-off any outstanding amounts owed to you by CE against
                                    any sum or monies owed by you to CE by deducting the outstanding amounts from the
                                    Account.
                                </p>
                                <b><li>FORCE MAJEURE</li></b>
                                <p>
                                    13.1. Without limiting the generality of any provision in these Terms and Conditions, CE shall
                                    not be liable for any failure to perform its obligations herein caused by an act of God,
                                    insurrection or civil disorder, military operations or act of terrorism, all emergency, acts or
                                    omission of Government, or any competent authority, labour trouble or industrial disputes of
                                    any kind, fire, lightning, subsidence, explosion, floods, acts or omission of persons or bodies
                                    for whom CE has no control over or any cause outside CE’s reasonable control.
                                </p>
                                <p>
                                    13.2. The Account may occasionally be affected by interference caused by objects beyond
                                    CE’s control such as buildings, underpasses and weather conditions, electromagnetic
                                    interference, equipment failure or congestion in Services system. In the event of such
                                    interference, CE shall not be responsible for any inability to use or access the Account,
                                    interruption or disruption of the Account.
                                </p>
                                <b><li>COMPLIANCE TO LAW</li></b>
                                <p>14.1. PERSONAL DATA PROTECTION</p>
                                <p>
                                    14.1.1 You agree that by registering for the Account, you have consented for your Personal
                                    Information/Data to be used and/or disclosed in accordance with the Personal Data Protection
                                    Act 2010 and CE’s Privacy Notice as posted at the Website.
                                </p>
                                <p>
                                    14.1.2 You also agree and consent that the Personal Information/Data provided by you may
                                    be held in CE’s database and that CE may use, store, analyze, disclose and process your
                                    Personal Information/Data provided by you to CE for the following purposes:-
                                </p>
                                <ol type="a">
                                    <li>Processing your Services and/or Account application</li>
                                    <li>Managing your relationship and Account with CE; and</li>
                                    <li>Audit or verification by any regulatory bodies in Malaysia.</li>
                                </ol>
                                <p>
                                    14.1.3 CE may monitor and record all transactions and communications that may take place
                                    between CE and you for CE’s quality control and training, prevention of unauthorized use of
                                    CE’s laundry systems and ensuring effective systems operation.
                                </p>
                                <p>
                                    14.1.4 You hereby agree, consent and allow CE to extract, disclose and release any
                                    information in the possession of CE relating to you (including your Personal Information/Data),
                                    the particulars of the transaction(s) or any designated account relating to the transaction(s) or
                                    any other data required for the purpose of investigating any claim or dispute arising out of or
                                    in connection with the transaction(s) or to be used as evidence in court or in the event of any
                                    suspected misuse of the Cleanpro Pay or Account. You hereby agree that in the event of a
                                    dispute or claim of any nature arising in respect of any transaction(s), the records of the
                                    transaction(s) available from CE shall be used as sole reference and basis of settling such
                                    dispute or claim. Your agreement and consent under this Clause shall survive the termination
                                    of the Account.
                                </p>
                                <p>
                                    14.2. ANTI-MONEY LAUNDERING, ANTI-TERRORISM FINANCING AND PROCEEDS OF
                                    UNLAWFUL ACTIVITIES 2001 (AMLATFPUAA) & FINANCIAL SERVICES ACT 2013 (FSA)
                                </p>
                                <p>
                                    14.2.1 CE acknowledges that the document or information collected by CE from you relating
                                    to your affair or account as a customer of CE will be only used and/or disclosed in accordance
                                    to the secrecy provisions under the Financial Services Act 2013 (FSA).
                                </p>
                                <p>
                                    14.2.3 Without limiting the generality of the foregoing, to the extent required by the
                                    AMLATFPUAA, CE shall (a) maintain an anti-money laundering and anti- terrorism financing
                                    compliance program that is in compliance, in all material respects, with the AMLATFPUAA,
                                    (b) conduct, in all material respects, the due diligence required under the AMLATFPUAA in
                                    connection with the use of the Services and/or Account, including with respect to the origin of
                                    the funds used by you to reload into the Account and (c) maintain sufficient information to
                                    identify you for purposes of compliance, in all material respects, with the AMLATFPUAA.
                                </p>
                                <p>
                                    14.2.4 In the event your Account is ceased, terminated or suspended by CE due to fraudulent,
                                    illegal or unlawful transactions including but not limited to breaches of any law (including but
                                    not limited to the Financial Services Act 2013 and/or AMLATFPUAA or any regulation and/or
                                    guidelines made thereunder), you shall not be entitled to obtain any refund of the monies and
                                    all reload monies whatsoever in the Account and it shall be lawful for CE to retain for an
                                    indefinite period or release to the relevant authorities all monies in the Account in accordance
                                    with applicable legislation, regulation and/or guidelines. You shall not be entitled to claim any
                                    form of compensation for any loss arising therefrom from CE.
                                </p>
                                <b><li>INTELLECTUAL PROPERTY RIGHTS</li></b>
                                <p>
                                    15.1. All trademarks and other intellectual property rights used in relation to the Services and
                                    Account belong to CE.
                                </p>
                                <p>
                                    15.2. You acknowledge and agree that all trademarks and other intellectual property rights
                                    relating to the use of the Services and Account or any part thereof, whether presented to you
                                    by CE, advertisers or any third party are protected by copyrights, trademarks, service marks,
                                    patents, or other proprietary rights and laws and all CE’s rights therein are expressly reserved.
                                </p>
                                <b><li>SERVICE OF NOTICE</li></b>
                                <p>
                                    16.1. CE may, in its sole discretion, serve you notice under these Terms and Conditions by
                                    posting such notice on the Website or any other online or other medium, which CE may
                                    introduce from time to time.
                                </p>
                                <b><li>SEVERABILITY AND EFFECT OF TERMS AND CONDITIONS</li></b>
                                <p>
                                    17.1. If any of the provision herein contained should be invalid, illegal or unenforceable under
                                    any applicable law, the legality and enforceability of the remaining provisions shall not be
                                    affected or impaired in any way and such invalid, illegal or unenforceable provision shall be
                                    deemed deleted.
                                </p>
                                <b><li>WAIVER</li></b>
                                <p>
                                    18.1. CE’s failure to exercise any particular right or provision of these Terms and Conditions
                                    shall not constitute a waiver of such right or provision, unless acknowledged and agreed to by
                                    CE in writing.
                                </p>
                                <b><li>ASSIGNMENT</li></b>
                                <p>
                                    19.1. You shall not assign or in any other way transfer your rights or obligations under these
                                    Terms and Conditions or part thereof.
                                </p>
                                <b><li>TIME OF ESSENCE</li></b>
                                <p>
                                    20.1. Time wherever mentioned shall be deemed to be and treated as the essence of these
                                    Terms and Conditions.
                                </p>
                                <b><li>GOVERNING LAW</li></b>
                                <p>
                                    These Terms and Conditions shall be governed by and construed in all respects in accordance
                                    with the laws of Malaysia and the courts in Malaysia shall have an exclusive jurisdiction over
                                    any matter arising out of these Terms and Conditions.
                                </p>
                                <b><li>INQUIRIES OR COMPLAINTS</li></b>
                                <p>
                                    22.1. For further information, inquiries or complaint on the Services or Account, you may
                                    contact CE’s Careline:
                                </p>
                                <ol type="a">
                                    <li>at 03-2770 0100;</li>
                                    <li>visit CE’s website at <a href="https://cleanproexpress.com/">www.cleanproexpress.com</a>;</li>
                                    <li>e-mail CE at info@cleanproexpress.com;</li>
                                </ol>
                                <p>
                                    15, JALAN TPP 14, TAMAN PERINDUSTRIAN PUTRA,<br />
                                    Lebuhraya ELITE,<br />
                                    47130 Puchong<br />
                                    Selangor, Malaysia
                                </p>
                                <b><li>Cleanpro Go</li></b>
                                <p>
                                    (1) You agree that your clothes are suitable to be washed in water, on a normal cycle, and
                                    dried using heat in a tumble dryer.
                                </p>
                                <p>
                                    (2) We will not be responsible for any damage to clothing that is not suitable for this standard
                                    laundering process.
                                </p>
                                <p>
                                    (3) Laundry items are tagged by the load and not individually tagged. Therefore, we cannot
                                    accept liability for damage to your Wash N Fold items and also missing items. We will assume
                                    any damages, shrinkages or decolouration’s on the items was already present on the
                                    particular item when the items were sent to us.
                                </p>
                                <p>
                                    (4) Similarly, we reserve the right to refuse to service items that we feel will be hazardous to
                                    our staff, such as items heavily soiled with human or animal waste. This is for the health and
                                    safety of our team.
                                </p>
                                <p>
                                    (5) For garments that need to be specially treated i.e. leather, silk, cashmere, fur, velvet and
                                    other delicate garments, please ensure that these items can be machine washed and tumble
                                    dried.
                                </p>
                                <p>
                                    (6) All laundering processes, regardless of how gentle will cause normal wear and tear on
                                    clothing. As a result, we will not be responsible for any wear and tear of laundry, including loss
                                    of buttons, fading, fatiguing, holes, or abrasions.
                                </p>
                                <p>
                                    (7) In the unlikely event of loss or damage to an item, we will pay compensation in line with
                                    standard industry guidelines. Our total liability to you in respect of each item is limited to ten
                                    times the price we charge for our services.
                                </p>
                                <p>
                                    (8) We will not be responsible for any single item valued at more than RM 1000 unless we
                                    have received (and acknowledged).
                                </p>
                                <p>
                                    (9) Ordered service cannot be cancelled & non-refundable.
                                </p>
                            </ol>
                        </div>
                    </div>
                ) : (
                    <div className="text-center mt-4 mb-5 px-5">
                        <h3>{laundroText()} Loyalty Terms and Conditions</h3>
                        <div className="text-justify">
                            <p><small>Last updated: 31 March 2021</small></p>

                            <div className="mt-5">
                                <h5 className="text-left">AGREEMENT TO TERMS</h5>
                                {REACT_APP_STORETYPE === "cuci" ? <p>These Terms and Conditions constitute a legally binding agreement made between you, whether personally or on behalf of an entity (“you”) and Klean And Shine Sdn. Bhd. (“we,” “us” or “our”), concerning your access to and use of the Cuci Express Loyalty Web application as well as any other media form, media channel, mobile website or web application related, linked, or otherwise connected thereto (collectively, the “App”).</p> : null}
                                <p>You agree that by accessing the App, you have read, understood, and agree to be bound by all of these Terms and Conditions. If you do not agree with all of these Terms and Conditions, then you are expressly prohibited from using the App and you must discontinue use immediately.</p>
                                <p>Supplemental terms and conditions or documents that may be posted on the App from time to time are hereby expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make changes or modifications to these Terms and Conditions at any time and for any reason.</p>
                                <p>We will alert you about any changes by updating the “Last updated” date of these Terms and Conditions, and you waive any right to receive specific notice of each such change.</p>
                                <p>It is your responsibility to periodically review these Terms and Conditions to stay informed of updates. You will be subject to, and will be deemed to have been made aware of and to have accepted, the changes in any revised Terms and Conditions by your continued use of the App after the date such revised Terms and Conditions are posted.</p>
                                <p>The information provided on the App is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within such jurisdiction or country.</p>
                                <p>Accordingly, those persons who choose to access the App from other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable.</p>
                            </div>

                            <div className="mt-5">
                                <h5 className="text-left">INTELLECTUAL PROPERTY RIGHTS</h5>
                                <p>Unless otherwise indicated, the App is our proprietary property and all databases, software, website designs, audio, video, text, photographs, and graphics on the App (collectively, the “Content”) and the trademarks, service marks, and logos contained therein (the “Marks”) are owned or controlled by us or licensed to us, and are protected by copyright and trademark laws and various other intellectual property rights.</p>
                                <p>The Content and the Marks are provided on the App “AS IS” for your information and personal use only. Except as expressly provided in these Terms and Conditions, no part of the App and no Content or Marks may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose whatsoever, without our express prior written permission.</p>
                                <p>Provided that you are eligible to use the App, you are granted a limited license to access and use the App and to download or print a copy of any portion of the Content to which you have properly gained access solely for your personal, non-commercial use. We reserve all rights not expressly granted to you in and to the App, the Content and the Marks.</p>
                            </div>

                            <div className="mt-5">
                                <h5 className="text-left">USER REPRESENTATIONS</h5>
                                <p>By using the App, you represent and warrant that:</p>
                                <p>(1) all registration information you submit will be true, accurate, current, and complete; (2) you will maintain the accuracy of such information and promptly update such registration information as necessary;</p>
                                <p>(2) you have the legal capacity and you agree to comply with these Terms and Conditions;</p>
                                <p>(3) you will not access the App through automated or non-human means, whether through a bot, script, or otherwise;</p>
                                <p>(4) you will not use the App for any illegal or unauthorized purpose;</p>
                                <p>(5) your use of the App will not violate any applicable law or regulation.</p>
                                <p>If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to suspend or terminate your account and refuse any and all current or future use of the App (or any portion thereof).</p>
                            </div>

                            <div className="mt-5">
                                <h5 className="text-left">USER REGISTRATION</h5>
                                <p>You may be required to register with the App. You agree to keep your password confidential and will be responsible for all use of your account and password. We reserve the right to remove, reclaim, or change a username you select if we determine, in our sole discretion, that such username is inappropriate, obscene, or otherwise objectionable.</p>
                            </div>

                            <div className="mt-5">
                                <h5 className="text-left">PROHIBITED ACTIVITIES</h5>
                                <p>You may not access or use the App for any purpose other than that for which we make the App available. The App may not be used in connection with any commercial endeavors except those that are specifically endorsed or approved by us.</p>
                                <p>As a user of the App, you agree not to:</p>
                                <ol>
                                    <li>systematically retrieve data or other content from the App to create or compile, directly or indirectly, a collection, compilation, database, or directory without written permission from us.</li>
                                    <li>make any unauthorized use of the App, including collecting usernames and/or email addresses of users by electronic or other means for the purpose of sending unsolicited email, or creating user accounts by automated means or under false pretenses.</li>
                                    <li>use a buying agent or purchasing agent to make purchases on the App.</li>
                                    <li>use the App to advertise or offer to sell goods and services.</li>
                                    <li>circumvent, disable, or otherwise interfere with security-related features of the App, including features that prevent or restrict the use or copying of any Content or enforce limitations on the use of the App and/or the Content contained therein.</li>
                                    <li>engage in unauthorized framing of or linking to the App.</li>
                                    <li>trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information such as user passwords;</li>
                                    <li>make improper use of our support services or submit false reports of abuse or misconduct.</li>
                                    <li>engage in any automated use of the system, such as using scripts to send comments or messages, or using any data mining, robots, or similar data gathering and extraction tools.</li>
                                    <li>interfere with, disrupt, or create an undue burden on the App or the networks or services connected to the App.</li>
                                    <li>attempt to impersonate another user or person or use the username of another user.</li>
                                    <li>sell or otherwise transfer your profile.</li>
                                    <li>use the App as part of any effort to compete with us or otherwise use the App and/or the Content for any revenue-generating endeavor or commercial enterprise.</li>
                                    <li>decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way making up a part of the App.</li>
                                    <li>attempt to bypass any measures of the App designed to prevent or restrict access to the App, or any portion of the App.</li>
                                    <li>harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing any portion of the App to you.</li>
                                    <li>delete the copyright or other proprietary rights notice from any Content.</li>
                                    <li>copy or adapt the App’s software, including but not limited to Flash, PHP, HTML, JavaScript, or other code.</li>
                                    <li>upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material, including excessive use of capital letters and spamming (continuous posting of repetitive text), that interferes with any party’s uninterrupted use and enjoyment of the App or modifies, impairs, disrupts, alters, or interferes with the use, features, functions, operation, or maintenance of the App.</li>
                                    <li>upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or active information collection or transmission mechanism, including without limitation, clear graphics interchange formats (“gifs”), 1×1 pixels, web bugs, cookies, or other similar devices (sometimes referred to as “spyware” or “passive collection mechanisms” or “pcms”).</li>
                                    <li>disparage, tarnish, or otherwise harm, in our opinion, us and/or the App.</li>
                                    <li>use the App in a manner inconsistent with any applicable laws or regulations.</li>
                                </ol>
                            </div>

                            <div className="mt-5">
                                <h5 className="text-left">CONTRIBUTION LICENSE</h5>
                                <p>By posting your Contributions to any part of the App, you automatically grant, and you represent and warrant that you have the right to grant, to us an unrestricted, unlimited, irrevocable, perpetual, non-exclusive, transferable, royalty-free, fully-paid, worldwide right, and license to host, use, copy, reproduce, disclose, sell, resell, publish, broadcast, retitle, archive, store, cache, publicly perform, publicly display, reformat, translate, transmit, excerpt (in whole or in part), and distribute such Contributions (including, without limitation, your image and voice) for any purpose, commercial, advertising, or otherwise, and to prepare derivative works of, or incorporate into other works, such Contributions, and grant and authorize sublicenses of the foregoing. The use and distribution may occur in any media formats and through any media channels.</p>
                                <p>This license will apply to any form, media, or technology now known or hereafter developed, and includes our use of your name, company name, and franchise name, as applicable, and any of the trademarks, service marks, trade names, logos, and personal and commercial images you provide. You waive all moral rights in your Contributions, and you warrant that moral rights have not otherwise been asserted in your Contributions.</p>
                                <p>We do not assert any ownership over your Contributions. You retain full ownership of all of your Contributions and any intellectual property rights or other proprietary rights associated with your Contributions. We are not liable for any statements or representations in your Contributions provided by you in any area on the App.</p>
                                <p>You are solely responsible for your Contributions to the App and you expressly agree to exonerate us from any and all responsibility and to refrain from any legal action against us regarding your Contributions.</p>
                                <p>We have the right, in our sole and absolute discretion, (1) to edit, redact, or otherwise change any Contributions; (2) to re-categorize any Contributions to place them in more appropriate locations on the App; and (3) to pre-screen or delete any Contributions at any time and for any reason, without notice. We have no obligation to monitor your Contributions.</p>
                            </div>

                            <div className="mt-5">
                                <h5 className="text-left">GUIDELINES FOR REVIEWS</h5>
                                <p>We may provide you areas on the App to leave reviews or ratings. When posting a review, you must comply with the following criteria:</p>
                                <p>(1) you should have firsthand experience with the person/entity being reviewed;</p>
                                <p>(2) your reviews should not contain offensive profanity, or abusive, racist, offensive, or hate language;</p>
                                <p>(3) your reviews should not contain discriminatory references based on religion, race, gender, national origin, age, marital status, sexual orientation, or disability;</p>
                                <p>(4) your reviews should not contain references to illegal activity;</p>
                                <p>(5) you should not be affiliated with competitors if posting negative reviews;</p>
                                <p>(6) you should not make any conclusions as to the legality of conduct;</p>
                                <p>(7) you may not post any false or misleading statements;</p>
                                <p>(8) you may not organize a campaign encouraging others to post reviews, whether positive or negative.</p>
                                <p>We may accept, reject, or remove reviews in our sole discretion. We have absolutely no obligation to screen reviews or to delete reviews, even if anyone considers reviews objectionable or inaccurate. Reviews are not endorsed by us, and do not necessarily represent our opinions or the views of any of our affiliates or partners.</p>
                                <p>We do not assume liability for any review or for any claims, liabilities, or losses resulting from any review. By posting a review, you hereby grant to us a perpetual, non-exclusive, worldwide, royalty-free, fully-paid, assignable, and sublicensable right and license to reproduce, modify, translate, transmit by any means, display, perform, and/or distribute all content relating to reviews.</p>
                            </div>

                            <div className="mt-5">
                                <h5 className="text-left">WEB APPLICATION LICENSE</h5>
                                <h6>Use License</h6>
                                <p>If you access the App via a web application, then we grant you a revocable, non-exclusive, non-transferable, limited right to install and use the web application on wireless electronic devices owned or controlled by you, and to access and use the web application on such devices strictly in accordance with the terms and conditions of this web application license contained in these Terms and Conditions.</p>
                                <p>You shall not:</p>
                                <p>(1) decompile, reverse engineer, disassemble, attempt to derive the source code of, or decrypt the application;</p>
                                <p>(2) make any modification, adaptation, improvement, enhancement, translation, or derivative work from the application;</p>
                                <p>(3) violate any applicable laws, rules, or regulations in connection with your access or use of the application;</p>
                                <p>(4) remove, alter, or obscure any proprietary notice (including any notice of copyright or trademark) posted by us or the licensors of the application;</p>
                                <p>(5) use the application for any revenue generating endeavor, commercial enterprise, or other purpose for which it is not designed or intended;</p>
                                <p>(6) make the application available over a network or other environmental permitting access or use by multiple devices or users at the same time;</p>
                                <p>(7) use the application for creating a product, service, or software that is, directly or indirectly, competitive with or in any way a substitute for the application;</p>
                                <p>(8) use the application to send automated queries to any website or to send any unsolicited commercial e-mail;</p>
                                <p>(9) use any proprietary information or any of our interfaces or our other intellectual property in the design, development, manufacture, licensing, or distribution of any applications, accessories, or devices for use with the application.</p>
                            </div>

                            <div className="mt-5">
                                <h5 className="text-left">SUBMISSIONS</h5>

                                <p>You acknowledge and agree that any questions, comments, suggestions, ideas, feedback, or other information regarding the App (“Submissions”) provided by you to us are non-confidential and shall become our sole property. We shall own exclusive rights, including all intellectual property rights, and shall be entitled to the unrestricted use and dissemination of these Submissions for any lawful purpose, commercial or otherwise, without acknowledgment or compensation to you.</p>
                                <p>You hereby waive all moral rights to any such Submissions, and you hereby warrant that any such Submissions are original with you or that you have the right to submit such Submissions. You agree there shall be no recourse against us for any alleged or actual infringement or misappropriation of any proprietary right in your Submissions.</p>
                            </div>

                            <div className="mt-5">
                                <h5 className="text-left">APPLICATION MANAGEMENT</h5>
                                <p>We reserve the right, but not the obligation, to:</p>
                                <p>(1) monitor the App for violations of these Terms and Conditions;</p>
                                <p>(2) take appropriate legal action against anyone who, in our sole discretion, violates the law or these Terms and Conditions, including without limitation, reporting such user to law enforcement authorities;</p>
                                <p>(3) in our sole discretion and without limitation, refuse, restrict access to, limit the availability of, or disable (to the extent technologically feasible) any of your Contributions or any portion thereof;</p>
                                <p>(4) in our sole discretion and without limitation, notice, or liability, to remove from the App or otherwise disable all files and content that are excessive in size or are in any way burdensome to our systems;</p>
                                <p>(5) otherwise manage the App in a manner designed to protect our rights and property and to facilitate the proper functioning of the App.</p>
                            </div>

                            <div className="mt-5">
                                <h5 className="text-left">PRIVACY POLICY</h5>
                                <p>We care about data privacy and security. Please review our Privacy Policy posted on the App. By using the App, you agree to be bound by our Privacy Policy, which is incorporated into these Terms and Conditions.</p>
                            </div>

                            <div className="mt-5">
                                <h5 className="text-left">COPYRIGHT INFRINGEMENTS</h5>
                                <p>We respect the intellectual property rights of others. If you believe that any material available on or through the App infringes upon any copyright you own or control, please immediately notify us using the contact information provided below (a “Notification”). A copy of your Notification will be sent to the person who posted or stored the material addressed in the Notification.</p>
                                <p>Please be advised that pursuant to federal law you may be held liable for damages if you make material misrepresentations in a Notification. Thus, if you are not sure that material located on or linked to by the App infringes your copyright, you should consider first contacting an attorney.]</p>
                            </div>

                            <div className="mt-5">
                                <h5 className="text-left">TERM AND TERMINATION</h5>
                                <p>These Terms and Conditions shall remain in full force and effect while you use the App. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS AND CONDITIONS, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF the App (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE TERMS AND CONDITIONS OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN the App OR DELETE YOUR ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.</p>
                                <p>If we terminate or suspend your account for any reason, you are prohibited from registering and creating a new account under your name, a fake or borrowed name, or the name of any third party, even if you may be acting on behalf of the third party.</p>
                                <p>In addition to terminating or suspending your account, we reserve the right to take appropriate legal action, including without limitation pursuing civil, criminal, and injunctive redress.</p>
                            </div>

                            <div className="mt-5">
                                <h5 className="text-left">MODIFICATIONS AND INTERRUPTIONS</h5>
                                <p>We reserve the right to change, modify, or remove the contents of the App at any time or for any reason at our sole discretion without notice. However, we have no obligation to update any information on our Site. We also reserve the right to modify or discontinue all or part of the App without notice at any time.</p>
                                <p>We will not be liable to you or any third party for any modification, price change, suspension, or discontinuance of the App.</p>
                                <p>We cannot guarantee the App will be available at all times. We may experience hardware, software, or other problems or need to perform maintenance related to the App, resulting in interruptions, delays, or errors.</p>
                                <p>We reserve the right to change, revise, update, suspend, discontinue, or otherwise modify the App at any time or for any reason without notice to you. You agree that we have no liability whatsoever for any loss, damage, or inconvenience caused by your inability to access or use the App during any downtime or discontinuance of the App.</p>
                                <p>Nothing in these Terms and Conditions will be construed to obligate us to maintain and support the App or to supply any corrections, updates, or releases in connection therewith.</p>
                            </div>

                            <div className="mt-5">
                                <h5 className="text-left">CORRECTIONS</h5>
                                <p>There may be information on the App that contains typographical errors, inaccuracies, or omissions that may relate to the App, including descriptions, pricing, availability, and various other information. We reserve the right to correct any errors, inaccuracies, or omissions and to change or update the information on the App at any time, without prior notice.</p>
                            </div>

                            <div className="mt-5">
                                <h5 className="text-left">DISCLAIMER</h5>
                                <p>The App IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF The App AND OUR SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH the App AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF the App’S CONTENT OR THE CONTENT OF ANY WEBSITES LINKED TO the App AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF the App, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM the App, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH the App BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA the App. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH the App, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR WEB APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.</p>
                                <p>AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.</p>
                            </div>

                            <div className="mt-5">
                                <h5 className="text-left">LIMITATIONS OF LIABILITY</h5>
                                <p>IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF the App, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>
                            </div>

                            <div className="mt-5">
                                <h5 className="text-left">INDEMNIFICATION</h5>
                                <p>You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our respective officers, agents, partners, and employees, from and against any loss, damage, liability, claim, or demand, including reasonable attorneys’ fees and expenses, made by any third party due to or arising out of: (1) your Contributions; (2) use of the App; (3) breach of these Terms and Conditions; (4) any breach of your representations and warranties set forth in these Terms and Conditions; (5) your violation of the rights of a third party, including but not limited to intellectual property rights; or (6) any overt harmful act toward any other user of the App with whom you connected via the App.</p>
                                <p>Notwithstanding the foregoing, we reserve the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to cooperate, at your expense, with our defense of such claims. We will use reasonable efforts to notify you of any such claim, action, or proceeding which is subject to this indemnification upon becoming aware of it.</p>
                            </div>

                            <div className="mt-5">
                                <h5 className="text-left">USER DATA</h5>
                                <p>We will maintain certain data that you transmit to the App for the purpose of managing the App, as well as data relating to your use of the App. Although we perform regular routine backups of data, you are solely responsible for all data that you transmit or that relates to any activity you have undertaken using the App.</p>
                                <p>You agree that we shall have no liability to you for any loss or corruption of any such data, and you hereby waive any right of action against us arising from any such loss or corruption of such data.</p>
                            </div>

                            <div className="mt-5">
                                <h5 className="text-left">MISCELLANEOUS</h5>
                                <p>These Terms and Conditions and any policies or operating rules posted by us on the App constitute the entire agreement and understanding between you and us. Our failure to exercise or enforce any right or provision of these Terms and Conditions shall not operate as a waiver of such right or provision.</p>
                                <p>These Terms and Conditions operate to the fullest extent permissible by law. We may assign any or all of our rights and obligations to others at any time. We shall not be responsible or liable for any loss, damage, delay, or failure to act caused by any cause beyond our reasonable control.</p>
                                <p>If any provision or part of a provision of these Terms and Conditions is determined to be unlawful, void, or unenforceable, that provision or part of the provision is deemed severable from these Terms and Conditions and does not affect the validity and enforceability of any remaining provisions.</p>
                                <p>There is no joint venture, partnership, employment or agency relationship created between you and us as a result of these Terms and Conditions or use of the App. You agree that these Terms and Conditions will not be construed against us by virtue of having drafted them.</p>
                                <p>You hereby waive any and all defenses you may have based on the electronic form of these Terms and Conditions and the lack of signing by the parties hereto to execute these Terms and Conditions.</p>
                            </div>

                            {
                                REACT_APP_STORETYPE === "cuci" ? (
                                    <div className="mt-5">
                                        <h5 className="text-left">CONTACT US</h5>
                                        <p>In order to resolve a complaint regarding the App or to receive further information regarding use of the App, please contact us at:</p>
                                        <p>Klean and shine sdn bhd <br /> Lot 53, Jln utas 15/7 section 15,<br /> 40000 Shah Alam</p>
                                        <p>0129278893</p>
                                        <p>Estherliow88@gmail.com</p>
                                    </div>
                                ) : null
                            }
                        </div>
                    </div>
                )
            }
        </div>
    );
};


export default TermsConditions;