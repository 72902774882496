import { ProSidebar, Menu, SubMenu, MenuItem, SidebarHeader, SidebarContent } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";

import { connect } from "react-redux";
import { Link } from "react-router-dom";
import viotLogo from '../../../assets/images/logo/ViotLogo.png';

const Sidebar = ({ toggled, setToggled, themeLogo, admin }) => {

	// console.log(admin)
	return (
		<ProSidebar breakPoint="xl" toggled={toggled} onToggle={val => setToggled(val)} id="react-pro-sidebar">
			<SidebarHeader id="react-pro-sidebar-header">
				<img src={viotLogo} alt="logo" className="img-fluid" />
			</SidebarHeader>

			<SidebarContent id="react-pro-sidebar-content">
				<Menu id="react-pro-sidebar-menu" iconShape="circle" popperArrow={true}>
					<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="fas fa-home"></i>}>
						Dashboard <Link to="/admin/dashboard" />
					</MenuItem>
					{
						admin && (admin.store_performance || admin.user_performance || admin.voucher_redemption || admin.token_report || admin.sms_report) ? (
							<SubMenu title="Report" icon={<i className="fas fa-file-invoice-dollar"></i>}>
								{/* {
									admin.store_performance ? (
										<MenuItem>
											Store Performance <Link to="/admin/outlet/performance" />
										</MenuItem>
									) : <></>
								} */}
								{
									admin.user_performance ? (
										<MenuItem>
											User Performance <Link to="/admin/user/performance" />
										</MenuItem>
									) : <></>
								}
								{
									admin.feedback_report ? (
										<MenuItem>
											Feedback Report <Link to="/admin/feedback/report" />
										</MenuItem>
									) : <></>
								}
								{
									admin.voucher_redemption ? (
										<MenuItem>
											Voucher Report <Link to="/admin/voucher/redemption" />
										</MenuItem>
									) : <></>
								}	
								{/* {
									admin.token && admin.token_report ? (
										<MenuItem>
											Token Report <Link to="/admin/token/report" />
										</MenuItem>
									) : <></>
								} */}
								{
									process.env.REACT_APP_STORETYPE !== "cuci" && admin.sms_report ? (
										<MenuItem>
											SMS Report <Link to="/admin/sms/report" />
										</MenuItem>
									) : <></>
								}
							</SubMenu>
						) : <></>
					}

					{
						admin && admin.userTypeSetting ? (
							<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="fas fa-user-cog"></i>}>
								User Type Setting <Link to="/admin/userType/setting" />
							</MenuItem>
						) : <></>
					}

					{/* {
						admin && admin.user_reward ? (
							<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="fas fa-gift"></i>}>
								User Rewards <Link to="/admin/giveaway" />
							</MenuItem>
						) : <></>
					} */}

					{
						admin && admin.token_setting ? (
							<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="fas fa-coins"></i>}>
								Point Setting <Link to="/admin/token/setting" />
							</MenuItem>
						) : <></>
					}

					{
						admin && admin.credit_setting ? (
							<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="fas fa-wallet"></i>}>
								Credit Setting <Link to="/admin/creditSetting" />
							</MenuItem>
						) : <></>
					}

					{
						admin && admin.voucher_setting ? (
							<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="fas fa-cogs"></i>}>
								Voucher Setting <Link to="/admin/voucher/setting" />
							</MenuItem>
						) : <></>
					}

					{
						admin && admin.referral_setting ? (
							<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="fas fa-user-friends"></i>}>
								Referral Setting <Link to="/admin/referral" />
							</MenuItem>
						) : <></>
					}

					{
						admin && admin.discount_code ? (
							<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="fas fa-percent"></i>}>
								Discount Code <Link to="/admin/discountCode" />
							</MenuItem>
						) : <></>
					}

					{
						process.env.REACT_APP_STORETYPE !== "cuci" && admin && admin.customize_color ? (
							<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="fas fa-palette"></i>}>
								Customize Color <Link to="/admin/setting/customize" />
							</MenuItem>
						) : <></>
					}

					{
						admin && admin.faq_setting ? (
							<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="fas fa-question-circle"></i>}>
								FAQs <Link to="/admin/faq" />
							</MenuItem>
						) : <></>
					}

					{
						admin && admin.news_setting ? (
							<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="fas fa-newspaper"></i>}>
								News <Link to="/admin/news" />
							</MenuItem>
						) : <></>
					}

					{/* <SubMenu defaultOpen={false} title="Outlets" icon={<i className="fas fa-cogs"></i>}>
                        {renderOutlets()}
                    </SubMenu> */}
				</Menu>
			</SidebarContent>
		</ProSidebar>
	);
};

const mapStateToProps = state => {
	return {
		themeLogo: state.theme.logo,
		admin: state.admin.admin
	};
};

export default connect(mapStateToProps, null)(Sidebar);
