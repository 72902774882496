import { connect } from 'react-redux';
import { changePage } from '../../../redux/actions/userActions';
import { Link, useHistory, useLocation } from 'react-router-dom';
import join from '../../../assets/images/logo/join.png';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { useTranslation } from 'react-i18next';
// import { useEffect } from 'react';

const mySwal = withReactContent(Swal);

const Check = ({ change, showButton, operatorId, machine }) => {

    const history = useHistory();
    const location = useLocation();
    const { t } = useTranslation();

    const checkOperator = e => {
        e.preventDefault();

        axios.post('/api/user/auth/checkDisable', { operatorId: operatorId })
            .then(res => {
                if (machine.outletStatus === "offline") {
                    mySwal.fire(t("Outlet is currently offline, please try again later"), "", "info")
                } else if (!machine.priceData && machine.type !== "Vending Machine") {
                    mySwal.fire(t("Price is not set, please contact the admin"), "", "info")
                } else {
                    history.push(`/user/payment${location.search}`);
                }
            })
            .catch(err => {
                mySwal.fire(t("Error"), t(err.response.data.error), "error")
            });
    }

    return (
        <div className="px-5 mt-5">
            <div className="text-center mb-4">
                <h3 className="font-weight-bold m-0 p-0">{t("WELCOME")}</h3>
                <img src={join} alt="join banner" className="img-fluid" />
            </div>
            <div>
                <button id="register" onClick={() => change("register")} className="big-button form-control btn mb-2">{t("REGISTER")}</button>
                <button id="login" onClick={() => change("login")} className="big-button form-control btn mb-2">{t("LOGIN")}</button>
                {
                    showButton
                        ? <Link id="skip-to-payment" to="/user/payment" className="big-button-alt form-control btn mb-2" onClick={checkOperator}> - {t('SKIP TO PAYMENT')} - </Link>
                        : null
                }
                <div className="text-center mt-3">
                    <p id="view-our-guideline" onClick={() => history.push('/faq')}>
                        {t("View our guideline")}
                    </p>
                </div>
            </div>
        </div>
    )
};

const mapStateToProps = state => {
    return {
        operatorId: state.user.operatorId,
        machine: state.payment.machine
    };
};

const mapDispatchToProps = dispatch => {
    return {
        change: page => dispatch(changePage(page)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Check);