import { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import axios from "axios";
import ReloadList from "./reload/ReloadList";
import PurchaseHistory from "./reload/PurchaseHistory";
import { useTranslation } from 'react-i18next';
import sha1 from "js-sha1";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const apiUrl = process.env.REACT_APP_API_URL;
const environment = process.env.REACT_APP_ENVIRONMENT;

const mySwal = withReactContent(Swal);

const Reload = ({ history, user, token }) => {
	const [selected/*, setSelect*/] = useState("reload");
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState(null);
	const [kiple, setKiple] = useState(false);
	const [kipleId, setKipleId] = useState(null);
	const [kipleHashValue, setKipleHashValue] = useState(null);
	const [amount, setAmount] = useState("");
	const [transId, setTransId] = useState("");

	const kipleButtonClick = useRef();

	const { t } = useTranslation();

	useEffect(() => {
		if (!user) history.push("/");
		else
			axios
				.get(`/api/token/fetchPackages?operatorId=${user.store}`, { headers: { "Content-Type": "application/json", "auth-token": token } })
				.then(res => {
					setData(res.data.tokenPackages);
					setKiple(res.data.kiple);
					setLoading(false);
				})
				.catch(err => {
					mySwal.fire("Error", err.response.data.error, "error");
					setLoading(false);
				});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (kipleHashValue) {
			kipleButtonClick.current.click();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [kipleHashValue]);

	// const selectColor = val => (val === selected ? "selected-button" : null);

	const handleSubmit = pkg => {
		mySwal
			.fire({
				title: t("Buy this package?"),
				html: `
                ${t("Purchase")} ${pkg.token} ${t("e-Tokens for")} RM ${parseFloat(pkg.price).toFixed(2)}?
            `,
				icon: "question",
				showCancelButton: true,
				showDenyButton: kiple,
				cancelButtonText: t("Cancel"),
				confirmButtonText: t("Purchase"),
				denyButtonText: t("Pay with kiplePay"),
				cancelButtonColor: "#d33",
				denyButtonColor: "#67b668",
				customClass: {
					confirmButton: 'purchase-token',
					denyButton: "kiplepay"
				},
			})
			.then(click => {
				if (click.isConfirmed && click.value) {
					// pay with revenue monster
					const userId = user.id;
					const pkgId = pkg.id;
					const sendThis = {
						userId,
						pkgId
					};
					setLoading(true);
					axios
						.post("/api/token/purchase", sendThis, { headers: { "Content-Type": "application/json", "auth-token": token } })
						.then(res => {
							setLoading(false);
							if (!res.data.data) {
								history.push("/");
							} else {
								window.location.href = res.data.data;
							}
						})
						.catch(err => {
							mySwal.fire("Error", err.response.data.error, "error");
							setLoading(false);
						});
				} else if (click.isDenied) {
					// pay with kiplepay
					let ord_totalamt = parseFloat(pkg.price).toFixed(2)
					setAmount(ord_totalamt)
					setLoading(true);
					axios
						.post("/api/token/kiplePurchase", { userId: user.id, pkgId: pkg.id }, { headers: { "Content-Type": "application/json", "auth-token": token } })
						.then(res => {
							setKipleId(res.data.kipleId);
							setTransId(res.data.purchaseId);

							let amount = ord_totalamt.replace('.', '')
							// sha1($merchant_secret . $ord_mercID . $ord_mercref . $amount);
							let merchant_hashvalue = sha1(`${res.data.kipleSecret}.${res.data.kipleId}.${res.data.purchaseId}.${amount}`)
							if (environment === "development") {
								merchant_hashvalue = sha1(`123456.80000155.${res.data.purchaseId}.${amount}`)
							}
							setKipleHashValue(merchant_hashvalue);
							setLoading(false);
						})
						.catch(err => {
							mySwal.fire("Error", err.response.data.error, "error");
							setLoading(false);
						});
				}
			});
	};

	const renderPage = selected === "reload" ? <ReloadList data={data} loading={loading} handleSubmit={handleSubmit} /> : <PurchaseHistory purchaseHistory={user.tokenPurchaseHistory} />;

	return (
		<div id="reload-page">
			<div id="user-header">
				<h5>
					<i onClick={e => history.push("/")} className="fas fa-chevron-left me-3"></i> <strong>{t("Reload Tokens")}</strong>
				</h5>

				<div id="token-info-container">
					<div id="token-image-container">
						<i className="fas fa-coins"></i>
					</div>

					<div id="token-info">
						<h3>{user?.name || ""}</h3>
						<h6>
							{t("Current e-Token")}: <span id="user-token">{user?.token || 0}</span>
						</h6>
					</div>
				</div>
			</div>

			<div id="user-body" className="mt-5">
				{/* <div id="type-selector">
					<button id="reload" onClick={e => setSelect("reload")} className={selectColor("reload")}>
						{t("Reload")}
					</button>
					<button id="history" onClick={e => setSelect("history")} className={selectColor("history")}>
						{t("History")}
					</button>
				</div> */}

				<div /*className="mt-3"*/>{renderPage}</div>
			</div>

			{
				kipleId ? (
					<div>
						<form action={environment === "development" ? "https://uat.kiplepay.com/wcgatewayinit.php" : "https://kiplepay.com/wcgatewayinit.php"} method="post">
							<input type="hidden" name="ord_date" value={new Date().toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' })} />
							<input type="hidden" name="ord_totalamt" value={amount} />
							<input type="hidden" name="ord_gstamt" value="0.00" />
							<input type="hidden" name="ord_shipname" value={user.name} />
							<input type="hidden" name="ord_shipcountry" value="Malaysia" />
							<input type="hidden" name="ord_mercref" value={transId} />
							<input type="hidden" name="ord_telephone" value={user.number} />
							{user.email ? <input type="hidden" name="ord_email" value={user.email} /> : null}
							<input type="hidden" name="ord_delcharges" value="0.00" />
							<input type="hidden" name="ord_svccharges" value="0.00" />
							<input type="hidden" name="ord_mercID" value={environment === "development" ? "80000155" : kipleId} />
							<input type="hidden" name="version" value="2.0" />
							<input type="hidden" name="merchant_hashvalue" value={kipleHashValue} />
							<input type="hidden" name="ord_returnURL" value={apiUrl + `/api/token/kiplePayment/success`} />

							<input type="submit" name="submit" value="Pay with kiplePay" hidden ref={kipleButtonClick} />
						</form>
					</div>
				) : null
			}
		</div>
	);
};

const mapStateToProps = state => {
	return {
		user: state.user.user,
		token: state.user.token
	};
};

export default connect(mapStateToProps, null)(Reload);
