import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import Sidebar from "../../components/Sidebar";
import Topbar from "../../components/Topbar";
import Loading from "../../components/Loading";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { MDBDataTable } from "mdbreact";
import { CSVLink } from "react-csv";

import { Modal, Select } from "antd";
const { Option } = Select;

const mySwal = withReactContent(Swal);

class Feedback extends Component {
	constructor(props) {
		super(props);
		this.state = {
			toggled: false,
			loading: true,
			data: [],
			selectedStatus: ""
		};
	}
	_isMounted = false;

	componentDidMount() {
		this._isMounted = true;
		if (this._isMounted) {
			if (!this.props.admin.isAuthenticated || !this.props.admin.admin) {
				this.props.history.push("/admin/dashboard");
			} else {
				const { operatorId } = this.props.admin.admin;
				axios
					.post("/api/admin/report/feedback", { operatorId }, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
					.then(res => {
						this.setState({
							...this.state,
							loading: false,
							data: res.data
						});
					})
					.catch(err => mySwal.fire("Error", err.response.data.error, "error"));
			}
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	handleToggle = () => this.setState({ ...this.state, toggled: !this.state.toggled });

	handleStatusChange = (value) => {
		this.setState({ selectedStatus: value });
	};

	handleClickOpenModal = (id) => {
		return () => {
			this.openModal(id);
		};
	};

	openModal = (id) => {
		this.setState({ showModal: true, selectedId: id });
	};

	closeModal = () => {
		this.setState({ showModal: false });
	};

	handleUpdateStatus = () => {
		const { selectedStatus, selectedId } = this.state;
		axios
			.post("/api/admin/setting/editUserReview", { selectedStatus, selectedId }, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
			.then(res => {
				if (!res) {
					Swal.fire("Error", "Status updated failed", "error");
				}

				Swal.fire("Success", "Status updated successfully", "success");
				this.fetchData();
			})
			.catch(err => mySwal.fire("Error", err.response.data.error, "error"));
		this.closeModal();
	};

	fetchData = () => {
		const { operatorId } = this.props.admin.admin;
		axios
			.post(
				"/api/admin/report/feedback",
				{ operatorId },
				{
					headers: {
						"Content-Type": "application/json",
						"auth-token": this.props.admin.token
					}
				}
			)
			.then((res) => {
				this.setState({
					...this.state,
					loading: false,
					data: res.data
				});
			})
			.catch((err) => mySwal.fire("Error", err.response.data.error, "error"));
	};

	render() {
		let data = {
			columns: [
				{
					label: "ID",
					field: "id",
					sort: "asc"
				},
				{
					label: "User ID",
					field: "userId",
					sort: "asc"
				},
				{
					label: "Phone Number",
					field: "number",
					sort: "asc"
				},
				{
					label: "Location",
					field: "location",
					sort: "asc"
				},
				{
					label: "Comment",
					field: "comment",
					sort: "asc"
				},
				{
					label: "Created At",
					field: "date",
					sort: "asc"
				},
				{
					label: "Status",
					field: "status",
					sort: "asc"
				},
				{
					label: "Action",
					field: "action"
				}
			],
			rows: []
		};

		let csv = [];

		if (this.state.data.length > 0) {
			for (let i = 0; i < this.state.data.length; i++) {
				const dataObject = {
					id: this.state.data[i].id,
					userId: this.state.data[i].userId,
					number: this.state.data[i].number,
					location: this.state.data[i].location,
					comment: this.state.data[i].comment,
					date: this.state.data[i].createdAt,
					status: this.state.data[i].status,
					action: (
						<div>
							<i className="fas fa-edit fa-lg" onClick={this.handleClickOpenModal(this.state.data[i].id)}></i>
						</div>
					)
				};
				data.rows.push(dataObject);

				const csvDataObject = {
					userId: this.state.data[i].userId,
					number: `=""${this.state.data[i].number}""`,
					location: this.state.data[i].location,
					comment: this.state.data[i].comment,
					date: new Date(this.state.data[i].createdAt).toLocaleString("en-GB")
				}
				csv.push(csvDataObject);
			}
		}

		const csvData = {
			headers: [
				{ label: "User ID", key: "userId" },
				{ label: "Phone Number", key: "number" },
				{ label: "Location", key: "location" },
				{ label: "Feedback", key: "comment" },
				{ label: "Created At", key: "date" }
			],
			data: csv
		};

		return (
			<div className="admin-page-container">
				<Sidebar toggled={this.state.toggled} setToggled={this.handleToggle} />
				<div className="admin-page">
					<Topbar handleToggle={this.handleToggle} />
					<div className="admin-content">
						<div className="card">
							<div className="card-header d-flex justify-content-between">
								<h5 className="card-title">Feedback Report</h5>
								{/* <h4 className="m-0 p-0">Remaining SMS Credit: {this.props.admin?.admin?.smsCredit || 0}</h4> */}
							</div>

							<div className="card-body text-center">
								{
									this.state.loading ? <Loading /> : (
										<div>
											<MDBDataTable data={data} noBottomColumns striped hover responsive bordered />
											<div className="d-grid gap-2 d-md-flex justify-content-md-end">
												<CSVLink className="btn btn-primary" data={csvData.data} headers={csvData.headers} filename="Feedback.csv" target="_blank">
													Download Report
												</CSVLink>
											</div>
										</div>
									)
								}
							</div>
						</div>
					</div>
				</div>
				<Modal
					visible={this.state.showModal}
					onCancel={this.closeModal}
					onOk={this.handleUpdateStatus}
					title="Update Status"
				>
					<div className="form-group">
						<label htmlFor="status">Status:</label>
						<Select
							id="status"
							value={this.state.selectedStatus}
							onChange={this.handleStatusChange}
							style={{ width: "100%" }}
						>
							<Option value="Rejected">Rejected</Option>
							<Option value="On-going">On-going</Option>
							<Option value="Completed">Completed</Option>
						</Select>
					</div>
				</Modal>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		admin: state.admin
	};
};

export default connect(mapStateToProps, null)(Feedback);
