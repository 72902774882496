import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import birthday from "../../../assets/images/news/birthday.png";
import newUser from "../../../assets/images/news/newUser.png";
import referral from "../../../assets/images/news/referral.png";
import stamp from "../../../assets/images/news/stamp.png";
import token from "../../../assets/images/news/token.png";

import i18n from "i18next";

const mySwal = withReactContent(Swal);

class NewsDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            voucherSetting: null,
            referralSetting: null,
            news: []
        };
    };

    componentDidMount() {
        if (this.props.user) {
            axios
                .post("/api/user/getVoucherSetting", { operatorId: 3 })
                .then(res => {
                    this.setState({
                        ...this.state,
                        voucherSetting: res.data.voucherSetting,
                        referralSetting: res.data.referralSetting
                    });

                    axios
                        .post("/api/user/getNews")
                        .then(res => {
                            this.setState({
                                ...this.state,
                                news: res.data,
                                loading: false
                            });
                        })
                        .catch(err => mySwal.fire(i18n.t("Error"), err.response.data.error, "error"));
                })
                .catch(err => mySwal.fire(i18n.t("Error"), err.response.data.error, "error"));
        } else {
            this.props.history.push("/");
        }
    }

    render() {

        const title = () => {
            if (this.props.match.params.newsId === "newUser") {
                return i18n.t("Welcome Gift");
            } else if (this.props.match.params.newsId === "referral") {
                return i18n.t("Share Your Referral Code Now");
            } else if (this.props.match.params.newsId === "birthday") {
                return i18n.t("Birthday Gift for You");
            } else if (this.props.match.params.newsId === "token") {
                return i18n.t("Let's Pay with E-Token");
            } else if (this.props.match.params.newsId === "stamp") {
                return i18n.t("Collect 10 Stamps and Get Voucher");
            } else {
                if (this.state.loading === false) {
                    const news = this.state.news.filter(news => news.id === this.props.match.params.newsId)[0];
                    if (this.props.language === "en") {
                        return news.title;
                    } else if (this.props.language === "ms") {
                        if (news.titleBm) {
                            return news.titleBm;
                        } else {
                            return news.title;
                        }
                    } else if (this.props.language === "zh") {
                        if (news.titleCh) {
                            return news.titleCh;
                        } else {
                            return news.title;
                        }
                    }
                }
            }
        }

        const image = () => {
            if (this.props.match.params.newsId === "newUser") {
                return newUser;
            } else if (this.props.match.params.newsId === "referral") {
                return referral;
            } else if (this.props.match.params.newsId === "birthday") {
                return birthday;
            } else if (this.props.match.params.newsId === "token") {
                return token;
            } else if (this.props.match.params.newsId === "stamp") {
                return stamp;
            } else {
                if (this.state.loading === false) {
                    const news = this.state.news.filter(news => news.id === this.props.match.params.newsId)[0];
                    return news.image;
                }
            }
        }

        const date = () => {
            if (this.props.match.params.newsId !== "newUser" && this.props.match.params.newsId !== "referral" && this.props.match.params.newsId !== "birthday" && this.props.match.params.newsId !== "token" && this.props.match.params.newsId !== "stamp") {
                if (this.state.loading === false) {
                    const news = this.state.news.filter(news => news.id === this.props.match.params.newsId)[0];
                    if (news.startDate && news.endDate) {
                        return (<p><i className="fas fa-calendar"></i> {`${`${new Date(news.startDate).getDate()}/${new Date(news.startDate).getMonth() + 1}/${new Date(news.startDate).getFullYear()}`} - ${`${new Date(news.endDate).getDate()}/${new Date(news.endDate).getMonth() + 1}/${new Date(news.endDate).getFullYear()}`}`} </p>);
                    }
                }
            }
        }

        const description = () => {
            if (this.props.match.params.newsId === "newUser") {
                // new user voucher
                const registerVoucherSetting = this.state.voucherSetting.filter(setting => setting.type === "Register")[0];
                return (
                    <div style={{ textAlign: "justify" }}>
                        <ol>
                            {this.props.user.payAndGetRegVoucher ? (<li>{i18n.t("The voucher will be given if you completed a transaction with a minimum spending of RM")} {this.props.user.regVoucherAmount}.</li>) : <li>{i18n.t("The voucher will be given if you registered your account successfully.")}</li>}
                            {registerVoucherSetting.voucher_expiry ? (<li>{i18n.t("The voucher is valid for ")}{registerVoucherSetting.voucher_duration} {i18n.t(" days from that day the voucher given.")}</li>) : (<li>{i18n.t("There is no expiration date for this voucher.")}</li>)}
                            {registerVoucherSetting.voucher_dryer_use && !registerVoucherSetting.voucher_washer_use ? (<li>{i18n.t("The voucher can be used for dryer machine only.")}</li>) : null}
                            {registerVoucherSetting.voucher_washer_use && !registerVoucherSetting.dryer_use ? (<li>{i18n.t("The voucher can be used for washer machine only.")}</li>) : null}
                            {registerVoucherSetting.voucher_washer_use && registerVoucherSetting.voucher_dryer_use ? (<li>{i18n.t("The voucher can be used for dryer or washer machine.")}</li>) : null}
                            <li>{i18n.t("When you applied the voucher during payment, it will give you")} {registerVoucherSetting.voucher_type === "Flat" ? "RM" + registerVoucherSetting.voucher_amount : registerVoucherSetting.voucher_amount + "%"} {i18n.t("discount.")}</li>
                            <li>{i18n.t("When promotion is on going, the voucher is not applicable.")}</li>
                            {/* <li>{i18n.t("The voucher cannot be used if your payment method is E-Token.")}</li> */}
                            <li>{i18n.t("The voucher is only for customer use only, not for sale.")}</li>
                        </ol>
                    </div>
                );
            } else if (this.props.match.params.newsId === "referral") {
                // referral code voucher
                const referralVoucherSetting = this.state.voucherSetting.filter(setting => setting.type === "Referral")[0];
                return (
                    <div style={{ textAlign: "justify" }}>
                        <br />
                        <ol>
                            <li>{i18n.t("Your referral code can be found in user settings page.")}</li>
                            <li>{i18n.t("When a friend / family register using your referral code, and completed a transaction with a minimum spending of RM")} {this.state.referralSetting.amount_transaction} {i18n.t(", you will be rewarded automatically.")}</li>
                            {this.state.referralSetting.number_of_referral === 999 ? (<li>{i18n.t("All users will have infinite referrals.")}</li>) : (<li>{i18n.t("One user only allow to have")} {this.state.referralSetting.number_of_referral} {i18n.t("referrals.")}</li>)}
                            {this.state.referralSetting.stamp_eligible && this.props.user.stamp ? (<li>{i18n.t("You can get")} {this.state.referralSetting.stamp_combined} {i18n.t("stamp for the reward.")}</li>) : null}
                            {this.state.referralSetting.token_eligible && this.props.user.tokenSetting ? (<li>{i18n.t("You can get")} {this.state.referralSetting.token_amount} {i18n.t("e-token for the reward.")}</li>) : null}
                            {referralVoucherSetting.voucher_eligible ? (<li>{i18n.t("You can get a voucher for the reward")}:
                                <ol style={{ listStyleType: "lower-alpha" }}>
                                    <li>{i18n.t("which have")} {referralVoucherSetting.voucher_type === "Rate" ? referralVoucherSetting.voucher_amount + "%" : "RM" + referralVoucherSetting.voucher_amount} {i18n.t("discount.")}</li>
                                    {referralVoucherSetting.voucher_dryer_use && !referralVoucherSetting.voucher_washer_use ? (<li>{i18n.t("it can be used for dryer machine only.")}</li>) : null}
                                    {!referralVoucherSetting.voucher_dryer_use && referralVoucherSetting.voucher_washer_use ? (<li>{i18n.t("it can be used for washer machine only.")}</li>) : null}
                                    {referralVoucherSetting.voucher_dryer_use && referralVoucherSetting.voucher_washer_use ? (<li>{i18n.t("it can be used for dryer or washer machine.")}</li>) : null}
                                    <li>{referralVoucherSetting.voucher_expiry ? i18n.t("it is valid for ") + referralVoucherSetting.voucher_duration + i18n.t(" days from that day the voucher given.") : i18n.t("There is no expiration date for this voucher.")}</li>
                                    <li>{i18n.t("The voucher is only for customer use only, not for sale.")}</li>
                                </ol>
                            </li>) : null}
                        </ol>
                    </div>
                );
                // if (this.props.user.combinedSetting) {
                //     return (
                //         <div style={{ textAlign: "justify" }}>
                //             <ol>
                //                 <li>{i18n.t("Your referral code can be found in user settings page.")}</li>
                //                 <li>{i18n.t("When a friend / family register using your referral code, and completed a transaction with a minimum spending of RM")} {this.state.referralSetting.amount_transaction} {i18n.t(", you will be rewarded automatically.")}</li>
                //                 {this.state.referralSetting.number_of_referral === 999 ? (<li>{i18n.t("All users will have infinite referrals.")}</li>) : (<li>{i18n.t("One user only allow to have")} {this.state.referralSetting.number_of_referral} {i18n.t("referrals.")}</li>)}
                //                 {this.state.referralSetting.stamp_eligible && this.props.user.stamp ? (<li>{i18n.t("You can get")} {this.state.referralSetting.stamp_combined} {i18n.t("stamp for the reward.")}</li>) : null}
                //                 {this.state.referralSetting.token_eligible && this.props.user.tokenSetting ? (<li>{i18n.t("You can get")} {this.state.referralSetting.token_amount} {i18n.t("e-token for the reward.")}</li>) : null}
                //                 {referralVoucherSetting.voucher_eligible ? (<li>{i18n.t("You can get a voucher for the reward")}:
                //                     <ol style={{ listStyleType: "lower-alpha" }}>
                //                         <li>{i18n.t("which have")} {referralVoucherSetting.voucher_type === "Rate" ? referralVoucherSetting.voucher_amount + "%" : "RM" + referralVoucherSetting.voucher_amount} {i18n.t("discount.")}</li>
                //                         {referralVoucherSetting.voucher_dryer_use && !referralVoucherSetting.voucher_washer_use ? (<li>{i18n.t("it can be used for dryer machine only.")}</li>) : null}
                //                         {!referralVoucherSetting.voucher_dryer_use && referralVoucherSetting.voucher_washer_use ? (<li>{i18n.t("it can be used for washer machine only.")}</li>) : null}
                //                         {referralVoucherSetting.voucher_dryer_use && referralVoucherSetting.voucher_washer_use ? (<li>{i18n.t("it can be used for dryer or washer machine.")}</li>) : null}
                //                         <li>{referralVoucherSetting.voucher_expiry ? i18n.t("it is valid for ") + referralVoucherSetting.voucher_duration + i18n.t(" days from that day the voucher given.") : i18n.t("There is no expiration date for this voucher.")}</li>
                //                         <li>{i18n.t("The voucher is only for customer use only, not for sale.")}</li>
                //                     </ol>
                //                 </li>) : null}
                //             </ol>
                //         </div>
                //     );
                // } else {
                //     if (this.props.user.specificStamp === "washerOnly") {
                //         return (
                //             <div style={{ textAlign: "justify" }}>
                //                 <ol>
                //                     <li>{i18n.t("Your referral code can be found in user settings page.")}</li>
                //                     <li>{i18n.t("When a friend / family register using your referral code, and completed a transaction with a minimum spending of RM")} {this.state.referralSetting.amount_transaction} {i18n.t(", you will be rewarded automatically.")}</li>
                //                     {this.state.referralSetting.number_of_referral === 999 ? (<li>{i18n.t("All users will have infinite referrals.")}</li>) : (<li>{i18n.t("One user only allow to have")} {this.state.referralSetting.number_of_referral} {i18n.t("referrals.")}</li>)}
                //                     {this.state.referralSetting.stamp_eligible && this.props.user.stamp ? (<li>{i18n.t("You can get")} {this.state.referralSetting.stamp_washer} {i18n.t("washer stamp for the reward.")}</li>) : null}
                //                     {this.state.referralSetting.token_eligible && this.props.user.tokenSetting ? (<li>{i18n.t("You can get")} {this.state.referralSetting.token_amount} {i18n.t("e-token for the reward.")}</li>) : null}
                //                     {referralVoucherSetting.voucher_eligible ? (<li>{i18n.t("You can get a voucher for the reward")}:
                //                         <ol style={{ listStyleType: "lower-alpha" }}>
                //                             <li>{i18n.t("which have")} {referralVoucherSetting.voucher_type === "Rate" ? referralVoucherSetting.voucher_amount + "%" : "RM" + referralVoucherSetting.voucher_amount} {i18n.t("discount.")}</li>
                //                             {referralVoucherSetting.voucher_dryer_use && !referralVoucherSetting.voucher_washer_use ? (<li>{i18n.t("it can be used for dryer machine only.")}</li>) : null}
                //                             {!referralVoucherSetting.voucher_dryer_use && referralVoucherSetting.voucher_washer_use ? (<li>{i18n.t("it can be used for washer machine only.")}</li>) : null}
                //                             {referralVoucherSetting.voucher_dryer_use && referralVoucherSetting.voucher_washer_use ? (<li>{i18n.t("it can be used for dryer or washer machine.")}</li>) : null}
                //                             <li>{referralVoucherSetting.voucher_expiry ? i18n.t("it is valid for ") + referralVoucherSetting.voucher_duration + i18n.t(" days from that day the voucher given.") : i18n.t("There is no expiration date for this voucher.")}</li>
                //                             <li>{i18n.t("The voucher is only for customer use only, not for sale.")}</li>
                //                         </ol>
                //                     </li>) : null}
                //                 </ol>
                //             </div>
                //         );
                //     } else if (this.props.user.specificStamp === "dryerOnly") {
                //         return (
                //             <div style={{ textAlign: "justify" }}>
                //                 <ol>
                //                     <li>{i18n.t("Your referral code can be found in user settings page.")}</li>
                //                     <li>{i18n.t("When a friend / family register using your referral code, and completed a transaction with a minimum spending of RM")} {this.state.referralSetting.amount_transaction} {i18n.t(", you will be rewarded automatically.")}</li>
                //                     {this.state.referralSetting.number_of_referral === 999 ? (<li>{i18n.t("All users will have infinite referrals.")}</li>) : (<li>{i18n.t("One user only allow to have")} {this.state.referralSetting.number_of_referral} {i18n.t("referrals.")}</li>)}
                //                     {this.state.referralSetting.stamp_eligible && this.props.user.stamp ? (<li>{i18n.t("You can get")} {this.state.referralSetting.stamp_dryer} {i18n.t("dryer stamp for the reward.")}</li>) : null}
                //                     {this.state.referralSetting.token_eligible && this.props.user.tokenSetting ? (<li>{i18n.t("You can get")} {this.state.referralSetting.token_amount} {i18n.t("e-token for the reward.")}</li>) : null}
                //                     {referralVoucherSetting.voucher_eligible ? (<li>{i18n.t("You can get a voucher for the reward")}:
                //                         <ol style={{ listStyleType: "lower-alpha" }}>
                //                             <li>{i18n.t("which have")} {referralVoucherSetting.voucher_type === "Rate" ? referralVoucherSetting.voucher_amount + "%" : "RM" + referralVoucherSetting.voucher_amount} {i18n.t("discount.")}</li>
                //                             {referralVoucherSetting.voucher_dryer_use && !referralVoucherSetting.voucher_washer_use ? (<li>{i18n.t("it can be used for dryer machine only.")}</li>) : null}
                //                             {!referralVoucherSetting.voucher_dryer_use && referralVoucherSetting.voucher_washer_use ? (<li>{i18n.t("it can be used for washer machine only.")}</li>) : null}
                //                             {referralVoucherSetting.voucher_dryer_use && referralVoucherSetting.voucher_washer_use ? (<li>{i18n.t("it can be used for dryer or washer machine.")}</li>) : null}
                //                             <li>{referralVoucherSetting.voucher_expiry ? i18n.t("it is valid for ") + referralVoucherSetting.voucher_duration + i18n.t(" days from that day the voucher given.") : i18n.t("There is no expiration date for this voucher.")}</li>
                //                             <li>{i18n.t("The voucher is only for customer use only, not for sale.")}</li>
                //                         </ol>
                //                     </li>) : null}
                //                 </ol>
                //             </div>
                //         );
                //     } else if (this.props.user.specificStamp === "both") {
                //         return (
                //             <div style={{ textAlign: "justify" }}>
                //                 <ol>
                //                     <li>{i18n.t("Your referral code can be found in user settings page.")}</li>
                //                     <li>{i18n.t("When a friend / family register using your referral code, and completed a transaction with a minimum spending of RM")} {this.state.referralSetting.amount_transaction} {i18n.t(", you will be rewarded automatically.")}</li>
                //                     {this.state.referralSetting.number_of_referral === 999 ? (<li>{i18n.t("All users will have infinite referrals.")}</li>) : (<li>{i18n.t("One user only allow to have")} {this.state.referralSetting.number_of_referral} {i18n.t("referrals.")}</li>)}
                //                     {this.state.referralSetting.stamp_eligible && this.props.user.stamp ? (<li>{i18n.t("You can get")} {this.state.referralSetting.stamp_washer} {i18n.t("washer stamp and")} {this.state.referralSetting.stamp_dryer} {i18n.t("dryer stamp for the reward.")}</li>) : null}
                //                     {this.state.referralSetting.token_eligible && this.props.user.tokenSetting ? (<li>{i18n.t("You can get")} {this.state.referralSetting.token_amount} {i18n.t("e-token for the reward.")}</li>) : null}
                //                     {referralVoucherSetting.voucher_eligible ? (<li>{i18n.t("You can get a voucher for the reward")}:
                //                         <ol style={{ listStyleType: "lower-alpha" }}>
                //                             <li>{i18n.t("which have")} {referralVoucherSetting.voucher_type === "Rate" ? referralVoucherSetting.voucher_amount + "%" : "RM" + referralVoucherSetting.voucher_amount} {i18n.t("discount.")}</li>
                //                             {referralVoucherSetting.voucher_dryer_use && !referralVoucherSetting.voucher_washer_use ? (<li>{i18n.t("it can be used for dryer machine only.")}</li>) : null}
                //                             {!referralVoucherSetting.voucher_dryer_use && referralVoucherSetting.voucher_washer_use ? (<li>{i18n.t("it can be used for washer machine only.")}</li>) : null}
                //                             {referralVoucherSetting.voucher_dryer_use && referralVoucherSetting.voucher_washer_use ? (<li>{i18n.t("it can be used for dryer or washer machine.")}</li>) : null}
                //                             <li>{referralVoucherSetting.voucher_expiry ? i18n.t("it is valid for ") + referralVoucherSetting.voucher_duration + i18n.t(" days from that day the voucher given.") : i18n.t("There is no expiration date for this voucher.")}</li>
                //                             <li>{i18n.t("The voucher is only for customer use only, not for sale.")}</li>
                //                         </ol>
                //                     </li>) : null}
                //                 </ol>
                //             </div>
                //         );
                //     }
                // }

            } else if (this.props.match.params.newsId === "birthday") {
                // birthday voucher
                const birthdayVoucherSetting = this.state.voucherSetting.filter(setting => setting.type === "Birthday")[0];
                return (
                    <div style={{ textAlign: "justify" }}>
                        <ol>
                            <li>{i18n.t("The birthday voucher will be given on the first day of your birthday month.")}</li>
                            {this.props.user.payAndGetBirthdayVoucher ? (<li>{i18n.t("The voucher only will be given if you have exceed spending amount of RM")} {this.props.user.birthdayVoucherAmount}.</li>) : null}
                            {birthdayVoucherSetting.voucher_expiry ? (<li>{i18n.t("The voucher is valid for ")}{birthdayVoucherSetting.voucher_duration}{i18n.t(" days from that day the voucher given.")}</li>) : (<li>{i18n.t("There is no expiration date for this voucher.")}</li>)}
                            {birthdayVoucherSetting.voucher_dryer_use && !birthdayVoucherSetting.voucher_washer_use ? (<li>{i18n.t("The voucher can be used for dryer machine only.")}</li>) : null}
                            {birthdayVoucherSetting.voucher_washer_use && !birthdayVoucherSetting.voucher_dryer_use ? (<li>{i18n.t("The voucher can be used for washer machine only.")}</li>) : null}
                            {birthdayVoucherSetting.voucher_washer_use && birthdayVoucherSetting.voucher_dryer_use ? (<li>{i18n.t("The voucher can be used for dryer or washer machine.")}</li>) : null}
                            <li>{i18n.t("When you applied the voucher during payment, it will give you")} {birthdayVoucherSetting.voucher_type === "Flat" ? "RM" + birthdayVoucherSetting.voucher_amount : birthdayVoucherSetting.voucher_amount + "%"} {i18n.t("discount.")}</li>
                            <li>{i18n.t("When promotion is on going, the voucher is not applicable.")}</li>
                            {/* <li>{i18n.t("The voucher cannot be used if your payment method is E-Token.")}</li> */}
                            <li>{i18n.t("The voucher is only for customer use only, not for sale.")}</li>
                        </ol>
                    </div>
                );
            } else if (this.props.match.params.newsId === "token") {
                // e-token
                return (
                    <div style={{ textAlign: "justify" }}>
                        <ol>
                            {this.props.user.tokenSetting && this.props.user.epayment ? (<li>{i18n.t('When you click on "PAY" button on the payment page, you can choose either using E-wallet or E-token to pay.')}</li>) : null}
                            {/* <li>{i18n.t("When you using your voucher during payment, you will be unable to pay with E-token.")}</li> */}
                            <li>{this.props.user.stamp ? i18n.t("You can reload your E-token by clicking the reload button in the stamping page and select the package to purchase.") : i18n.t("You can reload your E-token by clicking the reload button in the home page and select the package to purchase.")}</li>
                            <li>{i18n.t("You also can view your token purchase history in transaction page.")}</li>
                        </ol>
                    </div>
                );
            } else if (this.props.match.params.newsId === "stamp") {
                // stamp voucher
                if (this.props.user.combinedSetting) {
                    // if stamp combined
                    const combinedVoucherSetting = this.state.voucherSetting.filter(setting => setting.type === "Combined Stamp")[0];
                    return (
                        <div style={{ textAlign: "justify" }}>
                            <ol>
                                <li>{i18n.t("Using either dryer machine or washer machine with the minimum spending of")} RM{this.props.user.stampTransactionAmount} {i18n.t("to collect one stamp.")}</li>
                                <li>{i18n.t("The voucher will be given if you have collected 10 stamps.")}</li>
                                {combinedVoucherSetting.voucher_expiry ? (<li>{i18n.t("The voucher is valid for ")}{combinedVoucherSetting.voucher_duration}{i18n.t(" days from that day the voucher given.")}</li>) : (<li>{i18n.t("There is no expiration date for this voucher.")}</li>)}
                                {combinedVoucherSetting.voucher_dryer_use && !combinedVoucherSetting.voucher_washer_use ? (<li>{i18n.t("The voucher can be used for dryer machine only.")}</li>) : null}
                                {!combinedVoucherSetting.voucher_dryer_use && combinedVoucherSetting.voucher_washer_use ? (<li>{i18n.t("The voucher can be used for washer machine only.")}</li>) : null}
                                {combinedVoucherSetting.voucher_dryer_use && combinedVoucherSetting.voucher_washer_use ? (<li>{i18n.t("The voucher can be used for dryer or washer machine.")}</li>) : null}
                                <li>{i18n.t("When you applied the voucher during payment, it will give you")} {combinedVoucherSetting.voucher_type === "Flat" ? "RM" + combinedVoucherSetting.voucher_amount : combinedVoucherSetting.voucher_amount + "%"} {i18n.t("discount.")}</li>
                                <li>{i18n.t("When promotion is on going, the voucher is not applicable.")}</li>
                                {/* <li>{i18n.t("The voucher cannot be used if your payment method is E-Token.")}</li> */}
                                <li>{i18n.t("The voucher is only for customer use only, not for sale.")}</li>
                            </ol>
                        </div>
                    );
                } else if (!this.props.user.combinedSetting) {
                    // if stamp separated
                    const washerVoucherSetting = this.state.voucherSetting.filter(setting => setting.type === "Washer Stamp")[0];
                    const dryerVoucherSetting = this.state.voucherSetting.filter(setting => setting.type === "Dryer Stamp")[0];
                    if (dryerVoucherSetting.voucher_eligible && !washerVoucherSetting.voucher_eligible) {
                        // if only give voucher for dryer stamp
                        return (
                            <div style={{ textAlign: "justify" }}>
                                <ol>
                                    {this.props.user.specificStamp === "dryerOnly" || this.props.user.specificStamp === "both" ? (<li>{i18n.t("Using dryer machine with the minimum spending of")} RM{this.props.user.stampTransactionAmount} {i18n.t("to collect one stamp.")}</li>) : null}
                                    <li>{i18n.t("The voucher will be given if you have collected 10 stamps.")}</li>
                                    {dryerVoucherSetting.voucher_expiry ? (<li>{i18n.t("The voucher is valid for ")}{dryerVoucherSetting.voucher_duration}{i18n.t(" days from that day the voucher given.")}</li>) : (<li>{i18n.t("There is no expiration date for this voucher.")}</li>)}
                                    {dryerVoucherSetting.voucher_dryer_use && !dryerVoucherSetting.voucher_washer_use ? (<li>{i18n.t("The voucher can be used for dryer machine only.")}</li>) : null}
                                    {!dryerVoucherSetting.voucher_dryer_use && dryerVoucherSetting.voucher_washer_use ? (<li>{i18n.t("The voucher can be used for washer machine only.")}</li>) : null}
                                    {dryerVoucherSetting.voucher_dryer_use && dryerVoucherSetting.voucher_washer_use ? (<li>{i18n.t("The voucher can be used for dryer or washer machine.")}</li>) : null}
                                    <li>{i18n.t("When you applied the voucher during payment, it will give you")} {dryerVoucherSetting.voucher_type === "Flat" ? "RM" + dryerVoucherSetting.voucher_amount : dryerVoucherSetting.voucher_amount + "%"} {i18n.t("discount.")}</li>
                                    <li>{i18n.t("When promotion is on going, the voucher is not applicable.")}</li>
                                    {/* <li>{i18n.t("The voucher cannot be used if your payment method is E-Token.")}</li> */}
                                    <li>{i18n.t("The voucher is only for customer use only, not for sale.")}</li>
                                </ol>
                            </div>
                        );
                    } else if (washerVoucherSetting.voucher_eligible && !dryerVoucherSetting.voucher_eligible) {
                        // if only give voucher for washer stamp
                        return (
                            <div style={{ textAlign: "justify" }}>
                                <ol>
                                    <li>{i18n.t("Using washer machine with the minimum spending of")} RM{this.props.user.stampTransactionAmount} {i18n.t("to collect one stamp.")}</li>
                                    <li>{i18n.t("The voucher will be given if you have collected 10 stamps.")}</li>
                                    {washerVoucherSetting.voucher_expiry ? (<li>{i18n.t("The voucher is valid for ")}{washerVoucherSetting.voucher_duration}{i18n.t(" days from that day the voucher given.")}</li>) : (<li>{i18n.t("There is no expiration date for this voucher.")}</li>)}
                                    {washerVoucherSetting.voucher_dryer_use && !washerVoucherSetting.voucher_washer_use ? (<li>{i18n.t("The voucher can be used for dryer machine only.")}</li>) : null}
                                    {!washerVoucherSetting.voucher_dryer_use && washerVoucherSetting.voucher_washer_use ? (<li>{i18n.t("The voucher can be used for washer machine only.")}</li>) : null}
                                    {washerVoucherSetting.voucher_dryer_use && washerVoucherSetting.voucher_washer_use ? (<li>{i18n.t("The voucher can be used for dryer or washer machine.")}</li>) : null}
                                    <li>{i18n.t("When you applied the voucher during payment, it will give you")} {washerVoucherSetting.voucher_type === "Flat" ? "RM" + washerVoucherSetting.voucher_amount : washerVoucherSetting.voucher_amount + "%"} {i18n.t("discount.")}</li>
                                    <li>{i18n.t("When promotion is on going, the voucher is not applicable.")}</li>
                                    {/* <li>{i18n.t("The voucher cannot be used if your payment method is E-Token.")}</li> */}
                                    <li>{i18n.t("The voucher is only for customer use only, not for sale.")}</li>
                                </ol>
                            </div>
                        );
                    } else if (washerVoucherSetting.voucher_eligible && dryerVoucherSetting.voucher_eligible) {
                        // if give voucher for washer stamp and dryer stamp
                        return (
                            <div style={{ textAlign: "justify" }}>
                                <ol>
                                    {this.props.user.specificStamp === "dryerOnly" || this.props.user.specificStamp === "both" ? (<li>{i18n.t("Using dryer machine with the minimum spending of")} RM{this.props.user.stampTransactionAmount} {i18n.t("to collect one dryer stamp.")}</li>) : null}
                                    {this.props.user.specificStamp === "washerOnly" || this.props.user.specificStamp === "both" ? (<li>{i18n.t("Using washer machine with the minimum spending of")} RM{this.props.user.stampTransactionAmount} {i18n.t("to collect one washer stamp.")}</li>) : null}
                                    {this.props.user.specificStamp === "dryerOnly" || this.props.user.specificStamp === "washerOnly" ? (<li>{i18n.t("The voucher will be given if you have collected 10 stamps.")}</li>) : (<li>{i18n.t("The voucher will be given if you have collected 10 dryer stamps or 10 washer stamps.")}</li>)}
                                    <li>{i18n.t("For the voucher given by the dryer stamps,")}
                                        <ol style={{ listStyleType: "lower-alpha" }}>
                                            {dryerVoucherSetting.voucher_expiry ? (<li>{i18n.t("It is valid for ")}{dryerVoucherSetting.voucher_duration}{i18n.t(" days from that day the voucher given.")}</li>) : (<li>{i18n.t("There is no expiration date for this voucher.")}</li>)}
                                            {dryerVoucherSetting.voucher_dryer_use && !dryerVoucherSetting.voucher_washer_use ? (<li>{i18n.t("The voucher can be used for dryer machine only.")}</li>) : null}
                                            {!dryerVoucherSetting.voucher_dryer_use && dryerVoucherSetting.voucher_washer_use ? (<li>{i18n.t("The voucher can be used for washer machine only.")}</li>) : null}
                                            {dryerVoucherSetting.voucher_dryer_use && dryerVoucherSetting.voucher_washer_use ? (<li>{i18n.t("The voucher can be used for dryer or washer machine.")}</li>) : null}
                                            <li>{i18n.t("When you applied the voucher during payment, it will give you")} {dryerVoucherSetting.voucher_type === "Flat" ? "RM" + dryerVoucherSetting.voucher_amount : dryerVoucherSetting.voucher_amount + "%"} {i18n.t("discount.")}</li>
                                        </ol>
                                    </li>
                                    <li>{i18n.t("For the voucher given by the washer stamps,")}
                                        <ol style={{ listStyleType: "lower-alpha" }}>
                                            {washerVoucherSetting.voucher_expiry ? (<li>{i18n.t("The voucher is valid for ")}{washerVoucherSetting.voucher_duration}{i18n.t(" days from that day the voucher given.")}</li>) : (<li>{i18n.t("There is no expiration date for this voucher.")}</li>)}
                                            {washerVoucherSetting.voucher_dryer_use && !washerVoucherSetting.voucher_washer_use ? (<li>{i18n.t("The voucher can be used for dryer machine only.")}</li>) : null}
                                            {!washerVoucherSetting.voucher_dryer_use && washerVoucherSetting.voucher_washer_use ? (<li>{i18n.t("The voucher can be used for washer machine only.")}</li>) : null}
                                            {washerVoucherSetting.voucher_dryer_use && washerVoucherSetting.voucher_washer_use ? (<li>{i18n.t("The voucher can be used for dryer or washer machine.")}</li>) : null}
                                            <li>{i18n.t("When you applied the voucher during payment, it will give you")} {washerVoucherSetting.voucher_type === "Flat" ? "RM" + washerVoucherSetting.voucher_amount : washerVoucherSetting.voucher_amount + "%"} {i18n.t("discount.")}</li>
                                        </ol>
                                    </li>
                                    <li>{i18n.t("When promotion is on going, all the vouchers is not applicable.")}</li>
                                    {/* <li>{i18n.t("All the vouchers cannot be used if your payment method is E-Token.")}</li> */}
                                    <li>{i18n.t("The voucher is only for customer use only, not for sale.")}</li>
                                </ol>
                            </div>
                        );
                    }
                }
            } else {
                if (this.state.loading === false) {
                    const news = this.state.news.filter(news => news.id === this.props.match.params.newsId)[0];
                    if (this.props.language === "en") {
                        return (
                            <div style={{ textAlign: "justify" }}>
                                <pre style={{ fontSize: "14px", fontFamily: "inherit" }}>{news.description}</pre>
                            </div>
                        );
                    } else if (this.props.language === "ms") {
                        if (news.descriptionBm) {
                            return (
                                <div style={{ textAlign: "justify" }}>
                                    <pre style={{ fontSize: "14px", fontFamily: "inherit" }}>{news.descriptionBm}</pre>
                                </div>
                            );
                        } else {
                            return (
                                <div style={{ textAlign: "justify" }}>
                                    <pre style={{ fontSize: "14px", fontFamily: "inherit" }}>{news.description}</pre>
                                </div>
                            );
                        }
                    } else if (this.props.language === "zh") {
                        if (news.descriptionCh) {
                            return (
                                <div style={{ textAlign: "justify" }}>
                                    <pre style={{ fontSize: "14px", fontFamily: "inherit" }}>{news.descriptionCh}</pre>
                                </div>
                            );
                        } else {
                            return (
                                <div style={{ textAlign: "justify" }}>
                                    <pre style={{ fontSize: "14px", fontFamily: "inherit" }}>{news.description}</pre>
                                </div>
                            );
                        }
                    }
                }
            }
        }

        const renderDetails = () => {
            if (this.state.loading) {
                return (
                    <div className="text-center">
                        <div className="spinner-border" style={{ color: "#ffd109" }} role="status">
                            <span className="sr-only">Loading ...</span>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div>
                        <img src={image()} alt="news" className="img-fluid" style={{ display: "block", margin: "auto" }} />
                        <div id="user-body">
                            <h2 className="pt-2">{title()}</h2>
                            {date()}
                            <h4 className="pt-2">{i18n.t("Description")}</h4>
                            {description()}
                        </div>
                    </div>
                );
            }
        }

        return (
            <div id="news-page">
                <div id="user-header">
                    <h5><i onClick={e => this.props.history.goBack()} className="fas fa-chevron-left me-3"></i> <strong>{title()}</strong></h5>
                </div>
                {
                    renderDetails()
                }
            </div>
        );

    };
};

const mapStateToProps = state => {
    return {
        operatorId: state.user.operatorId,
        language: state.user.language,
        user: state.user.user
    };
};

export default connect(mapStateToProps, null)(NewsDetails);